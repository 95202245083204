<template>
  <div class="fluid overflow-hidden bg-cinza-claro">
    <vue-topprogress ref="topProgress"></vue-topprogress>
    <div class="container">
      <div class="d-flex align-items-center">
        <div>
          <h3 class="ff-roboto-slab mt-4 pb-0 mb-0">Dados da conta</h3>
          <small class="text-uppercase">
            Informações de cadastro do usuário</small
          >
        </div>
        <div class="ml-md-auto">
          <a
            href="#/exportar-dados-pessoais"
            class="btn btn-sm btn-primary mr-1"
            >Exportar dados pessoais</a
          >
          <a href="#/editar-usuario" class="btn btn-sm btn-primary mr-1"
            >Editar conta</a
          >
          <a href="#/excluir-conta" class="btn btn-sm btn-primary"
            >Excluir conta</a
          >
        </div>
      </div>
      <div v-if="cliente" class="bg-white p-4 area-usario mt-3 mb-5">
        <div class="p-3 d-flex flex-column flex-md-row align-items-stretch">
          <div class="font-weight-bold pr-5">Nome:</div>
          {{ cliente.nome }}
        </div>
        <div class="p-3 d-flex flex-column flex-md-row align-items-stretch">
          <div class="font-weight-bold pr-5">E-mail:</div>
          {{ cliente.email }}
          <span class="ml-md-auto d-inline-block" v-if="cliente.emailConfirmado"
            ><i class="fas fa-check color-azul"></i> Verificado</span
          >
          <span
            class="ml-md-auto d-inline-block"
            v-if="!cliente.emailConfirmado"
          >
            <a
              href="javascript:void(0);"
              class="ml-md-auto d-inline-block"
              @click="confirmarEmail()"
              ><i class="fas fa-shield-alt"></i> Verificar E-mail</a
            >
          </span>
        </div>
        <div class="p-3 d-flex flex-column flex-md-row align-items-stretch">
          <div class="font-weight-bold pr-5">Celular:</div>
          <span v-if="cliente.celular">{{ cliente.celular | MaskTelefone }}</span>
          <span
            v-if="cliente.celular && cliente.celularConfirmado"
            class="ml-md-auto d-inline-block"
            ><i class="fas fa-check color-azul"></i> Verificado</span
          >
          <span
            v-if="cliente.celular && !cliente.celularConfirmado"
            class="ml-md-auto d-inline-block"
          >
            <a href="javascript:void(0);" @click="confirmarCelular()"
              ><i class="fas fa-shield-alt"></i> Verificar Celular</a
            >
          </span>
        </div>
        <div class="p-3 d-flex flex-column flex-md-row align-items-stretch">
          <div class="font-weight-bold pr-5">Estado:</div>
          {{ cliente.estado }}
        </div>
        <div class="p-3 d-flex flex-column flex-md-row align-items-stretch">
          <div class="font-weight-bold pr-5">Cidade:</div>
          {{ cliente.municipio }}
        </div>
        <div class="p-3 d-flex flex-column flex-md-row align-items-stretch">
          <div class="font-weight-bold pr-5">Histórico:</div>
          {{TextoHistorico}}
          <span class="ml-md-auto d-inline-block">
            <a href="javascript:void(0);" @click="confirmaSMS()"
              >{{TextoLinkHistorico}}</a
            ></span
          >
        </div>
        <div class="p-3 d-flex flex-column flex-md-row align-items-stretch">
          <div class="font-weight-bold pr-5">Senha:</div>
          ******
          <span class="ml-md-auto d-inline-block">
            <a href="javascript:void(0);" @click="redefinirSenha()"
              >Redefinir Senha</a
            ></span
          >
        </div>
      </div>
    </div>
    <modalSMS 
    :idModal="idModalSMS"
    :Titulo="'Habilitar histórico de consultas'"
    :Telefone="cliente ? cliente.celular : ''"
    :clienteId="cliente ? cliente.clienteId : 0"
    @fechaModal="habilitaDesabilitaHistorico"
    />
  </div>
</template>

<script>
import modalSMS from '../components/modalSMS.vue';
import api from "../config/api";
import UsuarioLogado from "../config/usuario-logado";

export default {
  components: { modalSMS },
  mounted: function() {
    this.$refs.topProgress.start();
    this.$refs.topProgress.start();
    this.ObterDadosCliente();
    setTimeout(() => {
      this.$refs.topProgress.done();
    }, 150);
  },
  data: function() {
    return {
      cliente: null,
      idModalSMS: "OcultaMostraHistorico"
    };
  },
  methods: {
    confirmarCelular() {
      this.$router.push({ path: "/sms" });
    },
    confirmarEmail() {
      var cliente = UsuarioLogado.obterUsuarioLogado();
      let loader = this.$loading.show();
      api
        .post(`email/enviar-verificacao?clienteId=${cliente.clienteId}`)
        .then(() => {
          this.$bvToast.toast(
            "E-mail de confirmação enviado. Verifique seu e-mail",
            {
              title: "Sucesso",
              variant: "success",
              solid: true,
            }
          );
        })
        .finally(() => {
          loader.hide();
        });
    },
    ObterDadosCliente() {
      var cliente = UsuarioLogado.obterUsuarioLogado();
      api
        .get(`clientes?clienteId=${cliente.clienteId}`)
        .then((resp) => {
          this.cliente = resp.data;
        })
        .finally(() => {
          setTimeout(() => {
            this.$refs.topProgress.done();
          }, 150);
        });
    },
    redefinirSenha() {
      this.$router.push({ path: "/redefinir-senha" });
    },
    async confirmaSMS() {
      await this.enviarSMSConfirmacao();
      this.$root.$bvModal.show(this.idModalSMS);
    },
    habilitaDesabilitaHistorico(smsOK) {

      if(smsOK) {
        let loader = this.$loading.show();
        var novoValor = this.cliente.ocultarHistorico ? false : true;
        api
          .put(`Clientes/habilita-desabilita-historico?clienteId=${this.cliente.clienteId}&valor=${novoValor}`)
          .then(() => {
            
            this.cliente.ocultarHistorico = novoValor;

            this.$root.$bvToast.toast("Dados alterados com sucesso", {
              title: "Sucesso",
              variant: "success",
              solid: true,
            });

            this.$root.$bvModal.hide(this.idModalSMS);
            
          }).catch((erro) => {
            console.log(erro)
          })
          .finally(() => {
            loader.hide();
          });
      }
    },
    enviarSMSConfirmacao() {
      this.erroCodigo = false;
      var cliente = UsuarioLogado.obterUsuarioLogado();
      api
        .post(`sms?clienteId=${cliente.clienteId}`)
        .then((resposta) => {
          this.numero = resposta.data;
        })
        .finally(() => {
          setTimeout(() => {
            this.$refs.topProgress.done();
          }, 150);
        });
    },
  },
  computed: {
    TextoLinkHistorico() {
      return this.cliente.ocultarHistorico ? 'Habilitar Histórico' : 'Desabilitar Histórico';
    },
    TextoHistorico() {
      return this.cliente.ocultarHistorico ? 'Desativado' : 'Ativado';
    }
  }
};
</script>

<style lang="scss" scoped>
.p-3 {
  border-bottom: 1px solid $cinza-medio;
}
b {
  display: inline-block;
  padding-right: 10px;
  min-width: 100px;
}
</style>
