 <template>
  <b-modal
    id="modalDescontarCredito"
    modal-class="modal-descontar-credito"
    body-class="p-0 visualizacao-credito shadow-none"
    centered
    hide-footer
    hide-header
  >
    <div class="header">
      <div class="header-icon text-center">
        <img src="@/assets/imgs/icons/apple-icon-76x76.png" alt="Legal lake" />
      </div>
      <div class="header-info text-center text-white">
        <h2 class="color-vermelho font-weight-light">Abrir Publicação</h2>
      </div>
    </div>
    <div class="creditos">
      <div class="text-center pt-5">
        <h4>
          <b>{{ (valorConsulta * -1) | currency }}</b>
        </h4>
        <p>Descontar Crédito</p>
        <a @click="abrePublicacao()" class="p-2 mx-3 btn btn-primary d-block"
          >Abrir Resultado</a
        >
      </div>
      <a
        href="#"
        @click="$bvModal.hide('modalDescontarCredito')"
        class="p-2 btn btn-link d-block"
        >Voltar para a pesquisa</a
      >
    </div>
  </b-modal>
</template>
<script>
import api from "../config/api";

export default {
  props: {
    status: String,
    valorConsulta: Number,
    idPublicacao: String,
    pesquisa: String,
    tipoConsulta: Number,
    paginaAtual: Number,
    historico: String,
    publicacaoFAZId: String,
  },
  data() {
    return {
      txtLogado: "Quase lá!",
      esconder: false,
    };
  },
  methods: {
    carregarModal() {
      if (this.status === "logado") {
        {
          this.esconder = true;
          this.txtLogado = "Abrir Publicação";
        }
      }
    },
    abrePublicacao() {
      let loader = this.$loading.show();
      const usuarioLogado = JSON.parse(localStorage.getItem("usuario"));
      api
        .post("ConsumoServico", {
          cliente_ID: usuarioLogado.clienteId,
          servico_ID: 1,
          termoPesquisa: this.pesquisa,
          iD_Registro_Visualizado: this.idPublicacao,
          historico: this.historico,
          publicacaoFAZId: this.publicacaoFAZId,
          tipoConsulta: this.tipoConsulta,
        })
        .then(() => {
          this.$router.push({
            path: "/resultado",
            query: {
              q: this.tipoConsulta, // q = Tipo de consulta
              w: this.paginaAtual, // w = Página atual
              pesquisa: this.pesquisa, // pesquisa = Termo pesquisado pelo usuário
              r: this.idPublicacao, // r = _id para apresentar detalhes do item selecionado
            },
          });
          this.$store.commit("atualizaSaldoDisponivel");
        })
        .catch((error) => {
          this.$root.$bvToast.toast(error.data.mensagemRetorno, {
            title: "Erro ao abrir publicação.",
            variant: "danger",
            solid: true,
            autoHideDelay: 5000,
          });

          this.$root.$bvModal.show("modalabrirvisualizacao");
          this.$bvModal.hide("modalDescontarCredito");
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>
<style lang="scss">
.modal-descontar-credito {
  .modal-content {
    background: transparent;
  }
  .visualizacao-credito {
    .header-icon {
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 50%,
        $azul_medio 50%
      );
    }
    .header-info {
      background: $azul_medio;
      padding: 20px;
    }
    .creditos {
      background: $branco;
    }
  }
  .selecionar-plano {
    //display: none;
    .nav-tabs {
      a {
        border-radius: 0;
        background-color: $cinza-medio;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
      }
      .nav-item:nth-child(1) {
        a {
          color: $vermelho;
          &.active {
            background-color: $vermelho;
            color: #fff;
          }
        }
      }
      .nav-item:nth-child(2) {
        a {
          color: $azul-escuro;
          &.active {
            background-color: $azul;
            color: #fff;
          }
        }
      }
    }
    .tab-content {
      .plano {
        .ttl {
          display: none;
        }
      }
      .ctn {
        border: none;
      }
    }
  }
  .actions {
    .btn-link {
      font-size: 0.9rem;
    }
  }
}
</style>