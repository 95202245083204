<template>
  <b-modal
    id="ModalAlteraCartao"
    body-class="p-0 visualizacao-credito shadow-none"
    centered
    hide-footer
    hide-header
  >
    <div class="header-padrao p-3 text-center">
      <a href="#/"
        ><img class="logo" src="../assets/imgs/legallake-branco.png"
      /></a>
    </div>

    <div v-if="cartaoCadastrado" class="px-3 px-md-5 py-4">
      <h4>Meus Cartões</h4>
      <div class="assinatura bg-cinza-claro my-1 p-2">
        <i class="fas fa-credit-card pr-2"></i
        >{{ cartaoCadastrado.payment_company.name }}
        <div>
          <b>Titular: </b>{{ cartaoCadastrado.holder_name
          }}<a Href="#" @click="RemoverCartao()" class="float-right">
            Remover
          </a>
        </div>
        <div>
          <b>Número:</b> {{ cartaoCadastrado.card_number_first_six }}****{{
            cartaoCadastrado.card_number_last_four
          }}
        </div>
      </div>
    </div>

    <div v-if="!cartaoCadastrado" class="px-3 px-md-5 py-4">
      <div class="mt-3">
        <h4>Adicionar Cartão</h4>
        <div
          class="alert alert-vermelho"
          role="alert"
          v-show="erros.length > 0"
        >
          <li v-for="(erro, index) in erros" :key="index">{{ erro }}</li>
        </div>
        <div class="panel-body">
          <form role="form" @submit.prevent="AdicionarCartao">
            <div class="form-group">
              <label for="CPF">CPF</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="CPF"
                  placeholder="CPF do titular"
                  v-model="cpf"
                  v-mask="'###.###.###-##'"
                  autofocus
                />
              </div>
            </div>
            <div class="form-group">
              <label for="titularCartao">Nome</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="titularCartao"
                  placeholder="Nome do titular do cartão"
                  v-model="dadosDoCartao.holder_name"
                  autofocus
                />
              </div>
            </div>
            <div class="form-group">
              <label for="numeroCartao">Número do Cartão</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="numeroCartao"
                  placeholder="Cartão válido"
                  v-model="dadosDoCartao.card_Number"
                  v-mask="['#### ###### #####', '#### #### #### ####']"
                  autofocus
                />
                <span class="input-group-addon"> </span>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-7 col-md-7">
                <div class="form-group">
                  <label for="expiracaoData">Validade do cartão</label>
                  <div class="row">
                    <div class="col-xs-6 col-lg-6 pl-ziro">
                      <input
                        name="validademes"
                        type="text"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                        maxlength="2"
                        class="form-control"
                        id="expiracaoData"
                        placeholder="MM"
                        v-model="ValidadeMes"
                      />
                    </div>
                    <div class="col-xs-6 col-lg-6 pl-ziro">
                      <input
                        name="validadeano"
                        type="text"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                        class="form-control"
                        id="expiracaoAno"
                        placeholder="AAAA"
                        v-model="ValidadeAno"
                        maxlength="4"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-5 col-md-5 pull-right">
                <div class="form-group">
                  <label for="cvcCode">Código CVC</label>
                  <input
                    type="password"
                    class="form-control"
                    id="cvcCode"
                    placeholder="CVC"
                    maxlength="4"
                    v-model="dadosDoCartao.card_cvv"
                  />
                </div>
              </div>
            </div>
            <button
              type="submit"
              :disabled="this.processando"
              class="btn btn-primary btn-block"
            >
              <span
                v-show="this.processando"
                class="spinner-border spinner-border-sm mr-2"
                role="status"
                aria-hidden="true"
              ></span>

              <span v-show="this.processando == false"
                ><i class="fas fa-shield-alt pr-2"></i>Adicionar Cartão</span
              >
              <span v-show="this.processando">Processando ...</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
  
<script>
import CompraCreditos from "../models/CompraCreditos";
import api from "../config/api";
import TermoDeAdesao from "@/views/TermoAdesao";
import UsuarioLogado from "../config/usuario-logado";

export default {
  data() {
    return {
      DadosCompra: {},
      holder_name: "",
      cpf: "",
      ValidadeMes: "",
      ValidadeAno: "",
      card_Number: "",
      card_cvv: "",
      valor: 0,
      termoAdesao: false,
      erros: [],
      processando: false,
      vendaAprovada: false,
      payment_method_code: "credit_card",
      payment_company_code: "mastercard",
      dadosDoCartao: {
        holder_name: "LEGALLAKE-16",
        card_expiration: "12/2030",
        card_number: "5555555555555557",
        card_cvv: "557",
      },
    };
  },
  props: {
    idModal: String,
    payment_profiles_ID: 0,
    cartaoCadastrado: null,
  },
  components: {
    TermoDeAdesao,
  },
  methods: {
    validaCPF(c) {
      if ((c = c.replace(/[^\d]/g, "")).length != 11) return false;

      if (
        c == "00000000000" ||
        c == "11111111111" ||
        c == "22222222222" ||
        c == "33333333333" ||
        c == "44444444444" ||
        c == "55555555555" ||
        c == "66666666666" ||
        c == "77777777777" ||
        c == "88888888888" ||
        c == "99999999999"
      )
        return false;

      var r;
      var s = 0;
      var i = 0;

      for (i = 1; i <= 9; i++) s = s + parseInt(c[i - 1]) * (11 - i);

      r = (s * 10) % 11;

      if (r == 10 || r == 11) r = 0;

      if (r != parseInt(c[9])) return false;

      s = 0;

      for (i = 1; i <= 10; i++) s = s + parseInt(c[i - 1]) * (12 - i);

      r = (s * 10) % 11;

      if (r == 10 || r == 11) r = 0;

      if (r != parseInt(c[10])) return false;

      return true;
    },
    AdicionarCartao() {
      this.erros = [];

      if (!this.cpf) {
        this.erros.push("Informe o CPF do titular");
      } else {
        if (this.validaCPF(this.cpf) == false) {
          this.erros.push("CPF inválido");
        }
      }

      if (!this.dadosDoCartao.holder_name) {
        this.erros.push("Informe o nome do titular");
      }

      if (!this.dadosDoCartao.card_Number) {
        this.erros.push("Informe o número do cartão");
      } else {
        if (this.dadosDoCartao.card_Number.replace(/\s/g, "").length < 15) {
          this.erros.push("Número do cartão inválido");
        }
      }

      if (!this.ValidadeMes) {
        this.erros.push("Informe o Mês de validade");
      } else {
        if (this.ValidadeMes < 1 || this.ValidadeMes > 12) {
          this.erros.push("Mês de validade inválido");
        }
      }

      var ano = 0;
      ano = this.ValidadeAno;

      if (ano < new Date().getFullYear()) {
        this.erros.push("Ano de validade inválido");
      }

      if (!this.dadosDoCartao.card_cvv) {
        this.erros.push("Informe o CVV do cartão");
      }

      if (this.erros.length > 0) {
        return;
      }

      this.processando = true;
      let loader = this.$loading.show();

      var cliente = UsuarioLogado.obterUsuarioLogado();
      this.dadosDoCartao.card_expiration =
        this.ValidadeMes + "/" + this.ValidadeAno;

      api
        .post("clientes/cadastra-cartao", {
          dadosDoCliente: { code: `legallake-${cliente.clienteId}` },
          dadosDoCartao: this.dadosDoCartao,
        })
        .then(() => {
          this.buscaDadosCartao();
        })
        .catch(() => {
          this.$root.$bvToast.toast(
            "Não foi possível adicionar seu cartão, por favor tente novamente.",
            {
              title: "Falha na Solicitação!",
              variant: "danger",
              solid: true,
              autoHideDelay: 5000,
            }
          );
        })
        .finally(() => {
          loader.hide();
          this.processando = false;
        });
    },
    validaDadosCartao() {
      this.erros = [];

      if (!this.cpf) {
        this.erros.push("Informe o CPF do titular");
      } else {
        if (this.validaCPF(this.cpf) == false) {
          this.erros.push("CPF inválido");
        }
      }

      if (!this.holder_name) {
        this.erros.push("Informe o nome do titular");
      }

      if (!this.card_Number) {
        this.erros.push("Informe o número do cartão");
      } else {
        if (this.card_Number.replace(/\s/g, "").length < 15) {
          this.erros.push("Número do cartão inválido");
        }
      }

      if (!this.ValidadeMes) {
        this.erros.push("Informe o Mês de validade");
      } else {
        if (this.ValidadeMes < 1 || this.ValidadeMes > 12) {
          this.erros.push("Mês de validade inválido");
        }
      }

      if (this.termoAdesao == false) {
        this.erros.push("Aceite o termo de adesão");
      }

      var ano = 0;
      ano = this.ValidadeAno;

      if (ano < new Date().getFullYear()) {
        this.erros.push("Ano de validade inválido");
      }

      if (!this.card_cvv) {
        this.erros.push("Informe o CVV do cartão");
      }

      if (this.erros.length > 0) {
        return;
      }
    },
    async RemoverCartao() {
      let loader = this.$loading.show();
      var cliente = UsuarioLogado.obterUsuarioLogado();

      await api
        .delete(`clientes/remove-cartao?IdPerfil=${this.payment_profiles_ID}`)
        .then((resp) => {
          this.buscaDadosCartao();
        })
        .catch((erro) => {
          console.log(erro);
        })
        .finally(() => {
          loader.hide();
        });
    },
    async buscaDadosCartao() {
      await this.$emit("buscaDadosCartao");

      // this.$root.$bvModal.hide('ModalAlteraCartao');

      // let loader = this.$loading.show();
      // var cliente = UsuarioLogado.obterUsuarioLogado();

      // api
      //   .get(`Clientes/cartao-cadastrado?clienteId=${cliente.clienteId}`)
      //   .then((resp) => {

      //     if(resp.data.payment_profiles.length > 0) {
      //       this.payment_profiles_ID = resp.data.payment_profiles[0].id
      //       this.cartaoCadastrado = resp.data.payment_profiles[0];
      //     } else {
      //       this.payment_profiles_ID = 0
      //       this.cartaoCadastrado = null;
      //     }

      //   }).catch((erro) => {
      //     console.log(erro)
      //   })
      //   .finally(() => {
      //     loader.hide();
      //   });
    },
    validaCPF(c) {
      if ((c = c.replace(/[^\d]/g, "")).length != 11) return false;

      if (
        c == "00000000000" ||
        c == "11111111111" ||
        c == "22222222222" ||
        c == "33333333333" ||
        c == "44444444444" ||
        c == "55555555555" ||
        c == "66666666666" ||
        c == "77777777777" ||
        c == "88888888888" ||
        c == "99999999999"
      )
        return false;

      var r;
      var s = 0;
      var i = 0;

      for (i = 1; i <= 9; i++) s = s + parseInt(c[i - 1]) * (11 - i);

      r = (s * 10) % 11;

      if (r == 10 || r == 11) r = 0;

      if (r != parseInt(c[9])) return false;

      s = 0;

      for (i = 1; i <= 10; i++) s = s + parseInt(c[i - 1]) * (12 - i);

      r = (s * 10) % 11;

      if (r == 10 || r == 11) r = 0;

      if (r != parseInt(c[10])) return false;

      return true;
    },
  },
};
</script>
<style lang="scss">
.login {
  max-width: 550px;
}

.pedidos {
  background: #fffccb;
  box-shadow: 0px 10px 20px #bbb;
  &:after {
    background: linear-gradient(-45deg, transparent 12px, #fffccb 0),
      linear-gradient(45deg, transparent 12px, #fffccb 0);
    background-repeat: repeat-x;
    background-position: left bottom;
    background-size: 12px 24px;
    content: "";
    display: block;
    width: 100%;
    height: 12px;
    position: relative;
    top: 12px;
    left: 0px;
    margin-bottom: 24px;
  }
  .pedido {
    border-bottom: 1px dashed $cinza;
  }
}
</style>
