<template>
  <div class="d-sm-flex align-items-md-center container-onda">
    <vue-topprogress ref="topProgress"></vue-topprogress>
    <div class="login m-auto">
      <div class="header-padrao p-3 text-center">
        <img class="logo" src="../assets/imgs/legallake-branco.png" />
      </div>
      <div class="px-5 py-4">
        <div class="text-center">
          <h3 class="p-0 m-0 font-weight-normal">Por favor aguarde</h3>
          <h6 class="p-2 small">Área Segura</h6>
        </div>
        <div v-if="expirado" class="py-3 text-center small">
          <span class="text-danger">Tempo expirado</span>
          <a href="http://app.faz.adv.br">Voltar para FAZ</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../config/api";
import UsuarioLogado from "../config/usuario-logado";

export default {
  mounted: function () {
    this.$refs.topProgress.start();
    this.enviarToken();
  },
  data: function () {
    return {
      expirado: false,
    };
  },
  methods: {
    enviarToken() {
      var token = this.$route.query.token;
      api
        .post("login/faz/token", {
          token: token,
        })
        .then((resposta) => {
          UsuarioLogado.inserirUsuarioLogado(
            new UsuarioLogado(
              resposta.data.nome,
              resposta.data.email,
              resposta.data.clienteId,
              resposta.data.token,
              true
            )
          );
          localStorage.setItem("access_token", resposta.data.token);
          this.$router.push({ path: "/" });
        })
        .catch((erro) => {
          if (erro.data.mensagem === "Tempo expirado") {
            this.expirado = true;
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.$refs.topProgress.done();
          }, 150);
        });
    },
  },
};
</script>