<template>
<b-modal
    id="OcultaMostraHistorico"
    body-class="p-0 visualizacao-credito shadow-none"
    centered
    hide-footer
    hide-header
  >
        <div class="login m-auto">
        <div class="header-padrao p-3 text-center">
            <img class="logo" src="../assets/imgs/legallake-branco.png" />
        </div>
        <div class="px-3 px-md-5 py-4">
            <div class="text-center">
            <h3 class="p-0 m-0 font-weight-normal">Ativar histórico de consultas</h3>
            <h6></h6>
            <div class="py-3" v-if="Telefone !== ''">
                Confirme o código enviado por SMS<br />
                para o número ** **** {{ this.Telefone.substring(7,11) }}
            </div>
            </div>
            <div v-if="erroCodigo" class="alert alert-vermelho" role="alert">
            Código não confere. <br />
            <a href="javascript:void(0);" @click="reenviarSMSConfirmacao()"
                >Reenviar código</a
            >
            </div>
            <div class="form-group">
            <label for="email">Código de 6 dígitos</label>
            <input
                type="text"
                maxlength="6"
                class="form-control"
                id="codigo"
                v-model="codigo"
                oninput="this.value = this.value.toUpperCase()"
                :class="{ 'is-invalid': $v.codigo.$error }"
            />
            <b-form-invalid-feedback
                v-if="!$v.codigo.required && formularioPostado"
                >Campo obrigatório</b-form-invalid-feedback
            >
            <b-form-invalid-feedback
                v-if="!$v.codigo.minLength && formularioPostado"
                >Código deve possuir 6 caracteres<br
            /></b-form-invalid-feedback>
            </div>
            <button
            type="button"
            class="btn btn-primary btn-block"
            @click="enviarConfirmacao()"
            >
            <i class="fas fa-shield-alt pr-2"></i>Confirmar
            </button>
            <div class="py-3 text-center">
            <span v-if="segundos && segundos > 0"
                >Aguarde {{ segundos }} segundo{{ segundos > 1 ? "s" : "" }} para
                enviar um novo código</span
            >
            <div v-if="segundos === 0">
                <a href="javascript:void(0);" @click="reenviarSMSConfirmacao()"
                >Envie um novo Código</a
                >
            </div>
            
            </div>
        </div>
        </div>
  
  </b-modal>
</template>
<script>
import api from "../config/api";
import UsuarioLogado from "../config/usuario-logado";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: 'modalOcultaMostraHistorico',
  data: function () {
    return {
      formularioPostado: false,
      codigo: "",
      numero: "",
      erroCodigo: false,
      envios: 1,
      segundos: null,
    };
  },
  props: {
      idModal: String,
      Titulo: String,
      Telefone: String
  },
  mounted() {
    //this.$refs.topProgress.start();
    // document.getElementById("codigo").focus();
    this.diminuirTempo();
  },
  validations: {
    codigo: { required, minLength: minLength(6) },
  },
  methods: {
    diminuirTempo: function () {
      let duracao = this.envios * 60;
      setInterval(() => {
        if (--duracao >= 0) {
          this.segundos = duracao;
        }
      }, 1000);
    },
    reenviarSMSConfirmacao() {
      this.erroCodigo = false;
      let loader = this.$loading.show();
      var cliente = UsuarioLogado.obterUsuarioLogado();
      api
        .post(`sms?clienteId=${cliente.clienteId}`)
        .then((resposta) => {
          this.numero = resposta.data;
          this.envios++;
          this.diminuirTempo();
        })
        .finally(() => {
          setTimeout(() => {
            loader.hide();
          }, 1000);
        });
    },
    enviarSMSConfirmacao() {
      this.erroCodigo = false;
      var cliente = UsuarioLogado.obterUsuarioLogado();
      api
        .post(`sms?clienteId=${cliente.clienteId}`)
        .then((resposta) => {
          this.numero = resposta.data;
        })
        .finally(() => {
          setTimeout(() => {
            this.$refs.topProgress.done();
          }, 150);
        });
    },
    enviarConfirmacao() {

      var cliente = UsuarioLogado.obterUsuarioLogado();
      
      api
        .post("sms/verificar", {
          clienteId: cliente.clienteId,
          codigo: this.codigo,
        })
        .then(() => {
          this.fechaModal(true, cliente.clienteId)
        })
        .catch(() => {})
    },
    fechaModal(val, clienteId) {
      this.$emit('fechaModal', val, clienteId)
    },
  },
};
</script>