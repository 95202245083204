<template>
  <div class="fluid overflow-hidden bg-cinza-claro">
    <vue-topprogress ref="topProgress"></vue-topprogress>
    <div class="container">
      <div class="d-flex align-items-center">
        <div>
          <h3 class="ff-roboto-slab mt-4 pb-0 mb-0">Carteira</h3>
          <small class="text-uppercase"> Ativos e movimentação da conta</small>
        </div>
      </div>

      <div class="bg-white p-4 area-usario mt-3 mb-5">
        <h4>Serviços ativos</h4>
        <div
          v-if="processando == false && planoAtual == 'Pré-Pago'"
          class="pre-pago bg-cinza-claro my-1 p-2"
        >
          <div class="d-flex border-bottom pb-1 mb-1">
            <div class="color-vermelho">
              <b>{{ planoAtual }}</b>
            </div>
            <div class="ml-auto">
              <b>{{ saldoAtual }}</b>
            </div>
          </div>
          <div v-if="vencimentoCliente">
            <i class="fas fa-exclamation-triangle"></i>
            Vence em {{ vencimentoCliente
            }}<a href="#" @click="comprarCredito()" class="float-right">
              Comprar Crédito
            </a>
          </div>
        </div>
        <div
          v-if="processando == false && planoAtual == 'Assinatura'"
          class="assinatura bg-cinza-claro my-1 p-2"
        >
          <div class="text-b d-flex border-bottom pb-1 mb-1">
            <div class="color-azul"><b>Assinatura</b></div>
            <div class="ml-auto">
              <b>{{ saldoAtual | currency }}</b>
            </div>
          </div>

          Próxima recorrência em {{ validade | moment("DD/MM/YYYY") }}:
          {{ valorRecargaMensal | currency }}
          <a href="#" @click="AbreModalCartao"> Alterar meio de pagamento </a>
          <!-- <a href="#" @click="compraCreditoAdicional">Credito Adicional</a> -->
          <a
            href="#"
            @click="AbreModalCancelamentoAssinatura()"
            class="float-right"
          >
            Cancelar Assinatura
          </a>
        </div>

        <div>
          <ResultadoVazio
            v-if="processando == false && planoAtual == ''"
            titulo="Sem ativos"
            descricao="Nenhum serviço foi encontrado"
            icon="fa-info-circle"
          />
        </div>

        <div v-if="Extrato.length > 0" class="table-responsive">
          <h4 class="mt-4">Extrato da carteira</h4>
          <table id="extrato" class="table small">
            <thead>
              <tr>
                <th scope="col">Data Transação</th>
                <th scope="col">Tipo</th>
                <th scope="col">Valor</th>
                <th scope="col">Validade</th>
                <th scope="col">Histórico</th>
                <th scope="col">Saldo Anterior</th>
                <th scope="col">Saldo Atual</th>
              </tr>
            </thead>
            <tbody>
              <tr
                :id="item.idLancto"
                v-for="(item, index) in Extrato"
                :key="item.idLancto"
              >
                <td>{{ DataDeValidade(item.dataMov) }}</td>

                <td :class="item.tipo == 'D' ? 'text-danger' : 'text-info'">
                  {{
                    item.tipo == "C"
                      ? item.bonus
                        ? "Bônus"
                        : "Compra Crédito"
                      : "Consulta Publicação"
                  }}
                </td>
                <td :class="item.tipo == 'D' ? 'text-danger' : 'text-info'">
                  {{
                    item.tipo == "D" ? item.valor * -1 : item.valor | currency
                  }}
                </td>
                <td>
                  {{ item.tipo == "C" ? DataDeValidade(item.validade) : "" }}
                </td>
                <td>
                  <small v-if="cliente">{{
                    item.tipo === "D" && cliente.ocultarHistorico
                      ? "Pesquisa realizada pelo usuário"
                      : montarHistoricoParaExibicao(item.historico)
                  }}</small>
                </td>
                <td>{{ item.saldoAnterior | currency }}</td>
                <td>{{ CalcSaldo(item.valor, index) | currency }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <ResultadoVazio
        v-show="processando == false && Extrato.length <= 0"
        titulo="Sem movimentação"
        descricao="Nenhuma informação foi encontrada."
        icon="fa-money-check-alt"
      />
      <ModalCartao
        :payment_profiles_ID="payment_profiles_ID"
        :cartaoCadastrado="cartaoCadastrado"
        @buscaDadosCartao="buscaDadosCartao"
      />

      <b-modal
        id="ModalCancelaAssinatura"
        body-class="p-0 visualizacao-credito shadow-none"
        centered
        hide-footer
        hide-header
      >
        <div class="px-3 px-md-5 py-4">
          <h4>Cancelamento de Assinatura</h4>
          <div class="assinatura bg-cinza-claro my-1 p-2">
            <div v-if="cartaoCadastrado">
              <p>Tem certeza que deseja cancelar sua assinatura?</p>
              <p>
                Caso confirme, você terá acesso as suas consultas salvas e
                poderá utilizar todo saldo restante até o dia
                {{ validade | moment("DD/MM/YYYY") }}.
              </p>
              <p>Para continuar clique em <b>"Confirmar"</b></p>
            </div>

            <div v-if="!cartaoCadastrado">
              <p>Não encontramos nenhum meio de pagamento ativo.</p>
              <p>
                Caso não adicione um meio de pagamento, seu plano atual será
                automaticamente cancelado no dia
                {{ validade | moment("DD/MM/YYYY") }}.
              </p>
              <p>Para continuar clique em <b>"Confirmar"</b></p>
            </div>
          </div>
          <button
            v-if="cartaoCadastrado"
            type="button"
            class="btn btn-primary btn-block"
            @click="CancelarAssinatura()"
          >
            <i class="fas fa-shield-alt pr-2"></i>Confirmar
          </button>
        </div>
      </b-modal>

      <ModalCompraCreditoAdicional
        @buscaDadosCartao="buscaDadosCartao"
        :cartaoCadastrado="cartaoCadastrado"
      />
    </div>
  </div>
</template>

<script>
import ResultadoVazio from "@/components/ResultadoVazio";
import api from "../config/api";
import UsuarioLogado from "../config/usuario-logado";
import ModalCartao from "../components/ModalCartaoCredito.vue";
import ModalCompraCreditoAdicional from "../components/ModalCompraCreditoAdicional";
import ExpressaoBusca from "../models/expressao-busca";

export default {
  components: { ResultadoVazio, ModalCartao, ModalCompraCreditoAdicional },
  data() {
    return {
      Extrato: [],
      processando: false,
      planoAtual: "",
      SaldoInicial: 0,
      Saldo_Acumulado: 0,
      SaldoAtual: 0,
      valorRecargaMensal: 0,
      validade: "",
      cliente: null,
      payment_profiles_ID: 0,
      cartaoCadastrado: null,
      vencimentoCliente: null,
      idModalCompra: "comprarCredito",
    };
  },
  methods: {
    CarregaExtrato() {
      this.processando = true;
      let loader = this.$loading.show();

      let usuarioLogado = JSON.parse(localStorage.getItem("usuario"));

      api
        .post("ExtratoCliente", { ClienteId: usuarioLogado.clienteId })
        .then((resp) => {
          let extratoTmp = resp.data.qryExtrato;
          this.Extrato = extratoTmp;
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error({
            title: "Falha na Solicitação!!!",
            message:
              "Não foi possível carregar seu estrato, por favor tente novamente mais tarde.",
            progressBar: true,
            position: "top right",
            showDuration: 1000,
            hideDuration: 1000,
            delay: 0,
            timeOut: "5500",
          });
        })
        .finally(() => {
          loader.hide();
          this.processando = false;
        });
    },
    DataDeValidade(dataVal) {
      let DataDez = dataVal.substr(0, 10);
      return (
        DataDez.substr(8, 2) +
        "/" +
        DataDez.substr(5, 2) +
        "/" +
        DataDez.substr(0, 4)
      );
    },
    CalcSaldo(val, index) {
      let vlAnt = 0;
      let sdlAn = 0;

      if (index > 0) {
        sdlAn = this.Extrato[index - 1].saldoAcumulado;
      }

      vlAnt =
        (isNaN(this.Extrato[index].valor) ? 0 : this.Extrato[index].valor) *
        (this.Extrato[index].tipo == "D" ? -1 : 1);

      if (this.Extrato[index].tipo == "C") {
        this.Extrato[index].saldoAcumulado =
          this.Extrato[index].valor + this.Extrato[index].saldoAnterior;
      } else {
        this.Extrato[index].saldoAnterior = sdlAn;
        this.Extrato[index].saldoAcumulado = vlAnt + sdlAn;
      }

      this.Saldo_Acumulado = this.Extrato[index].saldoAcumulado;

      return this.Extrato[index].saldoAcumulado;
    },
    montarHistoricoParaExibicao(historico) {
      return ExpressaoBusca.converterParaExibicao(historico);
    },
    ObterDadosCliente() {
      var cliente = UsuarioLogado.obterUsuarioLogado();
      api.get(`clientes?clienteId=${cliente.clienteId}`).then((resp) => {
        this.cliente = resp.data;
      });
    },
    ObterSaldoCliente() {
      var cliente = UsuarioLogado.obterUsuarioLogado();
      api.get("Clientes/saldo?clienteId=" + cliente.clienteId).then((resp) => {
        //this.$store.commit("atualizaSaldoDisponivel");
        this.saldoAtual = resp.data.saldo;
        this.planoAtual = resp.data.tipoPlano;
        this.validade = resp.data.validade;
        this.valorRecargaMensal = resp.data.valorRecargaMensal;
      });
    },
    AbreModalCartao() {
      this.$root.$bvModal.show("ModalAlteraCartao");
    },
    comprarCredito() {
      this.$root.$bvModal.show(this.idModalCompra);
    },
    compraCreditoAdicional() {
      this.$root.$bvModal.show("ModalCompraCreditoAdicional");
    },
    CancelarAssinatura() {
      let loader = this.$loading.show();

      api
        .delete(`Clientes/remove-cartao?IdPerfil=${this.payment_profiles_ID}`)
        .then(() => {
          this.$root.$bvToast.toast("Assinatura cancelada com sucesso", {
            title: "Sucesso",
            variant: "success",
            solid: true,
          });

          this.cartaoCadastrado = null;
        })
        .catch((erro) => {
          this.$root.$bvToast.toast(erro, {
            title: "Falha ao Cancelar Assinatura",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          loader.hide();
          this.$root.$bvModal.hide("ModalCancelaAssinatura");
        });
    },
    async AbreModalCancelamentoAssinatura() {
      this.$root.$bvModal.show("ModalCancelaAssinatura");
    },
    async buscaDadosCartao() {
      let loader = this.$loading.show();

      var cliente = UsuarioLogado.obterUsuarioLogado();

      await api
        .get(`Clientes/cartao-cadastrado?clienteId=${cliente.clienteId}`)
        .then((resp) => {
          if (
            resp.data.payment_profiles &&
            resp.data.payment_profiles.length > 0
          ) {
            this.payment_profiles_ID = resp.data.payment_profiles[0].id;
            this.cartaoCadastrado = resp.data.payment_profiles[0];
          } else {
            this.payment_profiles_ID = 0;
            this.cartaoCadastrado = null;
          }
        })
        .finally(() => {
          loader.hide();
        });
    },
    async obterVencimentoCliente() {
      var cliente = UsuarioLogado.obterUsuarioLogado();
      api
        .get("clientes/validade?clienteId=" + cliente.clienteId)
        .then((vencimento) => {
          this.vencimentoCliente = vencimento.data;
        });
    },
  },
  async mounted() {
    this.$refs.topProgress.start();
    await Promise.all([
      this.ObterDadosCliente(),
      this.CarregaExtrato(),
      this.ObterSaldoCliente(),
      this.buscaDadosCartao(),
      this.obterVencimentoCliente(),
    ]);
    this.$refs.topProgress.done();
  },
};
</script>
<style lang="scss" scoped>
.tbl-head {
  font-weight: 700;
  background: $cinza-claro;
  border-bottom: 2px solid $cinza;
}
.tbl-ctn {
  border-bottom: 1px solid $cinza-medio;
  &:hover {
    background: $cinza-claro;
  }
}
.col {
  padding: 16px;
}
</style>
