<template>
  <div class="fluid overflow-hidden bg-cinza-claro">
    <vue-topprogress ref="topProgress"></vue-topprogress>
    <div class="container">
      
      <div  class="d-flex flex-column flex-md-row align-items-center">
        <div>
          <h3 class="ff-roboto-slab mt-4 pb-0 mb-0">
            Termos e Condições Gerais de Uso
          </h3>
          <small class="text-uppercase">
            Conheça os seus direitos e deveres para utilizar o este
            serviço</small
          >
        </div>
        
        <div class="ml-md-auto">
         Atualizado em 17/08/2021
        </div>
      </div>


      <div v-if="mostraOpcaoAceite" class="mt-2 alert alert-vermelho alert-danger">
          <div class="d-flex flex-column flex-md-row align-items-stretch align-items-center">
            <div> 
              <small class="text-uppercase">
                Nossos termos e condições de uso foram atualizados. <br>
                Ao navegar por nosso sistema você estará concordando com nossos termos.</small>
            </div>
            
            <div class="ml-md-auto">
              <a
                @click="aceitaTermo()"
                class="btn btn-block btn-primary mr-1"
                >Entendi</a
              >
            </div>
          </div>
      </div>

      <div class="bg-white p-4 area-usario mt-3 mb-5">
        <div class="p-3 d-flex flex-column flex-md-row align-items-stretch">
          <div>
            <div class="ttl mb-4">
              Termos e Condições Gerais de Uso
            </div>
            <div class="ttl-alt mb-4">
              <p>
                Os serviços do LegalLake são fornecidos pela SEDEP Info Soluções
                Inteligentes LTDA, inscrito no CNPJ 09.471.861/0001-22, titular
                da propriedade intelectual sobre o software, website,
                aplicativos e demais ativos relacionados a plataforma.
              </p>
              <p>
                Os usuários utilizarão os serviços do LegalLake no âmbito destes
                termos de uso e prometem respeitá-los nas seguintes condições:
              </p>
            </div>
            <div class="ttl mb-4">
              Do objeto
            </div>
            <div class="ttl-alt mb-4">
              <p>
                O LegalLake visa licenciar seu uso fornecendo ferramentas para
                auxiliar e dinamizar o dia a dia dos seus usuários.
              </p>
              <p>
                A plataforma LegalLake caracteriza-se pela prestação do seguinte
                serviço: concede aos usuários apenas acesso as informações e ao
                conteúdo de jornais oficiais e materiais publicados em sites
                oficiais em todo o país, tudo de maneira acessível e
                administrada por uma infraestrutura robusta e gerida pela sua
                criadora, a empresa SEDEP.
              </p>
            </div>
            <div class="ttl mb-4">
              Da aceitação
            </div>
            <div class="ttl-alt mb-4">
              <p>
                O presente termo estabelece obrigações contratadas de livre e
                espontânea vontade, por tempo indeterminado, entre plataforma e
                as pessoas físicas ou jurídicas, usuárias do site ou aplicativo.
                Ao utilizar a plataforma LegalLake para consultas, o usuário
                aceita integralmente as presentes normas e compromete-se a
                observá-las, sob risco de aplicação das penalidades cabíveis.
              </p>
              <p>
                O usuário aceita que LegalLake pode modificar estes termos de
                uso a qualquer momento sem notificar o usuário com antecedência.
                Caso não concorde com as disposições deste instrumento, o
                usuário não deve utilizá-los.
              </p>
            </div>
            <div class="ttl mb-4">
              Do acesso dos usuários
            </div>
            <div class="ttl-alt mb-4">
              <p>
                Serão utilizadas todas as soluções técnicas à disposição do
                responsável pela plataforma para permitir o acesso ao serviço 24
                (vinte e quatro) horas por dia, 7 (sete) dias por semana. No
                entanto, a navegação na plataforma ou em alguma de suas páginas
                poderá ser interrompida, limitada ou suspensa para atualizações,
                modificações ou qualquer ação necessária ao seu bom
                funcionamento.
              </p>
              <p>
                Os usuários poderão utilizar o acesso normalmente em áreas
                restritas, e em caso de abuso, como implantação de robôs ou
                outros mecanismos de coleta de dados em lotes, e acessar este
                usuário de forma que desacelere o sistema ou mesmo paralise
                outros usuários, neste caso O download pode ser cancelado
                imediatamente a critério exclusivo de LegalLake, sem prejuízo da
                apuração dos prejuízos apurados.
              </p>
            </div>
            <div class="ttl mb-4">
              Do cadastro
            </div>
            <div class="ttl-alt mb-4">
              <p>
                O usuário garante que as informações fornecidas pelo usuário no
                momento do preenchimento do formulário de contato
                disponibilizado por LegalLake são verdadeiras e completas.
              </p>
              <p>
                O usuário, ao aceitar os Termos e Política de Privacidade,
                autoriza expressamente a plataforma a coletar, usar, armazenar,
                tratar, ceder ou utilizar as informações derivadas do uso dos
                serviços, do site e quaisquer plataformas, incluindo todas as
                informações preenchidas pelo usuário no momento em que realizar
                ou atualizar seu cadastro, além de outras expressamente
                descritas na Política de Privacidade que deverá ser autorizada
                pelo usuário.
              </p>
              <p>
                O usuário poderá de maneira gratuita, realizar o seu cadastro,
                modificá-lo excluí-lo e solicitar a exportação dos seus dados
                conforme disposto no Art. 18 da Lei Geral de Proteção de Dados
                (Lei nº 13.709/2018).
              </p>
            </div>
            <div class="ttl mb-4">
              Dos preços
            </div>
            <div class="ttl-alt mb-4">
              <p>
                A plataforma LegalLake se reserva no direito de reajustar
                unilateralmente, a qualquer tempo, os valores dos serviços ou
                produtos sem consulta ou anuência prévia do usuário.
              </p>
              <p>
                Os valores aplicados são aqueles que estão em vigor no momento
                do pedido.
              </p>
              <p>
                Os preços são indicados em reais e não incluem as taxas de
                entrega, as quais são especificadas à parte e são informadas ao
                usuário antes da finalização do pedido.
              </p>
              <p>
                Na contratação de determinado serviço ou produto, a plataforma
                poderá solicitar as informações financeiras do usuário, como CPF
                e dados para cobrança. Ao inserir referidos dados o usuário
                concorda que sejam cobrados, de acordo com a forma de pagamento
                que venha a ser escolhida, os preços então vigentes e informados
                quando da contratação. Referidos dados financeiros poderão ser
                armazenados para facilitar acessos e contratações futuras.
              </p>
              <p>
                Para processar o pagamento no caso de você contratar o
                LegalLake, vamos compartilhar o seu número de cartão de crédito,
                dados bancários, dados de cobrança e informações de contato com
                instituições financeiras, provedores de meios de pagamento,
                integradores de meios de pagamento e empresas de cartões de
                crédito.
              </p>
              <p>
                Todas as informações financeiras como número de cartões e demais
                dados financeiros coletados, são administrados por um serviço
                terceiro que é a plataforma de pagamentos VINDI. A VINDI é uma
                plataforma segura de pagamentos que realiza toda gestão para
                transações financeiras, onde a SEDEP, como utilizadora desse
                serviço, apenas transmite a VINDI, as informações financeiras
                para validação e autorização do pagamento pela plataforma
                digital.
              </p>
              <p>
                A SEDEP não armazena nenhum dado financeiro dos seus clientes,
                apenas repassando tais informações a VINDI, utilizando a sua
                plataforma de pagamentos, para apenas receber a confirmação da
                operação, onde a VINDI analisará se o pagamento foi autorizado
                ou recusado. Informações sobre eventuais recusas são repassadas
                da VINDI aos usuários SEDEP, para que estes possam solicitar
                esclarecimentos junto das instituições financeiras ao qual
                mantém relacionamento.
              </p>
              <p>
                Para saber mais sobre a VINDI, acesse o seu site a partir do
                link:
                <a href="https://vindi.com.br/sobre/"
                  >https://vindi.com.br/sobre/</a
                >
              </p>
              <p>
                O usuário sabe que todas as modalidades do plano mensal têm um
                número limitado de visitas indicadas em cada plano, inclusive
                sua data de vencimento, que será atualizada a cada recarga de
                crédito de qualquer valor.
              </p>
            </div>
            <div class="ttl mb-4">
              Das responsabilidades
            </div>
            <div class="ttl-alt mb-4">
              <p>É responsabilidade do usuário:</p>
              <ul>
                <li>
                  os usuários entendem que todos os materiais disponíveis são
                  propriedade da LegalLake e estão proibidos de usá-los para
                  fins de marketing da mesma forma que LegalLake;
                </li>
                <li>
                  comprometer-se a não copiar, modificar, sublicenciar, alugar e
                  / ou vender qualquer conteúdo deste produto;
                </li>
                <li>
                  reconhecer que a responsabilidade da LegalLake se limita ao
                  conteúdo editorial produzido exclusivamente por ela e
                  veiculado através do produto (existindo no produto conteúdo de
                  terceiros). A LegalLake informa que o conteúdo do produto é
                  elaborado por profissionais qualificados, não estando, contudo
                  isentos de falhas e erros, portanto o usuário isenta a
                  LegalLake de responsabilização por danos sofridos em valores
                  superiores ao equivalente a 1/12 avos de uma anualidade do
                  produto;
                </li>
                <li>
                  concordar que o conteúdo transmitido (incluindo qualquer
                  texto, áudio, imagem ou software, seja de propriedade da
                  LegalLake ou de terceiros) está sujeito à LGPD (Lei Geral de
                  Proteção de Dados);
                </li>
                <li>
                  reconhecer que mensagens eletrônicas e documentos são uma boa
                  maneira de comprovar documentos para todos os fins e efeitos;
                </li>
                <li>
                  pelo cumprimento e respeito ao conjunto de regras disposto
                  nesse Termo de Condições Geral de Uso, na respectiva Política
                  de Privacidade e na legislação nacional e internacional;
                </li>
                <li>
                  pela proteção aos dados de acesso à sua conta/perfil (login e
                  senha).
                </li>
              </ul>
              <p>É responsabilidade da plataforma LegalLake:</p>
              <ul>
                <li>indicar as características do serviço ou produto;</li>
                <li>
                  os defeitos e vícios encontrados no serviço ou produto
                  oferecido desde que lhe tenha dado causa;
                </li>
                <li>
                  as informações que foram por ela divulgadas, sendo que os
                  comentários ou informações divulgadas por usuários são de
                  inteira responsabilidade dos próprios usuários;
                </li>
                <li>
                  os conteúdos ou atividades ilícitas praticadas através da sua
                  plataforma.
                </li>
              </ul>
              <p>
                A plataforma LegalLake não se responsabiliza por links externos
                contidos em seu sistema que possam redirecionar o usuário à
                ambiente externo a sua rede.
              </p>
              <p>
                Não poderão ser incluídos links externos ou páginas que sirvam
                para fins comerciais ou publicitários ou quaisquer informações
                ilícitas, violentas, polêmicas, pornográficas, xenofóbicas,
                discriminatórias ou ofensivas.
              </p>
            </div>
            <div class="ttl mb-4">
              Dos direitos autorais
            </div>
            <div class="ttl-alt mb-4">
              <p>
                O presente Termo de Uso concede aos usuários uma licença não
                exclusiva, não transferível e não sublicenciável, para acessar e
                fazer uso da plataforma e dos serviços e produtos por ela
                disponibilizados.
              </p>
              <p>
                A estrutura do site ou aplicativo, as marcas, logotipos, nomes
                comerciais, layouts, gráficos e design de interface, imagens,
                ilustrações, fotografias, apresentações, vídeos, conteúdos
                escritos e de som e áudio, programas de computador, banco de
                dados, arquivos de transmissão e quaisquer outras informações e
                direitos de propriedade intelectual da SEDEP Info Soluções
                Inteligentes LTDA, observados os termos da Lei da Propriedade
                Industrial (Lei nº 9.279/96), Lei de Direitos Autorais (Lei nº
                9.610/98) e Lei do Software (Lei nº 9.609/98), estão devidamente
                reservados.
              </p>
              <p>
                Este Termos de Uso não cede ou transfere ao usuário qualquer
                direito, de modo que o acesso não gera qualquer direito de
                propriedade intelectual ao usuário, exceto pela licença limitada
                ora concedida.
              </p>
              <p>
                O uso da plataforma pelo usuário é pessoal, individual e
                intransferível, sendo vedado qualquer uso não autorizado,
                comercial ou não-comercial. Tais usos consistirão em violação
                dos direitos de propriedade intelectual da SEDEP Info Soluções
                Inteligentes LTDA, puníveis nos termos da legislação aplicável.
              </p>
            </div>
            <div class="ttl mb-4">
              Da política de privacidade
            </div>
            <div class="ttl-alt mb-4">
              <p>
                Além do presente Termo, o usuário deverá consentir com as
                disposições contidas na respectiva Política de Privacidade a ser
                apresentada a todos os interessados dentro da interface da
                plataforma. Você poderá visualizar a nossa Política de
                Privacidade a partir do link:
                <a href="https://privacidade.sedep.com.br/"
                  >https://privacidade.sedep.com.br/</a
                >
              </p>
            </div>
            <div class="ttl mb-4">
              Do foro
            </div>
            <div class="ttl-alt mb-4">
              <p>
                Para a solução de controvérsias decorrentes do presente
                instrumento será aplicado integralmente o Direito brasileiro.
              </p>
              <p>
                No caso de conflitos existentes entre regras e instrumentos
                listados na definição dos Termos de Condições Gerais de Uso
                constantes no caput deste instrumento, deverão prevalecer regras
                e condições sobre produtos e/ou serviços específicos tal como
                disponibilizados por escrito pela SEDEP.
              </p>
              <p>
                O não exercício pela SEDEP de quaisquer direitos ou faculdades
                que lhe sejam conferidos por estes Termos de Condições Gerais de
                Uso ou pela legislação brasileira, bem como a eventual
                tolerância contra infrações a estes Termos de Condições Gerais
                de Uso ou a seus direitos, não importará na renúncia aos seus
                direitos, novação ou alteração de itens e cláusulas deste
                instrumento, podendo ainda a SEDEP exercer seus direitos a
                qualquer momento.
              </p>
              <p>
                Estes Termos de Condições Gerais de Uso, bem como a relação
                decorrente das ações aqui compreendidas, assim como qualquer
                disputa que surja em virtude disto será regulada exclusivamente
                pela legislação brasileira
              </p>
              <p>
                Fica eleito o Foro Central da Comarca da Capital do Estado do
                Mato Grosso do Sul para dirimir quaisquer questões oriundas da
                utilização dos Conteúdos, Serviços e/ou violação destes Termos
                Gerais, renunciando as partes a qualquer outro, por mais
                privilegiado que seja ou venha a ser.
              </p>
              <p>
                Caso você considere que existem fatos, circunstâncias ou
                suspeita de qualquer uso inadequado dos Conteúdos ou qualquer
                questão de segurança relacionada à SEDEP e, em particular, que
                representem violação de direito autoral ou de propriedade
                intelectual ou outros direitos, pedimos que envie uma
                comunicação à SEDEP por meio do e-mail privacidade@sedep.com.br
                contendo, se possível, seus dados pessoais (nome, endereço e
                número de telefone), bem como especificação do evento verificado
                por Você (em particular, quando se tratar de suposta violação de
                direitos autorais, indicação precisa e completa dos conteúdos
                protegidos e supostamente infringidos com as respectivas URLs).
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../config/api";
import UsuarioLogado from "../config/usuario-logado";

export default {
  data: function () {
    return {
      mostraOpcaoAceite: false,
    }
  },
  methods: {
    aceitaTermo() {
      
      var cliente = UsuarioLogado.obterUsuarioLogado();

      api.put(`Clientes/aceita-termo?clienteId=${cliente.clienteId}`);
      localStorage.setItem("termo_aceito", true);
      this.$router.push({ path: "/" });
      
    }
  },
  mounted() {
    
    console.log(localStorage.getItem("termo_aceito") !== 'true')
    this.mostraOpcaoAceite = localStorage.getItem("termo_aceito") !== 'true'
  },
}
</script>