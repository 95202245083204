<template>
  <div>
    <Header></Header>
    <div class="bg-cinza-claro" style="min-height: 450px">
      <router-view></router-view>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";

export default {
  name: "MasterPage",
  components: {
    Header,
    Footer,
  },
  data () {
    return {};
  },
  methods: {},
  metaInfo: {
    bodyAttrs: {
      class: ["bg-cinza-claro"],
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/styles/legallake.scss";
</style>
