<template>
  <div class="d-sm-flex align-items-md-center container-onda">
    <vue-topprogress ref="topProgress"></vue-topprogress>
    <div class="login m-auto">
      <div class="header-padrao p-3 text-center">
        <div class="personagem d-md-block d-none">
          <img v-show="!showPass" src="../assets/imgs/login.png" />
          <img
            v-show="showPass"
            class="ver"
            src="../assets/imgs/login-ver.png"
          />
        </div>
        <a href="/">
          <img class="logo" src="../assets/imgs/legallake-branco.png" />
        </a>
      </div>
      <div class="px-3 px-md-5 py-4">
        <div class="text-center">
          <h3 class="p-0 m-0 font-weight-normal">Login</h3>
          <h6 class="p-2 small">Área Segura</h6>
        </div>
        <div v-if="erroLogin" class="alert alert-vermelho" role="alert">
          Seu e-mail ou senha não conferem.
        </div>
        <div class="form-group">
          <label for="email">E-mail</label>
          <input
            type="email"
            class="form-control"
            id="email"
            placeholder="nome@servidor.com.br"
            v-model="email"
            :class="{ 'is-invalid': $v.email.$error }"
            @keyup.enter="login()"
          />
          <b-form-invalid-feedback
            v-if="!$v.email.required && formularioPostado"
            >Campo obrigatório</b-form-invalid-feedback
          >
          <b-form-invalid-feedback v-if="!$v.email.email && formularioPostado"
            >E-mail inválido</b-form-invalid-feedback
          >
        </div>
        <div class="form-group">
          <label for="password">Senha</label>
          <div class="input-group mb-3">
            <input
              type="password"
              class="form-control"
              placeholder="• • • • •"
              v-model="password"
              v-show="!showPass"
              :class="{ 'is-invalid': $v.password.$error }"
              @keyup.enter="login()"
            />
            <input
              type="text"
              class="form-control"
              placeholder="senha"
              v-model="password"
              v-show="showPass"
              :class="{ 'is-invalid': $v.password.$error }"
              @keyup.enter="login()"
            />
            <div class="input-group-append">
              <button class="btn" type="button" @click="showPass = !showPass">
                <span v-show="showPass">
                  <i class="fas fa-eye"></i>
                </span>
                <span v-show="!showPass"
                  ><i class="fas fa-eye-slash"></i>
                </span>
              </button>
            </div>
            <b-form-invalid-feedback
              v-if="!$v.password.required && formularioPostado"
              >Campo obrigatório</b-form-invalid-feedback
            >
          </div>
        </div>
        <div class="text-right pb-3 small">
          Esqueceu a senha?
          <a href="#/envio-recuperar-senha">Clique para recuperar</a>
        </div>
        <button
          type="button"
          class="btn btn-primary btn-block"
          @click="login()"
        >
          <i class="fas fa-shield-alt pr-2"></i>Entrar
        </button>
        <div class="py-3 text-center small">
          Não tem acesso? <a href="#/Cadastro">Criar uma conta</a>
        </div>

        <div class="py-3 text-center small">
          <a href="https://privacidade.sedep.com.br/politica-privacidade"  target="_blank">Privacidade</a>
        </div>
      </div>           
    </div>
    
  </div>

</template>

<script>
import api from "../config/api";
import UsuarioLogado from "../config/usuario-logado";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "login",
  mounted() {
    document.getElementById("email").focus();
    this.$refs.topProgress.start();
    setTimeout(() => {
      this.$refs.topProgress.done();
    }, 150);
  },
  data: function () {
    return {
      formularioPostado: false,
      showPass: false,
      password: "",
      email: "",
      erroLogin: false,
      reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  validations: {
    password: { required },
    email: { required, email },
  },
  methods: {
    emailValido: function () {
      return this.email && this.email !== "" ? this.reg.test(this.email) : true;
    },
    login: function () {
      this.erroLogin = false;
      this.formularioPostado = true;
      this.$v.$touch();
      if (!this.$v.$error) {
        let loader = this.$loading.show();
        api
          .post(`login/token`, {
            email: this.email,
            senha: this.password,
          })
          .then((resposta) => {
            UsuarioLogado.inserirUsuarioLogado(
              new UsuarioLogado(
                resposta.data.nome,
                resposta.data.email,
                resposta.data.clienteId,
                resposta.data.token,
                resposta.data.clienteFAZ
              )
            );

            this.$store.commit("atualizaDadosUsuario", {
              idUsuario: resposta.data.clienteId,
              nomeUsuario: resposta.data.nome,
              emailUsuario: resposta.data.email,
              saldoDisponivel: 0,
            });

            localStorage.setItem("access_token", resposta.data.token);

            api
              .get(`Clientes/verifica-aceite-termo?clienteId=` + resposta.data.clienteId)              
              .catch(() => {
                localStorage.removeItem("termo_aceito");
                this.$router.push({ path: "termos-de-uso" });
              })



            if (this.comprando == true) {
              this.$router.push({ path: "checkout" });
            } else {
              if (this.abrindoPublicacao == true) {
                this.$router.push({
                  path: "busca",
                  query: {
                    q: this.tipoConsulta, // q = Tipo de consulta
                    w: this.paginaAtual, // w = Página atual
                    pesquisa: this.pesquisa, // pesquisa = Termo pesquisado pelo usuário
                  },
                });

                // this.$router.push({path: 'busca',
                //                   params: { q: this.tipoConsulta,
                //                   w: this.paginaAtual,
                //                   pesquisa: this.pesquisa }})
              } else {
                // this.$router.replace("/");
                this.$router.push({ path: "/" });
              }
            }
          })
          .catch(() => {
            this.erroLogin = true;
          })
          .finally(() => {
            loader.hide();
          });
      }
    },
  },
  computed: {
    comprando() {
      return this.$store.state.checkout.comprando;
    },
    abrindoPublicacao() {
      return this.$store.state.logBusca.abrindoPublicacao;
    },
    tipoConsulta() {
      return this.$store.state.logBusca.tipoConsulta;
    },
    paginaAtual() {
      return this.$store.state.logBusca.paginaAtual;
    },
    pesquisa() {
      return this.$store.state.logBusca.pesquisa;
    },
  },
};
</script>
<style lang="scss">
.container-onda {
  min-height: 100vh;
  padding-top: 70px;
  background-image: url(../assets/imgs/bg-login.png);
  background-position: bottom;
  background-repeat: repeat-x;
  padding-bottom: 20px;
  .header-padrao {
    position: relative;
    border-radius: $border-radius $border-radius 0 0;
    .personagem img {
      position: absolute;
      left: -47px;
      top: -68px;
      &.ver {
        position: absolute;
        left: -22px;
        top: -70px;
      }
    }
    img.logo {
      width: 220px;
    }
  }
  .login {
    border-radius: $border-radius;
    margin-top: 120px;
    min-width: 450px;
    background: $branco;

    box-shadow: 1px 1px 5px $cinza-medio;
    .fa-eye {
      padding-left: 2px;
    }
  }
}
</style>
