<template>
  <div class="fluid overflow-hidden bg-cinza-claro">
    <vue-topprogress ref="topProgress"></vue-topprogress>
    <div class="container">
      <div class="d-flex align-items-center">
        <div>
          <h3 class="ff-roboto-slab mt-4 pb-0 mb-0">
            Seus dados foram cancelados
          </h3>
          <small class="text-uppercase">
            Esta conta foi cancelada pelo usuário</small
          >
        </div>
      </div>

      <div class="bg-white p-4 area-usario mt-3 mb-5">
        <div class="p-3 d-flex flex-column flex-md-row align-items-stretch">
          <div v-if="cancelado">
            <div class="ttl mb-4">
              Antes de partir, leia o informativo abaixo
            </div>
            <div class="ttl-alt mb-4">
              Seus dados permanecerão na base de dados do LegalLake por 30 dias
              a partir de agora. Se você não fez o download dos seus dados,
              poderá fazê-lo entrando em contato com o suporte do LegalLake.
            </div>
            <div class="ttl-alt mb-4">
              A permanência dos dados por mais 30 dias serve apenas para fins de
              auditoria interna. Após esse período seus dados serão
              definitivamente excluídos.
            </div>
            <div class="ttl-alt mb-4">
              Agradecemos pelo tempo que esteve conosco e se precisar mergulhar
              novamente no nosso lago de dados, é só criar uma nova conta.
            </div>
          </div>
          <div v-else>
            <div class="ttl mb-4">
              Parece que alguma coisa aconteceu
            </div>
            <div class="ttl-alt mb-4">
              Verifique a informação a seguir e tente novamente.
            </div>
            <div class="ttl-alt mb-4">
              {{ informacaoCancelamento }}
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-betwee">
          <button
            type="button"
            class="btn btn-primary ml-auto"
            @click="paginaInicial()"
          >
            Ir para página inicial
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../config/api";
import UsuarioLogado from "../config/usuario-logado";

export default {
  data: function() {
    return {
      cancelado: false,
      informacaoCancelamento: null,
    };
  },
  mounted() {
    this.$refs.topProgress.start();
    setTimeout(() => {
      this.confirmarCancelamento();
      this.$refs.topProgress.done();
    }, 150);
  },
  methods: {
    confirmarCancelamento() {
      UsuarioLogado.removerUsuarioLogado();
      let loader = this.$loading.show();
      let codigo = this.$route.query.byeBye;
      api
        .post(`clientes/excluir-conta-confirmacao`, {
          ByeBye: codigo,
        })
        .then((resposta) => {
          let data = resposta.data;

          if (data == "ok") {
            this.$root.$bvToast.toast(
              "A conta foi CANCELADA por requisição do usuário",
              {
                title: "Conta cancelada",
                variant: "warning",
                solid: true,
              }
            );
            this.cancelado = true;
            return;
          }

          this.cancelado = false;
          this.informacaoCancelamento = data;
        })
        .finally(() => {
          loader.hide();
        });
    },
    paginaInicial() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>
