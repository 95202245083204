<template>
  <div class="fluid overflow-hidden bg-cinza-claro">
    <vue-topprogress ref="topProgress"></vue-topprogress>
    <div class="container">
      <div
        class="
          d-flex
          flex-column flex-md-row
          mt-4
          align-items-center
          justify-content-md-between
        "
      >
        <div>
          <h3 class="ff-roboto-slab pb-0 mb-0">Publicações Abertas</h3>
          <small class="text-uppercase">
            Publicações que já foram vizualizados</small
          >
        </div>
        <div class="">
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              id="swexpirados"
              v-model="expirados"
              @change="obterResultadosAbertos()"
            />
            <label class="custom-control-label" for="swexpirados"
              >Exibir expirados</label
            >
          </div>
        </div>
      </div>
      <div v-if="resultadosAbertos && resultadosAbertos.length > 0">
        <div class="bg-white p-4 area-usario mt-3 mb-3">
          <div
            v-for="resultadoAberto in resultadosAbertos"
            :key="resultadoAberto.lancamentoId"
            class="d-flex align-items-center buscas-salvas p-1"
          >
            <div>
              <span
                v-if="resultadoAberto.expirado"
                class="badge badge-danger d-inline-block mr-2"
              >
                <small
                  ><i class="fas fa-exclamation-triangle"></i> expirado</small
                ></span
              >
              <b>Título: </b>
              {{
                cliente.ocultarHistorico
                  ? "Pesquisa realizada pelo usuário"
                  : montarTermoParaExibicao(resultadoAberto.termoPesquisa)
              }}
              <p>
                <b>Data: </b>
                {{ resultadoAberto.dataLancamento | moment("DD/MM/YYYY") }}
                <b>Validade:</b>
                {{
                  resultadoAberto.validadeVisualizacao | moment("DD/MM/YYYY")
                }}
              </p>
              <div v-if="resultadoAberto.historico">
                <b>Histórico: </b>{{ resultadoAberto.historico }}
              </div>
            </div>
            <div class="ml-auto">
              <a
                href="javascript:void(0);"
                class="btn btn-sm btn-primary btn-outline-primary"
                @click="visualizarResultado(resultadoAberto)"
                >Abrir Publicação</a
              >
            </div>
          </div>
        </div>
        <div class="overflow-auto">
          <b-pagination
            v-model="pagina"
            :total-rows="quantidade"
            :per-page="registrosPorPagina"
            align="center"
            @input="obterResultadosAbertos()"
          ></b-pagination>
        </div>
      </div>
      <div v-if="resultadosAbertos && resultadosAbertos.length <= 0">
        <div class="bg-white p-4 area-usario mt-3 mb-5">
          <ResultadoVazio
            titulo="Resultado Vazio"
            descricao="Nenhum resultado ainda foi aberto"
            icon="fa-exclamation"
          />
        </div>
      </div>
    </div>
    <ModalDescontarCredito
      :valorConsulta="valorConsulta"
      :idPublicacao="idPublicacao"
      :tipoConsulta="tipoConsulta"
      :paginaAtual="0"
      :pesquisa="pesquisa"
      :historico="historico"
      :publicacaoFAZId="publicacaoFAZId"
    />
    <ModalAbrirVisualizacao
      :idPublicacao="idPublicacao"
      :tipoConsulta="tipoConsulta"
      :paginaAtual="0"
      :pesquisa="pesquisa"
      :idModal="this.idModalabrirvisualizacao"
    />
  </div>
</template>
<script>
import ResultadoVazio from "@/components/ResultadoVazio";
import api from "../config/api";
import UsuarioLogado from "../config/usuario-logado";
import ModalDescontarCredito from "@/components/ModalDescontarCredito";
import ExpressaoBusca from "../models/expressao-busca";

export default {
  components: { ResultadoVazio, ModalDescontarCredito },
  data: function () {
    return {
      pagina: 1,
      registrosPorPagina: 10,
      quantidade: null,
      resultadosAbertos: null,
      dataValidade: null,
      cliente: null,
      valorConsulta: 0,
      idPublicacao: 0,
      pesquisa: "",
      tipoConsulta: null,
      expirados: false,
      publicacaoFAZId: 0,
      idModalabrirvisualizacao: "comprarCredito",
    };
  },
  async mounted() {
    await this.$refs.topProgress.start();
    await this.ObterDadosCliente();
    await this.obterResultadosAbertos(this.pagina);
    setTimeout(() => {
      this.$refs.topProgress.done();
    }, 150);
  },
  methods: {
    montarTermoParaExibicao(termo) {
      return ExpressaoBusca.converterParaExibicao(termo);
    },
    visualizarResultado(resultadoAberto) {
      var usuarioLogado = UsuarioLogado.obterUsuarioLogado();

      let url =
        `consulta/verificar-acesso-resultado?clienteId=${usuarioLogado.clienteId}` +
        `&tipoConsulta=${resultadoAberto.tipoConsulta}&termoPesquisa=${resultadoAberto.termoPesquisa}` +
        `&registroId=${resultadoAberto.registroVisualizadoId}`;

      let loader = this.$loading.show();
      api
        .get(url)
        .then((resp) => {
          if (resp.data) {
            this.$router.push({
              path: `/resultado?pesquisa=${resultadoAberto.termoPesquisa}&r=${resultadoAberto.registroVisualizadoId}&q=${resultadoAberto.tipoConsulta}`,
            });
          } else {
            api
              .get("Clientes/saldo?clienteId=" + usuarioLogado.clienteId)
              .then((resp) => {
                this.valorConsulta = resp.data.valorConsulta;
                this.pesquisa = resultadoAberto.termoPesquisa;
                this.tipoConsulta = resultadoAberto.tipoConsulta;
                this.publicacaoFAZId = resultadoAberto.publicacaoFAZId;
                this.$store.commit("atualizaSaldoDisponivel");
                this.idPublicacao = resultadoAberto.registroVisualizadoId;
                if (resp.data.saldo > 0) {
                  this.$bvModal.show("modalDescontarCredito");
                } else {
                  this.$bvModal.show(this.idModalabrirvisualizacao);
                }
              });
          }
        })
        .finally(() => {
          loader.hide();
        });
    },
    obterResultadosAbertos() {
      let loader = this.$loading.show();
      var cliente = UsuarioLogado.obterUsuarioLogado();

      if (
        !this.expirados &&
        this.resultadosAbertos &&
        this.resultadosAbertos.length > 0 &&
        this.resultadosAbertos[0].expirado
      ) {
        this.pagina = 1;
      }

      api
        .get(
          `resultados?clienteId=${cliente.clienteId}
          &pagina=${this.pagina}&registrosPorPagina=${this.registrosPorPagina}&expirados=${this.expirados}`
        )
        .then((resposta) => {
          this.quantidade = resposta.data.quantidade;
          this.resultadosAbertos = resposta.data.resultadosAbertos;
        })
        .finally(() => {
          loader.hide();
        });
    },
    DataDeLancamento(dataVal) {
      let DataDez = dataVal.substr(0, 10);
      let Horas = dataVal.substr(11, 8);
      return (
        DataDez.substr(8, 2) +
        "/" +
        DataDez.substr(5, 2) +
        "/" +
        DataDez.substr(0, 4) +
        " " +
        Horas
      );
    },
    ObterDadosCliente() {
      var cliente = UsuarioLogado.obterUsuarioLogado();
      api
        .get(`clientes?clienteId=${cliente.clienteId}`)
        .then((resp) => {
          this.cliente = resp.data;
        })
        .finally(() => {
          setTimeout(() => {
            this.$refs.topProgress.done();
          }, 150);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.tbl-head {
  font-weight: 700;
  background: $cinza-claro;
  border-bottom: 2px solid $cinza;
}
.tbl-ctn {
  border-bottom: 1px solid $cinza-medio;
  &:hover {
    background: $cinza-claro;
  }
}
.col {
  padding: 16px;
}
</style>
