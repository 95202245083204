<template>
  <div class="fluid bg-cinza-claro" v-if="itensCarregados">
    <div class="container py-4">
      <div class="ttl pb-2">
        <button
          type="button"
          @click="$router.go(-1)"
          class="btn btn-outline-primary btn-sm"
        >
          <i class="fas fa-chevron-left"></i> Voltar
        </button>

        <h5 class="d-inline">{{ respostaServidor.diario }}</h5>
      </div>
      <div class="row">
        <div class="col-md-12">
          <AvisoDadosPublicos />
        </div>
        <div id="resultado" class="col-md-12">
          <div class="resultado p-4 mb-4">
            <div class="mb-4">
              <div class="paremetro mb-3">
                <h4>{{ respostaServidor.diario }}</h4>
              </div>
              <div class="d-flex">
                <div class="paremetro mr-3">
                  <strong>Data de Disponibilização</strong>:
                  {{ respostaServidor.dataDisponibilizacao }}
                </div>
                <div class="paremetro mr-3">
                  <strong>Data de Publicação</strong>:
                  {{ respostaServidor.dataPublicacao }}
                </div>
                <div class="paremetro mr-3">
                  <strong>Região</strong>:
                  {{ respostaServidor.estado }}
                </div>
                <div class="paremetro mr-3">
                  <strong>Página</strong>:
                  {{ respostaServidor.numeroPagina }}
                </div>
              </div>
            </div>
            <div class="mb-4">
              <div class="d-flex">
                <div class="paremetro mr-3">
                  <strong>Número do Processo</strong>:
                  {{ respostaServidor.numeroDoProcesso }}
                </div>
              </div>
            </div>
            <h5>{{ respostaServidor.cabecalhoSecundario }}</h5>
            <h5>{{ respostaServidor.cabecalhoPrincipal }}</h5>
            <div style="text-align: justify" v-html="respostaServidor.texto"></div>
            <p>{{ respostaServidor.rodape }}</p>
          </div>

          <div id="nao_imprimir" class="download d-flex align-items-center p-3">
            <i class="fas fa-exclamation-triangle color-vermelho"></i>
            <div class="mx-4" v-if="dataExpiracao">
              <b class="d-block">Expiração do Resultado</b>
              Este resultado estará disponível até o dia {{ dataExpiracao }}
            </div>
            <div class="ml-auto">
              <button
                type="button"
                class="btn btn-primary btn-azul btn-sm mx-1"
                @click="$bvModal.show('modal-emails')"
              >
                Enviar por e-mail
              </button>
              <button
                type="button"
                class="btn btn-primary btn-azul btn-sm mx-1"
                @click="salvaResultadoPdf()"
              >
                Baixar Publicação
              </button>
            </div>
            <b-modal id="modal-emails" header-class="flex-column">
              <template #modal-header>
                <h5 class="d-block">Enviar por E-mail</h5>
                <small class="d-block"
                  >Envie para um ou mais e-mails separando por ponto e vírgula
                  (;)</small
                >
              </template>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="email">E-mails</label>
                    <input
                      type="text"
                      class="form-control"
                      id="email"
                      v-model="emails"
                      :class="{
                        'is-invalid': $v.emails.$error,
                      }"
                    />
                    <b-form-invalid-feedback
                      v-if="!$v.emails.required && formularioPostado"
                      >Campo obrigatório<br
                    /></b-form-invalid-feedback>
                    <b-form-invalid-feedback
                      v-if="
                        $v.emails.required &&
                        !$v.emails.validacaoMultiplosEmails &&
                        formularioPostado
                      "
                    >
                      <div v-html="emailsInvalidos"></div>
                      <br
                    /></b-form-invalid-feedback>
                  </div>
                </div>
              </div>
              <template #modal-footer>
                <b-button
                  variant="link"
                  size="sm"
                  class="float-right"
                  @click="$bvModal.hide('modal-emails')"
                >
                  Cancelar
                </b-button>
                <b-button
                  @click="enviarEmailPublicacao()"
                  variant="primary"
                  size="sm"
                  class="float-right"
                >
                  Enviar
                </b-button>
              </template>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
    <div style="display: none" ref="print">
      <div
        style="
          background: #e1e1e1;
          min-width: 100%;
          width: 100%;
          padding: 10px 5px;
        "
      >
        <div
          style="
            background: #fff;
            min-width: 450px;
            width: 550px;
            margin: 45px auto;
            border-radius: 4px;
            font-family: roboto, arial, verdama;
            font-size: 14px;
          "
        >
          <div
            style="
              background: #09076e;
              padding: 15px;
              border-radius: 4px 4px 0 0;
              text-align: center;
            "
          >
            <img src="@/assets/imgs/legallake.png" title="Legal Lake" />
          </div>
          <div
            style="
              padding: 45px;
              border-radius: 4px 4px 0 0;
              color: #000000;
              margin: 14px 0 14px 0;
            "
          >
            <div style="margin: 0 0 14px 0">
              <div>
                Disponibilizado em:
                {{ respostaServidor.dataDisponibilizacao }}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Publicado
                em: {{ respostaServidor.dataPublicacao }}
              </div>
              <div>Diário: {{ respostaServidor.diario }}</div>
              <div>
                Estado:
                {{ respostaServidor.estado }}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Página:
                {{ respostaServidor.numeroPagina }}
              </div>
              <br />
              <div>
                Número do Processo: {{ respostaServidor.numeroDoProcesso }}
              </div>
            </div>
            <h6 style="margin: 14px 0 14px 0">
              {{ respostaServidor.cabecalhoSecundario }}
            </h6>
            <h6 style="margin: 0 0 14px 0">
              {{ respostaServidor.cabecalhoPrincipal }}
            </h6>
            <p v-html="respostaServidor.texto"></p>
            <p v-html="respostaServidor.rodape"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.ttl {
  margin-bottom: 3px;

  h5 {
    font-family: $font-alt;
  }

  .btn {
    position: relative;
    top: -2px;
    font-size: 0.7rem;
    padding: 3px 6px;
    margin-right: 10px;
  }
}
.resultado {
  background: $branco;
  border-radius: $border-radius;
  border: 1px solid $cinza-medio;
  box-shadow: 1px 1px 10px $cinza-medio;
  word-break: break-word;
}
.download {
  background: #e0e0e0;
  border-radius: $border-radius;
  border: 1px solid $cinza-medio;
  font-size: 13px;
  i {
    font-size: 1.4rem;
    opacity: 0.7;
  }
}
</style>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.3.0/jspdf.umd.min.js"></script>

<script>
import api from "../config/api";
import UsuarioLogado from "../config/usuario-logado";
import AvisoDadosPublicos from "@/components/AvisoDadosPublicos";
import { required } from "vuelidate/lib/validators";
import * as Clipboard from "clipboard";

const validacaoMultiplosEmails = (value, vm) => {
  vm.emailsInvalidos = "";
  const invalidos = [];
  const emails = value.split(";").map((t) => t.trim());
  if (value) {
    emails.forEach((r) => {
      const patt = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$", "g");
      if (!patt.test(r)) {
        invalidos.push(r);
      }
    });
    if (invalidos.length > 0) {
      vm.emailsInvalidos =
        invalidos.join(" é um email inválido <br />") + " é um email inválido";
    }
  }
  return invalidos.length <= 0;
};

//  https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.2.61/jspdf.min.js
//  https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.3.0/jspdf.umd.min.js
export default {
  components: {
    AvisoDadosPublicos,
  },
  mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute(
      "src",
      "https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.min.js"
    );
    plugin.async = true;
    document.head.appendChild(plugin);

    this.init();
    this.obterDataDeExpiracao();
  },
  data() {
    return {
      tipoConsulta: 0, // Tipo de consulta
      paginaAtual: 1, // Página atual
      pesquisa: "", // Termo pesquisado pelo usuário
      itemId: "", // _id para apresentar detalhes do item selecionado
      detalhe: null,
      itensCarregados: false,
      dataExpiracao: null,
      emails: "",
      emailsInvalidos: "",
      formularioPostado: false,
    };
  },
  validations: {
    emails: { required, validacaoMultiplosEmails },
  },
  methods: {
    init() {
      this.tipoConsulta = parseInt(this.$route.query.q); // Tipo de consulta
      this.paginaAtual = parseInt(this.$route.query.w); // Página atual
      this.pesquisa = this.$route.query.pesquisa; // Termo pesquisado pelo usuário
      this.itemId = this.$route.query.r; // _id para apresentar detalhes do item selecionado
      this.itensCarregados = false;
      this.buscarDetalhes();
    },
    salvaResultadoPdf() {
      var margins = {
        top: 20,
        bottom: 20,
        left: 20,
        width: 522,
      };

      var specialElementHandlers = {
        //remove esse elemento do pdf
        "#nao_imprimir": function (element, renderer) {
          return true;
        },
      };

      var pdf = new jsPDF("p", "pt", "A4");
      pdf.fromHTML(
        this.$refs.print, // HTML string or DOM elem ref.
        margins.left, // x coord
        margins.top, // y coord
        {
          width: margins.width,
          elementHandlers: specialElementHandlers,
        },
        function () {
          pdf.save("Publicacao.pdf");
        },
        margins
      );
    },
    buscarDetalhes() {
      var usuarioLogado = UsuarioLogado.obterUsuarioLogado();

      let consulta =
        "Consulta/AbrirDetalhes" +
        `?termoPesquisa=${encodeURIComponent(this.pesquisa)}` +
        `&registroId=${this.itemId}` +
        `&clienteId=${usuarioLogado.clienteId}` +
        `&tipoConsulta=${this.tipoConsulta}`;

      api
        .get(consulta)
        .then((resposta) => {
          console.log(resposta);
          this.itensCarregados = true;
          this.respostaServidor = resposta.data;
        })
        .catch((erro) => {
          console.error(erro);
          this.itensCarregados = false;
        });
    },
    enviarEmailPublicacao() {
      this.$v.emails.$touch();
      this.formularioPostado = true;
      if (!this.$v.emails.$error) {
        let loader = this.$loading.show();
        var usuarioLogado = UsuarioLogado.obterUsuarioLogado();

        let consulta = {
          termoPesquisa: this.pesquisa,
          registroId: this.itemId,
          clienteId: usuarioLogado.clienteId,
          tipoConsulta: this.tipoConsulta,
        };

        api
          .post(`email/enviar-email-publicacao?emails=${this.emails}`, consulta)
          .then(() => {
            this.$root.$bvModal.hide("modal-emails");
            this.$root.$bvToast.toast("Publicação enviada com sucesso.", {
              title: "Sucesso",
              variant: "success",
              solid: true,
            });
          })
          .finally(() => {
            loader.hide();
          });
      }
    },
    obterDataDeExpiracao() {
      var usuarioLogado = UsuarioLogado.obterUsuarioLogado();
      api
        .get(
          `resultados/expiracao?registroVisualizadoId=${this.itemId}&clienteId=${usuarioLogado.clienteId}`
        )
        .then((resultado) => {
          this.dataExpiracao = resultado.data;
        });
    },
  },
};
</script>
