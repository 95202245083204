import api from '../config/api';
import UsuarioLogado from '../config/usuario-logado';

export default class CompraCreditos {
    constructor(obj) {
        var usuarioLogado = UsuarioLogado.obterUsuarioLogado();

        this.dadosDoSistema = {   
            nomeSistema: "legallake",
            urlCallback: "http://api.legallake.com.br/api/WebHookCreditos"
        }
        
        this.dadosDoCliente = {
            name: usuarioLogado.nome,
            email: usuarioLogado.email,
            registry_code: "",
            code: "legallake-" + obj.idUsuario,
            notes: "",
            metadata: "metadata"
        }

        this.dadosDoCartao = {
            holder_name: obj.holder_name,
            card_expiration: obj.card_expiration,
            card_number: obj.card_number,
            card_cvv: obj.card_cvv,
            payment_method_code: "credit_card",
            payment_company_code: this.PegaBandeira(obj.card_number) //obj.payment_company_code
        }

        this.dadosDaFatura = {
            code: "",
            valor: obj.valor,
            plano_assinatura_id: obj.plano_assinatura_id,
            recorrente: false
        }    

        this.obj = {
            dadosDoSistema: this.dadosDoSistema,
            dadosDoCliente: this.dadosDoCliente,
            dadosDoCartao: this.dadosDoCartao,
            dadosDaFatura: this.dadosDaFatura
        }
         
    }

    async ConfirmaCompra() {

        await api.post('CompraCreditos', this.obj)
            .then(resp => {
            return 'ok - ' + JSON.stringify(resp)
        })
        .catch( error => {
            return JSON.stringify(error)
        });

    }

    PegaBandeira(numero_cartao) {
        
        var cardnumber = numero_cartao.replace(/[^0-9]+/g, '');
        var tipo = '';

        var cards = {
            mastercard:       /^5(?!04175|067|06699)\d{15}$/,            
            visa:             /^4(?!38935|011|51416|576)\d{12}(?:\d{3})?$/,
            american_express: /^3[47][0-9]{13}/,
            diners_club:      /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
            elo:              /^[456](?:011|38935|51416|576|04175|067|06699|36368|36297)\d{10}(?:\d{2})?$/,
            hipercard:        /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
            jcb:              /^(?:2131|1800|35\d{3})\d{11}/
        };

        for (var flag in cards) {
            if(cards[flag].test(cardnumber)) {
                tipo = flag;
            }
        }
        
        return tipo;
    }
  }
  