<template>
  <div class="fluid overflow-hidden bg-cinza-claro">
    <vue-topprogress ref="topProgress"></vue-topprogress>
    <div class="container">
      <div class="d-flex align-items-center">
        <div>
          <h3 class="ff-roboto-slab mt-4 pb-0 mb-0">Excluir sua conta</h3>
          <small class="text-uppercase">
            Seus dados serão removidos do LegalLake</small
          >
        </div>
      </div>

      <div class="bg-white p-4 area-usario mt-3 mb-5">
        <div class="p-3 d-flex flex-column flex-md-row align-items-stretch">
          <div>
            <div class="ttl mb-4">
              Antes de prosseguir, leia o informativo abaixo
            </div>
            <div class="ttl-alt mb-4">
              Nos preocupamos com os seus dados pessoais e trabalhamos com
              vários mecanismos para mitigação de riscos, proteção à privacidade
              e segurança da informação.
            </div>
            <div class="ttl-alt mb-4">
              Você como titular dos seus dados pessoais, possui direitos
              estabelecidos pela Lei Geral de Proteção de Dados (LGPD).
              Portanto, você poderá excluir seus dados pessoais que são mantidos
              pelo LegalLake, que são dados necessários para que você possa
              utilizar o nosso serviço.
            </div>
            <div class="ttl-alt mb-4">
              Entretanto, é importante que você saiba que após a confirmação da
              exclusão dos seus dados, a sua conta se tornará inativa. Sendo
              assim, todos os valores de créditos em sua conta e o seu
              histórico, serão removidos definitivamente da base de dados do
              LegalLake.
            </div>
            <div class="ttl-alt mb-4">
              Após confirmada a solicitação de exclusão da sua conta, você não
              conseguirá mais ter acessa a mesma. Seus créditos serão removidos
              e não háverá devolução do seu saldo.
            </div>
            <div class="ttl-alt mb-4">
              Esse processo após confirmado, será irreversível. Manteremos seus
              dados pessoais em nossas bases por um período de até 30 dias, para
              análises e auditoria do serviço, sem a possibilidade de exportar
              os dados ou reativar a conta.
            </div>
            <div class="ttl-alt mb-4">
              Você poderá solicitar rapidamente a exportação dos seus dados
              entes de prosseguir clicando
              <a href="#/exportar-dados-pessoais">aqui</a>.
            </div>
            <div class="ttl-alt mb-4">
              Em qualquer momento, você poderá criar uma nova conta para
              usufruir dos nossos serviços.
            </div>
            <div class="ttl-alt mb-4">
              Assim que clicar no botão "Eu quero excluir a minha conta", você
              receberá um e-mail para o endereço cadastrado como medida de
              segurança. Somente após confirmar a exclusão da sua conta
              confirmando ela pelo e-mail que receberá, é que a sua conta será
              inativada definitivamente.
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-betwee">
          <button type="button" class="btn btn-link" @click="voltar()">
            <i class="fas fa-chevron-left"></i> Voltar
          </button>
          <button
            type="button"
            class="btn btn-primary ml-auto"
            @click="enviarEmailConfirmaacoCancelamento()"
          >
            Eu quero excluir a minha conta
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../config/api";
import UsuarioLogado from "../config/usuario-logado";

export default {
  data: function() {
    return {};
  },
  methods: {
    mounted() {
      this.$refs.topProgress.start();
      setTimeout(() => {
        this.$refs.topProgress.done();
      }, 150);
    },
    voltar() {
      this.$router.push({ path: "/usuario" });
    },
    enviarEmailConfirmaacoCancelamento() {
      let loader = this.$loading.show();
      var cliente = UsuarioLogado.obterUsuarioLogado();
      api
        .post(`email/enviar-confirmacao-cancelamento-conta`, {
          ClienteId: cliente.clienteId,
        })
        .then(() => {
          this.$root.$bvToast.toast(
            "Foi enviado para o seu e-mail informações para o cancelamento da conta.",
            {
              title: "Sucesso",
              variant: "success",
              solid: true,
            }
          );
          this.emailEnviado = true;
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>
