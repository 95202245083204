var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-sm-flex align-items-md-center container-onda"},[_c('vue-topprogress',{ref:"topProgress"}),_c('div',{staticClass:"login m-auto"},[_vm._m(0),_c('div',{staticClass:"px-3 px-md-5 py-4"},[_vm._m(1),(!_vm.senhaAlterada)?_c('div',[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"novaSenha"}},[_vm._v("Nova Senha")]),_c('div',{staticClass:"input-group mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.alteracaoSenha.novaSenha),expression:"alteracaoSenha.novaSenha"}],staticClass:"form-control",class:{ 'is-invalid': _vm.$v.alteracaoSenha.novaSenha.$error },attrs:{"id":"nova-senha","type":"password","placeholder":"• • • • •"},domProps:{"value":(_vm.alteracaoSenha.novaSenha)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.alterarSenha()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.alteracaoSenha, "novaSenha", $event.target.value)}}}),(
                !_vm.$v.alteracaoSenha.novaSenha.required && _vm.formularioPostado
              )?_c('b-form-invalid-feedback',[_vm._v("Campo obrigatório"),_c('br')]):_vm._e(),(
                !_vm.$v.alteracaoSenha.novaSenha.minLength && _vm.formularioPostado
              )?_c('b-form-invalid-feedback',[_vm._v("Sua senha deve ter no mínimo 6 caracteres"),_c('br')]):_vm._e()],1)]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"confirmacaoSenha"}},[_vm._v("Repetir nova senha")]),_c('div',{staticClass:"input-group mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.alteracaoSenha.confirmacaoSenha),expression:"alteracaoSenha.confirmacaoSenha"}],staticClass:"form-control",class:{
                'is-invalid': _vm.$v.alteracaoSenha.confirmacaoSenha.$error,
              },attrs:{"type":"password","placeholder":"• • • • •"},domProps:{"value":(_vm.alteracaoSenha.confirmacaoSenha)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.alterarSenha()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.alteracaoSenha, "confirmacaoSenha", $event.target.value)}}}),(
                !_vm.$v.alteracaoSenha.confirmacaoSenha.required &&
                _vm.formularioPostado
              )?_c('b-form-invalid-feedback',[_vm._v("Campo obrigatório")]):_vm._e(),(
                !_vm.$v.alteracaoSenha.confirmacaoSenha.sameAs &&
                _vm.$v.alteracaoSenha.confirmacaoSenha.required &&
                _vm.formularioPostado
              )?_c('b-form-invalid-feedback',[_vm._v("Confirmação de senha está diferente")]):_vm._e()],1)]),_c('button',{staticClass:"btn mt-4 btn-primary btn-block",attrs:{"type":"button"},on:{"click":function($event){return _vm.alterarSenha()}}},[_c('i',{staticClass:"fas fa-shield-alt pr-2"}),_vm._v("Salvar nova senha ")])]):_vm._e(),(_vm.senhaAlterada)?_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"py-3"},[_vm._v("Senha alterada.")])]):_vm._e(),_vm._m(2)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-padrao p-3 text-center"},[_c('img',{staticClass:"logo",attrs:{"src":require("../assets/imgs/legallake-branco.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('h3',{staticClass:"p-0 m-0 font-weight-normal"},[_vm._v("Recuperar Senha")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-3 text-center small"},[_c('a',{attrs:{"href":"/"}},[_vm._v("Voltar para Legal Lake")])])}]

export { render, staticRenderFns }