<template>
  <div class="d-sm-flex align-items-md-center container-onda">
    <vue-topprogress ref="topProgress"></vue-topprogress>
    <div class="login m-auto">
      <div class="header-padrao p-3 text-center">
        <a href="/">
          <img class="logo" src="../assets/imgs/legallake-branco.png" />
        </a>
      </div>
      <div class="px-3 px-md-5 py-4">
        <div class="text-center">
          <h3 class="p-0 m-0 font-weight-normal">Cadastro</h3>
          <h6 class="p-2 small">
            Encontre informações em milhares de processos.
          </h6>
        </div>
        <div class="form-group">
          <label for="nome">Nome</label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.cliente.nome.$error }"
            id="nome"
            placeholder="Seu nome completo"
            v-model="cliente.nome"
            autocomplete="off"
            @keyup.enter="cadastrar()"
          />
          <b-form-invalid-feedback
            v-if="!$v.cliente.nome.required && formularioPostado"
            >Campo obrigatório</b-form-invalid-feedback
          >
        </div>
        <div class="form-group">
          <label for="email">E-mail</label>
          <input
            autocomplete="off"
            type="email"
            class="form-control"
            id="email"
            placeholder="nome@servidor.com.br"
            v-model="cliente.email"
            :class="{ 'is-invalid': $v.cliente.email.$error }"
            @keyup.enter="cadastrar()"
          />
          <b-form-invalid-feedback
            v-if="!$v.cliente.email.required && formularioPostado"
            >Campo obrigatório<br
          /></b-form-invalid-feedback>
          <b-form-invalid-feedback
            v-if="!$v.cliente.email.email && formularioPostado"
            >E-mail inválido<br
          /></b-form-invalid-feedback>
        </div>
        <div class="form-group">
          <label for="confirmarEmail">Confirmar E-mail</label>
          <input
            type="email"
            class="form-control"
            id="confirmarEmail"
            placeholder="nome@servidor.com.br"
            v-model="cliente.confirmarEmail"
            :class="{ 'is-invalid': $v.cliente.confirmarEmail.$error }"
            @keyup.enter="cadastrar()"
            autocomplete="off"
          />
          <b-form-invalid-feedback
            v-if="!$v.cliente.confirmarEmail.required && formularioPostado"
            >Campo obrigatório<br
          /></b-form-invalid-feedback>
          <b-form-invalid-feedback
            v-if="
              $v.cliente.confirmarEmail.required &&
              !$v.cliente.confirmarEmail.sameAs &&
              formularioPostado
            "
            >Confirmação de E-mail está diferente<br
          /></b-form-invalid-feedback>
        </div>
        <div class="form-group">
          <label for="celular">Celular</label>
          <input
            type="text"
            class="form-control"
            id="celular"
            placeholder="Seu número de celular com DDD"
            v-model="cliente.celular"
            v-mask="['(##) ####-####', '(##) # ####-####']"
            :class="{ 'is-invalid': $v.cliente.celular.$error }"
            @keyup.enter="cadastrar()"
            autocomplete="off"
          />
          <b-form-invalid-feedback
            v-if="!$v.cliente.celular.required && formularioPostado"
            >Campo obrigatório<br
          /></b-form-invalid-feedback>
          <b-form-invalid-feedback
            v-if="!$v.cliente.celular.minLength && formularioPostado"
            >Número inválido<br
          /></b-form-invalid-feedback>
        </div>
        <div class="form-group">
          <label for="estado">Estado</label>
          <b-form-select
            :class="{ 'is-invalid': $v.cliente.estado.$error }"
            v-model="cliente.estado"
            @change="
              cidade = '';
              carregaComboMunicipios(cliente.estado);
            "
          >
            <b-form-select-option
              :value="null"
              selected="selected"
            ></b-form-select-option>
            <b-form-select-option value="AC">Acre</b-form-select-option>
            <b-form-select-option value="AL">Alagoas</b-form-select-option>
            <b-form-select-option value="AP">Amapá</b-form-select-option>
            <b-form-select-option value="AM">Amazonas</b-form-select-option>
            <b-form-select-option value="BA">Bahia</b-form-select-option>
            <b-form-select-option value="CE">Ceará</b-form-select-option>
            <b-form-select-option value="DF"
              >Distrito Federal</b-form-select-option
            >
            <b-form-select-option value="ES"
              >Espírito Santo</b-form-select-option
            >
            <b-form-select-option value="GO">Goiás</b-form-select-option>
            <b-form-select-option value="MA">Maranhão</b-form-select-option>
            <b-form-select-option value="MT">Mato Grosso</b-form-select-option>
            <b-form-select-option value="MS"
              >Mato Grosso do Sul</b-form-select-option
            >
            <b-form-select-option value="MG">Minas Gerais</b-form-select-option>
            <b-form-select-option value="PA">Pará</b-form-select-option>
            <b-form-select-option value="PB">Paraíba</b-form-select-option>
            <b-form-select-option value="PR">Paraná</b-form-select-option>
            <b-form-select-option value="PE">Pernambuco</b-form-select-option>
            <b-form-select-option value="PI">Piauí</b-form-select-option>
            <b-form-select-option value="RJ"
              >Rio de Janeiro</b-form-select-option
            >
            <b-form-select-option value="RN"
              >Rio Grande do Norte</b-form-select-option
            >
            <b-form-select-option value="RS"
              >Rio Grande do Sul</b-form-select-option
            >
            <b-form-select-option value="RO">Rondônia</b-form-select-option>
            <b-form-select-option value="RR">Roraima</b-form-select-option>
            <b-form-select-option value="SC"
              >Santa Catarina</b-form-select-option
            >
            <b-form-select-option value="SP">São Paulo</b-form-select-option>
            <b-form-select-option value="SE">Sergipe</b-form-select-option>
            <b-form-select-option value="TO">Tocantins</b-form-select-option>
          </b-form-select>
          <b-form-invalid-feedback
            v-if="!$v.cliente.estado.required && formularioPostado"
            >Campo obrigatório</b-form-invalid-feedback
          >
        </div>
        <div class="form-group">
          <label for="cidade">Cidade</label>
          <b-form-select
            v-model="cliente.municipio"
            value-field="municipioId"
            text-field="nomeMunicipio"
            :options="municipios"
            :class="{
              'is-invalid':
                $v.cliente.estado.required && $v.cliente.municipio.$error,
            }"
            :disabled="municipios.length <= 0"
          >
            <b-form-select-option
              :value="null"
              selected="selected"
            ></b-form-select-option>
          </b-form-select>
          <b-form-invalid-feedback
            v-if="
              $v.cliente.estado.required &&
              !$v.cliente.municipio.required &&
              formularioPostado
            "
            >Campo obrigatório</b-form-invalid-feedback
          >
        </div>
        <div class="form-group">
          <label for="senha">Senha</label>
          <input
            type="password"
            class="form-control"
            id="senha"
            placeholder="Uma senha segura"
            v-model="cliente.senha"
            :class="{ 'is-invalid': $v.cliente.senha.$error }"
            @keyup.enter="cadastrar()"
          />
          <b-form-invalid-feedback
            v-if="!$v.cliente.senha.required && formularioPostado"
            >Campo obrigatório<br
          /></b-form-invalid-feedback>
          <b-form-invalid-feedback
            v-if="!$v.cliente.senha.minLength && formularioPostado"
            >Sua senha deve ter no mínimo 6 caracteres<br
          /></b-form-invalid-feedback>
        </div>
        <div class="form-group">
          <label for="repetirSenha">Repetir Senha:</label>
          <input
            type="password"
            class="form-control"
            id="repetirSenha"
            placeholder="Repetir sua senha"
            v-model="cliente.confirmacaoSenha"
            :class="{ 'is-invalid': $v.cliente.confirmacaoSenha.$error }"
            @keyup.enter="cadastrar()"
          />
          <b-form-invalid-feedback
            v-if="!$v.cliente.confirmacaoSenha.required && formularioPostado"
            >Campo obrigatório</b-form-invalid-feedback
          >
          <b-form-invalid-feedback
            v-if="
              !$v.cliente.confirmacaoSenha.sameAs &&
              $v.cliente.confirmacaoSenha.required &&
              formularioPostado
            "
            >Confirmação de senha está diferente</b-form-invalid-feedback
          >
        </div>
        <div class="form-group small">
          Ao se cadastrar, você estará concordando com os <a href="#/termos-de-uso" target="_blank">Termos de Serviço</a>
        </div>
        <button
          type="button"
          class="btn btn-primary btn-block"
          @click="cadastrar()"
        >
          Cadastrar
          <i class="fas fa-arrow-right pl-2"></i>
        </button>
        <div class="pt-3 text-center small">
          Já tenho cadastro, <a href="#/login">Fazer Login</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../config/api";
import UsuarioLogado from "../config/usuario-logado";
import { required, email, sameAs, minLength } from "vuelidate/lib/validators";

export default {
  name: "cadastro",
  mounted() {
    document.getElementById("nome").focus();
    this.$refs.topProgress.start();
    setTimeout(() => {
      this.$refs.topProgress.done();
    }, 150);
  },
  data: function () {
    return {
      formularioPostado: false,
      cliente: {
        nome: "",
        email: "",
        confirmarEmail: "",
        celular: "",
        senha: "",
        confirmacaoSenha: "",
        estado: "",
        municipio: "",
      },
      municipios: [],
    };
  },
  validations: {
    cliente: {
      nome: { required },
      email: { required, email },
      confirmarEmail: { required, sameAs: sameAs("email") },
      celular: { required, minLength: minLength(10) },
      estado: { required },
      municipio: { required },
      senha: { required, minLength: minLength(6) },
      confirmacaoSenha: { required, sameAs: sameAs("senha") },
    },
  },
  methods: {
    carregaComboMunicipios(estado) {
      if (estado !== "") {
        let loader = this.$loading.show();
        api
          .get(`municipios/combo?estado=${estado}`)
          .then((resp) => {
            this.municipios = resp.data;
          })
          .finally(() => {
            loader.hide();
          });
      }
    },
    cadastrar: function () {
      this.$v.cliente.$touch();
      this.formularioPostado = true;
      if (!this.$v.cliente.$error) {
        let loader = this.$loading.show();
        api
          .post(`clientes`, {
            nome: this.cliente.nome,
            email: this.cliente.email,
            celular: this.cliente.celular,
            senhaAcesso: this.cliente.senha,
            enderecoUF: this.cliente.estado,
            municipioId: this.cliente.municipio,
          })
          .then(() => {
            this.logar();
          })
          .finally(() => {
            loader.hide();
          });
      }
    },
    logar: function () {
      let loader = this.$loading.show();
      api
        .post(`login/token`, {
          email: this.cliente.email,
          senha: this.cliente.senha,
        })
        .then((resposta) => {
          UsuarioLogado.inserirUsuarioLogado(
            new UsuarioLogado(
              resposta.data.nome,
              resposta.data.email,
              resposta.data.clienteId,
              resposta.data.token,
              false
            )
          );
          localStorage.setItem("access_token", resposta.data.token);
          this.$router.push({ path: "/sms" });
        })
        .catch(() => {
          this.$router.push({ path: "/login" });
        })
        .finally(() => {
          this.$root.$bvToast.toast("Cadastro criado com sucesso", {
            title: "Sucesso",
            variant: "success",
            solid: true,
          });
          loader.hide();
        });
    },
  },
};
</script>