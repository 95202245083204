import { app } from "@/main";
import UsuarioLogado from './usuario-logado';
import Token from './token'

export default class GuardUsuarioLogado {
    static verificarUsuarioLogado(to, from, next) {
        var usuario = UsuarioLogado.obterUsuarioLogado();
        if (!usuario) {
            next({ name: 'Login' });
            app.$bvToast.toast(`Usuário não está logado, favor logar novamente.`, {
                title: `Erro`,
                variant: "danger",
                solid: true
            });
        }
        else if (Token.expirado()) {
            UsuarioLogado.removerUsuarioLogado();
            next({ name: 'Login' });
            app.$bvToast.toast(`Sessão expirada, favor logar novamente.`, {
                title: `Erro`,
                variant: "danger",
                solid: true
            });
        }
        else {
            next();
        }
    }
}
