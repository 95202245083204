<template>
  <div class="d-sm-flex align-items-md-center container-onda">
    <vue-topprogress ref="topProgress"></vue-topprogress>
    <div class="login m-auto">
      <div class="header-padrao p-3 text-center">
        <img class="logo" src="../assets/imgs/legallake-branco.png" />
      </div>
      <div class="px-5 py-4">
        <div v-if="verificado" class="text-center">
          <div class="py-3">E-mail verificado.</div>
        </div>
        <div v-if="erroCodigo" class="text-center">
          <div class="py-3">E-mail de verificação expirado ou com erro.</div>
        </div>
        <div v-if="reenviado" class="text-center">
          <div class="py-3">
            E-mail de verificação enviado, verifique seu e-mail.
          </div>
        </div>
        <div v-if="erroCodigo" class="py-3 text-center small">
          <a href="javascript:void(0);" @click="reenviarEmailConfirmacao()"
            >Reenviar e-mail de confirmação</a
          >
        </div>
        <div class="py-3 text-center small">
          <a href="/">Voltar para Legal Lake</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../config/api";
import UsuarioLogado from "../config/usuario-logado";

export default {
  mounted: function () {
    this.$refs.topProgress.start();
    this.enviarConfirmacao();
  },
  data: function () {
    return {
      erroCodigo: false,
      verificado: false,
      reenviado: false,
    };
  },
  methods: {
    reenviarEmailConfirmacao() {
      this.erroCodigo = false;
      this.verificado = false;
      let loader = this.$loading.show();
      var cliente = UsuarioLogado.obterUsuarioLogado();
      api
        .post(`email/enviar-verificacao?clienteId=${cliente.clienteId}`)
        .then((resposta) => {
          this.numero = resposta.data;
          this.reenviado = true;
        })
        .finally(() => {
          loader.hide();
        });
    },
    enviarConfirmacao() {
      var codigo = this.$route.query.codigo;
      var cliente = UsuarioLogado.obterUsuarioLogado();
      api
        .post("email/verificar", {
          clienteId: cliente.clienteId,
          codigo: codigo,
        })
        .then(() => {
          cliente.emailConfirmado = true;
          UsuarioLogado.inserirUsuarioLogado(cliente);
          this.verificado = true;
        })
        .catch(() => {
          this.erroCodigo = true;
        })
        .finally(() => {
          setTimeout(() => {
            this.$refs.topProgress.done();
          }, 150);
        });
    },
  },
};
</script>