export default class UsuarioLogado {
    constructor(nome, email, clienteId, token, clienteFAZ) {
        this.nome = nome;
        this.email = email;
        this.clienteId = clienteId;
        this.token = token;
        this.ClienteFAZ = clienteFAZ;
    }
    static obterUsuarioLogado() {
        let usuarioLogado = JSON.parse(localStorage.getItem('usuario'));
        return usuarioLogado;
    }

    static removerUsuarioLogado() {
        localStorage.removeItem('usuario');
        localStorage.removeItem('access_token');
        localStorage.removeItem('ultimo-aviso-vencimento');
    }

    static inserirUsuarioLogado(usuarioLogado) {
        localStorage.setItem('usuario', JSON.stringify(usuarioLogado));
    }
}