<template>
  <div class="d-sm-flex align-items-md-center container-onda">
    <div class="login m-auto">
      <div class="header-padrao p-3 text-center">
        <a href="#/"
          ><img class="logo" src="../assets/imgs/legallake-branco.png"
        /></a>
      </div>
      <div class="px-3 px-md-5 py-4">
        <div class="text-center">
          <h3 class="p-0 m-0 font-weight-normal">Pedido</h3>
          <h6 class="p-2 small">
            Falta pouco, confirme seu pedido para finalizar
          </h6>
        </div>
        <div class="alert alert-vermelho d-none" role="alert">
          Por favor preencha todos os campos obrigatorios.
        </div>
        <div class="pedidos">
          <div class="pedido zig-zag-bottom mx-3 py-3 d-flex">
            <div class="text-uppercase">
              <div class="tipo-plano">
                <b>{{ tipoPlano }}</b>
              </div>
              <div class="visualizacoes small">{{ descricaoPlano }}</div>
            </div>
            <div class="preco ml-auto">
              <b>{{ valorPlanoSel | currency }}</b>
            </div>
          </div>
        </div>
        <div class="termo my-4">
          <div class="alert alert-primary" role="alert" for="aceitarTermo">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="aceitarTermo"
                v-model="termoAdesao"
              />
              <label class="form-check-label">
                Aceito o
                <a href="#" v-b-modal.termoadesao>Termo de Adesão</a>
                <TermoDeAdesao />
              </label>
            </div>
          </div>
        </div>
        <div class="tabs">
          <div>
            <b-card no-body>
              <b-tabs pills card fill>
                <b-tab title="Cartão de Crédito" v-if="exibirCartao" active>
                  <b-card-text>
                    <div>
                      <h4>Dados do cartão</h4>
                      <div
                        class="alert alert-vermelho"
                        role="alert"
                        v-show="erros.length > 0"
                      >
                        <li v-for="(erro, index) in erros" :key="index">
                          {{ erro }}
                        </li>
                      </div>
                      <div class="panel-body">
                        <form role="form" @submit.prevent="EfetuaPagamento">
                          <div class="form-group">
                            <label for="CPF">CPF</label>
                            <div class="input-group">
                              <input
                                type="text"
                                class="form-control"
                                id="CPF"
                                placeholder="CPF do titular"
                                v-model="cpf"
                                v-mask="'###.###.###-##'"
                                autofocus
                              />
                            </div>
                          </div>
                          <div class="form-group">
                            <label for="titularCartao">Nome</label>
                            <div class="input-group">
                              <input
                                type="text"
                                class="form-control"
                                id="titularCartao"
                                placeholder="Nome do titular do cartão"
                                v-model="holder_name"
                                autofocus
                              />
                            </div>
                          </div>
                          <div class="form-group">
                            <label for="numeroCartao">Número do Cartão</label>
                            <div class="input-group">
                              <input
                                type="text"
                                class="form-control"
                                id="numeroCartao"
                                placeholder="Cartão válido"
                                v-model="card_Number"
                                v-mask="[
                                  '#### ###### #####',
                                  '#### #### #### ####',
                                ]"
                                autofocus
                              />
                              <span class="input-group-addon"> </span>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xs-7 col-md-7">
                              <div class="form-group">
                                <label for="expiracaoData"
                                  >Validade do cartão</label
                                >
                                <div class="row">
                                  <div class="col-xs-6 col-lg-6 pl-ziro">
                                    <input
                                      name="validademes"
                                      type="text"
                                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                      maxlength="2"
                                      class="form-control"
                                      id="expiracaoData"
                                      placeholder="MM"
                                      v-model="ValidadeMes"
                                    />
                                  </div>
                                  <div class="col-xs-6 col-lg-6 pl-ziro">
                                    <input
                                      name="validadeano"
                                      type="text"
                                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                      class="form-control"
                                      id="expiracaoAno"
                                      placeholder="AAAA"
                                      v-model="ValidadeAno"
                                      maxlength="4"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xs-5 col-md-5 pull-right">
                              <div class="form-group">
                                <label for="cvcCode">Código CVC</label>
                                <input
                                  type="password"
                                  class="form-control"
                                  id="cvcCode"
                                  placeholder="CVC"
                                  maxlength="4"
                                  v-model="card_cvv"
                                />
                              </div>
                            </div>
                          </div>
                          <button
                            type="submit"
                            @click="EfetuaPagamento"
                            :disabled="this.processando"
                            class="btn btn-primary btn-block"
                          >
                            <span
                              v-show="this.processando"
                              class="spinner-border spinner-border-sm mr-2"
                              role="status"
                              aria-hidden="true"
                            ></span>

                            <span v-show="this.processando == false"
                              ><i class="fas fa-shield-alt pr-2"></i>PAGAR
                              {{ valorPlanoSel | currency }}</span
                            >
                            <span v-show="this.processando"
                              >Processando Pagamento...</span
                            >
                          </button>
                        </form>
                      </div>
                    </div></b-card-text
                  >
                </b-tab>
                <b-tab
                  title="PIX"
                  v-if="tipoPlano !== 'ASSINATURA' && exibirPix"
                >
                  <b-card-text v-if="!qrCodePix">
                    <div>
                      <h4>Dados do PIX</h4>
                    </div>
                    <div
                      class="alert alert-vermelho"
                      role="alert"
                      v-show="pix.errosPix.length > 0"
                    >
                      <li v-for="(erro, index) in pix.errosPix" :key="index">
                        {{ erro }}
                      </li>
                    </div>
                    <div class="panel-body">
                      <b-form-group>
                        <b-form-radio-group
                          id="radio-group-pix"
                          v-model="pix.pixTipoPessoa"
                          name="radio-sub-component"
                        >
                          <b-form-radio
                            v-model="pix.pixTipoPessoa"
                            name="fisica"
                            value="fisica"
                            >Pessoa Física</b-form-radio
                          >
                          <b-form-radio
                            v-model="pix.pixTipoPessoa"
                            name="juridica"
                            value="juridica"
                            >Pessoa Jurídica</b-form-radio
                          >
                        </b-form-radio-group>
                      </b-form-group>

                      <div
                        class="form-group"
                        v-if="pix.pixTipoPessoa === 'juridica'"
                      >
                        <label for="cnpjPix">CNPJ</label>
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control"
                            id="cnpjPix"
                            placeholder="CNPJ"
                            v-model="pix.cnpjPix"
                            v-mask="'##.###.###/####-##'"
                          />
                        </div>
                      </div>
                      <div
                        class="form-group"
                        v-if="pix.pixTipoPessoa === 'fisica'"
                      >
                        <label for="cpfPix">CPF</label>
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control"
                            id="cpfPix"
                            placeholder="CPF"
                            v-model="pix.cpfPix"
                            v-mask="'###.###.###-##'"
                            autofocus
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="titularCartao">Nome</label>
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control"
                            id="titularCartao"
                            placeholder="Nome"
                            v-model="pix.nomePix"
                            autofocus
                          />
                        </div>
                      </div>
                      <button
                        type="button"
                        @click="obterQRCodePix"
                        :disabled="this.processando"
                        class="btn btn-primary btn-block"
                      >
                        <span
                          v-show="this.processando"
                          class="spinner-border spinner-border-sm mr-2"
                          role="status"
                          aria-hidden="true"
                        ></span>

                        <span v-show="this.processando === false"
                          ><i class="fas fa-shield-alt pr-2"></i>GERAR QR CODE
                          {{ valorPlanoSel | currency }}</span
                        >
                        <span v-show="this.processando"
                          >Gerando QR Code...</span
                        >
                      </button>
                    </div>
                  </b-card-text>
                  <b-card-text v-if="qrCodePix">
                    <div>
                      <h4>QR code PIX</h4>
                    </div>
                    <div class="panel-body">
                      <div class="mb-2 text-center bg-light p-2">
                        <i class="fas fa-clock pr-2"></i>Este QR code expira em
                        30 minutos.
                      </div>
                      <button
                        class="btn btn-primary btn-azul btn-block mt-4"
                        @click="copiarCodigoPix()"
                      >
                        Copiar código Pix
                      </button>
                      <div class="mb-2 text-center bg-light p-2">
                        Escaneie o QR Code utilizando o aplicativo da sua
                        instituição bancária no seu celular
                      </div>
                      <div class="mb-2 text-center bg-light py-3">
                        <img :src="qrCodePix" alt="QR Code Pix" />
                      </div>
                    </div>
                  </b-card-text>
                </b-tab>
                <b-tab
                  title="Boleto"
                  v-if="tipoPlano !== 'ASSINATURA' && exibirBoleto"
                >
                  <b-card-text v-if="!resultadoBoleto">
                    <div>
                      <h4>Dados do Boleto</h4>
                    </div>
                    <div
                      class="alert alert-vermelho"
                      role="alert"
                      v-show="boleto.errosBoleto.length > 0"
                    >
                      <li
                        v-for="(erro, index) in boleto.errosBoleto"
                        :key="index"
                      >
                        {{ erro }}
                      </li>
                    </div>
                    <div class="panel-body">
                      <b-form-group>
                        <b-form-radio-group
                          id="radio-group-boleto"
                          v-model="boleto.boletoTipoPessoa"
                          name="radio-sub-component"
                        >
                          <b-form-radio
                            v-model="boleto.boletoTipoPessoa"
                            name="fisica"
                            value="fisica"
                            >Pessoa Física</b-form-radio
                          >
                          <b-form-radio
                            v-model="boleto.boletoTipoPessoa"
                            name="juridica"
                            value="juridica"
                            >Pessoa Jurídica</b-form-radio
                          >
                        </b-form-radio-group>
                      </b-form-group>

                      <div
                        class="form-group"
                        v-if="boleto.boletoTipoPessoa === 'juridica'"
                      >
                        <label for="cnpjBoleto">CNPJ</label>
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control"
                            id="cnpjBoleto"
                            placeholder="CNPJ"
                            v-model="boleto.cnpjBoleto"
                            v-mask="'##.###.###/####-##'"
                          />
                        </div>
                      </div>
                      <div
                        class="form-group"
                        v-if="boleto.boletoTipoPessoa === 'fisica'"
                      >
                        <label for="cpfBoleto">CPF</label>
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control"
                            id="cpfBoleto"
                            placeholder="CPF"
                            v-model="boleto.cpfBoleto"
                            v-mask="'###.###.###-##'"
                            autofocus
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="nomeBoleto">Nome</label>
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control"
                            id="nomeBoleto"
                            placeholder="Nome"
                            v-model="boleto.nomeBoleto"
                            autofocus
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="telefoneBoleto">Telefone</label>
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control"
                            id="telefoneBoleto"
                            placeholder="Telefone"
                            v-mask="['(##) ####-####', '(##) # ####-####']"
                            v-model="boleto.telefoneBoleto"
                            autofocus
                          />
                        </div>
                      </div>
                      <button
                        type="button"
                        @click="obterBoleto()"
                        class="btn btn-primary btn-block"
                      >
                        <span
                          v-show="this.processando"
                          class="spinner-border spinner-border-sm mr-2"
                          role="status"
                          aria-hidden="true"
                        ></span>

                        <span v-show="this.processando === false"
                          ><i class="fas fa-shield-alt pr-2"></i>GERAR BOLETO
                          {{ valorPlanoSel | currency }}</span
                        >
                        <span v-show="this.processando">Gerando Boleto...</span>
                      </button>
                    </div>
                  </b-card-text>
                  <b-card-text v-if="resultadoBoleto">
                    <div>
                      <h4>Boleto</h4>
                    </div>
                    <div class="panel-body">
                      <div class="mb-2 text-center bg-light p-2">
                        Após o pagamento este boleto será compensado em até
                        1(um) dia útil.
                      </div>
                      <div class="mb-2 text-center bg-light p-2">
                        <b>Código de barras</b><br />{{
                          resultadoBoleto.codigoDeBarras
                        }}
                      </div>
                      <button
                        class="btn btn-primary btn-azul btn-block mt-4"
                        @click="copiarCodigoDeBarras()"
                      >
                        Copiar código de barras
                      </button>
                      <a
                        class="btn btn-primary btn-azul btn-block mt-4"
                        :href="resultadoBoleto.urlBoleto"
                        target="_blank"
                        >Exibir Boleto</a
                      >
                    </div>
                  </b-card-text>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
  
<script>
import CompraCreditos from "../models/CompraCreditos";
import api from "../config/api";
import TermoDeAdesao from "@/views/TermoAdesao";
import Validacoes from "../config/validacoes";
import UsuarioLogado from "../config/usuario-logado";
import Pagamentos from "../config/pagamentos";

export default {
  data() {
    return {
      DadosCompra: {},
      holder_name: "",
      cpf: "",
      ValidadeMes: "",
      ValidadeAno: "",
      card_Number: "",
      card_cvv: "",
      valor: 0,
      termoAdesao: false,
      erros: [],
      qrCodePix: null,
      codigoPix: null,
      resultadoBoleto: null,
      processando: false,
      vendaAprovada: false,
      exibirCartao: true,
      exibirPix: true,
      exibirBoleto: true,
      pix: {
        cpfPix: "",
        cnpjPix: "",
        nomePix: "",
        pixTipoPessoa: "fisica",
        errosPix: [],
      },
      boleto: {
        cpfBoleto: "",
        cnpjBoleto: "",
        nomeBoleto: "",
        telefoneBoleto: "",
        boletoTipoPessoa: "fisica",
        errosBoleto: [],
      },
    };
  },
  components: {
    TermoDeAdesao,
  },
  methods: {
    EfetuaPagamento() {
      this.erros = [];

      if (!this.cpf) {
        this.erros.push("Informe o CPF do titular");
      } else {
        if (this.validaCPF(this.cpf) == false) {
          this.erros.push("CPF inválido");
        }
      }

      if (!this.holder_name) {
        this.erros.push("Informe o nome do titular");
      }

      if (!this.card_Number) {
        this.erros.push("Informe o número do cartão");
      } else {
        if (this.card_Number.replace(/\s/g, "").length < 15) {
          this.erros.push("Número do cartão inválido");
        }
      }

      if (!this.ValidadeMes) {
        this.erros.push("Informe o Mês de validade");
      } else {
        if (this.ValidadeMes < 1 || this.ValidadeMes > 12) {
          this.erros.push("Mês de validade inválido");
        }
      }

      if (this.termoAdesao == false) {
        this.erros.push("Aceite o termo de adesão");
      }

      var ano = 0;
      ano = this.ValidadeAno;

      if (ano < new Date().getFullYear()) {
        this.erros.push("Ano de validade inválido");
      }

      if (!this.card_cvv) {
        this.erros.push("Informe o CVV do cartão");
      }

      if (this.erros.length > 0) {
        return;
      }

      this.processando = true;
      this.exibirBoleto = false;
      this.exibirPix = false;
      let usuarioLogado = JSON.parse(localStorage.getItem("usuario"));

      this.DadosCompra = new CompraCreditos({
        holder_name: this.holder_name,
        card_expiration: this.ValidadeMes + "/" + this.ValidadeAno,
        card_number: this.card_Number,
        card_cvv: this.card_cvv,
        valor: this.valorPlanoSel,
        plano_assinatura_id: this.idPlanoSelecionado,
        idUsuario: usuarioLogado.clienteId,
      });

      this.DadosCompra.dadosDaFatura.recorrente =
        this.tipoPlano == "ASSINATURA";

      api
        .post("CompraCreditos", this.DadosCompra)
        .then((resp) => {
          if (resp.data.resultado === "APROVADO") {
            this.$store.commit("atualizaSaldoDisponivel");

            this.vendaAprovada = true;
            this.$store.commit("comprando", { comprando: false });
            this.processando = false;

            this.$root.$bvToast.toast("Seu pagamento foi aprovado.", {
              title: "Checkout Legallake",
              variant: "success",
              solid: true,
              autoHideDelay: 5000,
            });

            if (this.abrindoPublicacao && this.pesquisa !== "") {
              this.$router.push({
                path: "busca",
                query: {
                  q: this.tipoConsulta, // q = Tipo de consulta
                  w: this.paginaAtual, // w = Página atual
                  pesquisa: this.pesquisa, // pesquisa = Termo pesquisado pelo usuário
                },
              });
            } else {
              this.$router.push({ path: "/" });
            }
          } else {
            this.vendaAprovada = false;

            this.$root.$bvToast.toast(resp.data.msg, {
              title: "Checkout Legallake",
              variant: "danger",
              solid: true,
              autoHideDelay: 5000,
            });

            this.processando = false;
          }
        })
        .catch((error) => {
          this.vendaAprovada = false;

          this.$root.$bvToast.toast(
            "Não conseguimos processar sua solicitação.\n\nPor favor tente mais tarde.",
            {
              title: "Checkout Legallake",
              variant: "danger",
              solid: true,
              autoHideDelay: 5000,
            }
          );

          this.processando = false;
        });
    },
    validaCPF(c) {
      if ((c = c.replace(/[^\d]/g, "")).length != 11) return false;

      if (
        c == "00000000000" ||
        c == "11111111111" ||
        c == "22222222222" ||
        c == "33333333333" ||
        c == "44444444444" ||
        c == "55555555555" ||
        c == "66666666666" ||
        c == "77777777777" ||
        c == "88888888888" ||
        c == "99999999999"
      )
        return false;

      var r;
      var s = 0;
      var i = 0;

      for (i = 1; i <= 9; i++) s = s + parseInt(c[i - 1]) * (11 - i);

      r = (s * 10) % 11;

      if (r == 10 || r == 11) r = 0;

      if (r != parseInt(c[9])) return false;

      s = 0;

      for (i = 1; i <= 10; i++) s = s + parseInt(c[i - 1]) * (12 - i);

      r = (s * 10) % 11;

      if (r == 10 || r == 11) r = 0;

      if (r != parseInt(c[10])) return false;

      return true;
    },
    obterQRCodePix() {
      if (this.validarPix()) {
        this.processando = true;
        this.exibirCartao = false;
        this.exibirBoleto = false;
        var usuarioLogado = UsuarioLogado.obterUsuarioLogado();

        let dadosPix = {
          nome: this.pix.nomePix,
          valor: this.valorPlanoSel,
          planoAssinaturaId: this.idPlanoSelecionado,
          clienteId: usuarioLogado.clienteId,
          tipoDePessoa: this.pix.pixTipoPessoa,
        };

        if (this.pix.pixTipoPessoa === "fisica") {
          dadosPix.cpfCnpj = this.pix.cpfPix;
        }
        if (this.pix.pixTipoPessoa === "juridica") {
          dadosPix.cpfCnpj = this.pix.cnpjPix;
        }

        api.post("pagamentos/pix/gerar-qr-code", dadosPix).then((resp) => {
          this.qrCodePix = `${resp.data.imagem}`;
          this.codigoPix = resp.data.codigo;
          Pagamentos.obterPagamentosPendentesDoUsuario();
          this.processando = false;
        });
      }
    },
    copiarCodigoDeBarras() {
      var toaster = this.$bvToast;
      navigator.clipboard
        .writeText(this.resultadoBoleto.codigoDeBarras)
        .then(function () {
          toaster.toast("Código de barras copiado.", {
            title: "Sucesso",
            variant: "success",
            solid: true,
            autoHideDelay: 5000,
          });
        });
    },
    copiarCodigoPix() {
      var toaster = this.$bvToast;
      navigator.clipboard.writeText(this.codigoPix).then(function () {
        toaster.toast("Código Pix copiado.", {
          title: "Sucesso",
          variant: "success",
          solid: true,
          autoHideDelay: 5000,
        });
      });
    },
    validarPix() {
      this.pix.errosPix = [];

      if (this.pix.pixTipoPessoa === "fisica") {
        if (!this.pix.cpfPix) {
          this.pix.errosPix.push("Informe o CPF");
        } else {
          if (!this.validaCPF(this.pix.cpfPix)) {
            this.pix.errosPix.push("CPF inválido");
          }
        }
      }

      if (this.pix.pixTipoPessoa === "juridica") {
        if (!this.pix.cnpjPix) {
          this.pix.errosPix.push("Informe o CNPJ");
        } else {
          if (!Validacoes.validarCNPJ(this.pix.cnpjPix)) {
            this.pix.errosPix.push("CNPJ inválido");
          }
        }
      }

      if (this.termoAdesao == false) {
        this.pix.errosPix.push("Aceite o termo de adesão");
      }

      if (!this.pix.nomePix) {
        this.pix.errosPix.push("Informe o nome");
      }

      return this.pix.errosPix.length <= 0;
    },
    obterBoleto() {
      if (this.validarBoleto()) {
        this.processando = true;
        this.exibirCartao = false;
        this.exibirPix = false;
        var usuarioLogado = UsuarioLogado.obterUsuarioLogado();

        let dadosBoleto = {
          nome: this.boleto.nomeBoleto,
          valor: this.valorPlanoSel,
          planoAssinaturaId: this.idPlanoSelecionado,
          clienteId: usuarioLogado.clienteId,
          telefone: this.boleto.telefoneBoleto,
          tipoDePessoa: this.boleto.boletoTipoPessoa,
        };

        if (this.boleto.boletoTipoPessoa === "fisica") {
          dadosBoleto.cpfCnpj = this.boleto.cpfBoleto;
        }
        if (this.boleto.boletoTipoPessoa === "juridica") {
          dadosBoleto.cpfCnpj = this.boleto.cnpjBoleto;
        }

        api.post("pagamentos/boleto/gerar-boleto", dadosBoleto).then((resp) => {
          this.resultadoBoleto = resp.data;
          Pagamentos.obterPagamentosPendentesDoUsuario();
          this.processando = false;
        });
      }
    },
    validarBoleto() {
      this.boleto.errosBoleto = [];

      if (this.boleto.boletoTipoPessoa === "fisica") {
        if (!this.boleto.cpfBoleto) {
          this.boleto.errosBoleto.push("Informe o CPF");
        } else {
          if (!this.validaCPF(this.boleto.cpfBoleto)) {
            this.boleto.errosBoleto.push("CPF inválido");
          }
        }
      }

      if (this.boleto.boletoTipoPessoa === "juridica") {
        if (!this.boleto.cnpjBoleto) {
          this.boleto.errosBoleto.push("Informe o CNPJ");
        } else {
          if (!Validacoes.validarCNPJ(this.boleto.cnpjBoleto)) {
            this.boleto.errosBoleto.push("CNPJ inválido");
          }
        }
      }

      if (this.termoAdesao == false) {
        this.boleto.errosBoleto.push("Aceite o termo de adesão");
      }

      if (!this.boleto.nomeBoleto) {
        this.boleto.errosBoleto.push("Informe o nome");
      }

      if (!this.boleto.telefoneBoleto) {
        this.boleto.errosBoleto.push("Informe o telefone");
      }

      return this.boleto.errosBoleto.length <= 0;
    },
  },

  computed: {
    valorPlanoSel() {
      return this.$store.state.checkout.valorPlanoSelecionado;
    },
    descricaoPlano() {
      return this.$store.state.checkout.descricaoPlano;
    },
    idPlanoSelecionado() {
      return this.$store.state.checkout.idPlanoSelecionado;
    },
    tipoPlano() {
      return this.$store.state.checkout.tipoPlano;
    },
    comprando() {
      return this.$store.state.checkout.comprando;
    },
    abrindoPublicacao() {
      return this.$store.state.logBusca.abrindoPublicacao;
    },
    tipoConsulta() {
      return this.$store.state.logBusca.tipoConsulta;
    },
    paginaAtual() {
      return this.$store.state.logBusca.paginaAtual;
    },
    pesquisa() {
      return this.$store.state.logBusca.pesquisa;
    },
    logBusca() {
      return this.$store.state.logBusca;
    },
    idUsuarioLogado() {
      return this.$store.state.dadosUsuario.idUsuario;
    },
  },
};
</script>
<style lang="scss">
.login {
  width: 550px;
}

.pedidos {
  background: #fffccb;
  box-shadow: 0px 10px 20px #bbb;
  &:after {
    background: linear-gradient(-45deg, transparent 12px, #fffccb 0),
      linear-gradient(45deg, transparent 12px, #fffccb 0);
    background-repeat: repeat-x;
    background-position: left bottom;
    background-size: 12px 24px;
    content: "";
    display: block;
    width: 100%;
    height: 12px;
    position: relative;
    top: 12px;
    left: 0px;
    margin-bottom: 24px;
  }
  .pedido {
    border-bottom: 1px dashed $cinza;
  }
}
</style>