<template>
  <div>
    <div
      class="bg-azul color-branco p-1 text-center"
      v-if="$route.name.toLowerCase() === 'home'"
    >
      <small>
        Legal Lake é mantida por
        <a
          href="http://www.sedep.com.br"
          target="_blank"
          class="color-azul-claro"
        >
          Sedep</a
        >. Há 40 anos fazendo tudo por sua causa.
      </small>
    </div>
    <div class="d-sm-block d-flex">
      <div class="header-padrao py-3 mb-3 mb-md-0 w-100" id="pesquisar">
        <div
          class="container d-flex flex-md-nowrap flex-wrap align-items-center"
        >
          <a href="/">
            <img
              v-if="
                ($route.name.toLowerCase() === 'home' ||
                  $route.name.toLowerCase() === 'planos') &&
                !isMobile
              "
              src="../assets/imgs/legallake.png"
            />
            <img v-else src="../assets/imgs/legallake-branco.png" />
          </a>
          <div
            class="w-100 col-md mt-md-0 mt-3 order-1 order-md-0 px-0 px-md-3"
            v-if="mostrarBuscaHome"
          >
            <Buscador classContainer="input-group-sm" />
          </div>

          <div class="user-area ml-auto pl-2 order-0 order-md-1 d-flex">
            <div v-if="!usuarioEstaLogado()" class="nao-logado">
              <a
                href="#/login"
                class="btn btn-primary btn-azul btn-sm d-inline-block"
              >
                <i class="fas fa-sign-in-alt"></i> Entrar
              </a>
              <a
                href="#/cadastro"
                class="btn btn-primary btn-sm ml-2 d-inline-block"
              >
                <i class="fas fa-shield-alt"></i> Cadastrar
              </a>
            </div>
            <div v-if="usuarioEstaLogado()" class="d-flex">
              <WidgetMinhaCarteira />
              <div
                class="credito d-flex"
                id="show-btn"
                style="display: none !important"
              >
                <div class="valor">${{ saldoDisponivel | currency2 }}</div>

                <a @click="verPlanos()">
                  <i class="fas fa-plus"></i>
                </a>
              </div>
              <b-dropdown
                size="sm"
                variant="link"
                toggle-class="text-decoration-none"
                class="ml-2 user"
                no-caret
                right
              >
                <template #button-content>
                  <div class="dropmenu">{{ obterLetraUsuario() }}</div>
                </template>
                <b-dropdown-item href="#/usuario"
                  >Dados da conta</b-dropdown-item
                >
                <b-dropdown-item href="#/usuario/minha-carteira"
                  >Minha Carteira</b-dropdown-item
                >
                <b-dropdown-item href="#/usuario/buscas-salvas"
                  >Pesquisas Salvas</b-dropdown-item
                >
                <b-dropdown-item href="#/usuario/resultados-abertos"
                  >Publicações Abertas</b-dropdown-item
                >
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item href="#" class="sair" @click="sair()"
                  >Sair</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="exibirAvisoVerificacoes()"
      class="bg-vermelho text-white mt-3 mt-sm-0"
    >
      <div class="container py-1">
        Para sua segurança, é necessário verificar seu celular e e-mail.<a
          class="d-inline-block pl-1 text-white"
          href="javascript:void(0);"
          @click="enviarParaUsuario()"
          ><u>Clique aqui</u></a
        >
      </div>
    </div>
  </div>
</template>
<script>
import Buscador from "@/components/Buscador";
import UsuarioLogado from "../config/usuario-logado";
import Token from "../config/token";
import api from "../config/api";
import Dispositivo from "../config/dispositivo";
import WidgetMinhaCarteira from "@/components/WidgetMinhaCarteira";
import Pagamento from "../config/pagamentos";

export default {
  components: {
    Buscador,
    WidgetMinhaCarteira,
  },
  data() {
    return {
      mostrarBuscadorHeader: false,
      saldoCliente: null,
      cliente: null,
      isMobile: Dispositivo.mobile(),
    };
  },
  methods: {
    usuarioEstaLogado() {
      var usuario = UsuarioLogado.obterUsuarioLogado();
      return usuario && usuario.token;
    },
    obterLetraUsuario() {
      if (this.usuarioEstaLogado()) {
        var usuario = UsuarioLogado.obterUsuarioLogado();
        return usuario.nome[0];
      } else {
        return "";
      }
    },
    obterSaldoCliente() {
      var cliente = UsuarioLogado.obterUsuarioLogado();
      api.get(`clientes/saldo?clienteId=${cliente.clienteId}`).then((saldo) => {
        this.saldoCliente = saldo.data;
      });
    },
    verPlanos() {
      if (this.$route.path === "/" || this.$route.path === "/planos") {
        document
          .getElementById("planos")
          .scrollIntoView({ behavior: "smooth" });
      } else this.$router.push({ path: "planos" });
    },
    sair() {
      UsuarioLogado.removerUsuarioLogado();
      this.$bvToast.toast("Você desconectou com segurança", {
        title: "Logoff",
        solid: true,
      });
      if (this.$router.currentRoute === "/") {
        this.$forceUpdate();
      } else {
        this.$router.push({ path: "/" });
        this.$forceUpdate();
      }
    },
    obterCliente() {
      var cliente = UsuarioLogado.obterUsuarioLogado();
      api.get(`clientes?clienteId=${cliente.clienteId}`).then((resp) => {
        this.cliente = resp.data;
      });
    },
    exibirAvisoVerificacoes() {
      return (
        this.usuarioEstaLogado() &&
        this.cliente &&
        (!this.cliente.emailConfirmado || !this.cliente.celularConfirmado)
      );
    },
    enviarParaUsuario() {
      this.$router.push({ path: "usuario" });
    },
  },
  mounted() {
    if (Token.expirado()) {
      UsuarioLogado.removerUsuarioLogado();
    }
    if (this.usuarioEstaLogado()) {
      this.$store.commit("atualizaSaldoDisponivel");
      Pagamento.obterPagamentosPendentesDoUsuario();
    }
    if (this.usuarioEstaLogado()) {
      if (
        this.$route.name.toLowerCase() === "busca" ||
        this.$route.name.toLowerCase() === "resultado"
      ) {
        this.obterCliente();
      }
    }
  },
  computed: {
    mostrarBuscaHome() {
      return (
        this.$route.name.toLowerCase() !== "home" &&
        this.$route.name.toLowerCase() !== "planos"
      );
    },
    saldoDisponivel() {
      return this.$store.state.dadosUsuario.saldoDisponivel;
    },
  },
};
</script>
