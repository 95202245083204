import Vue from 'vue'
import Vuex from 'vuex'
import api from "../config/api";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    checkout: {
      valorPlanoSelecionado: 0,
      idPlanoSelecionado: 0,
      descricaoPlano: '',
      tipoPlano: ''
    },
    dadosUsuario: {
      idUsuario: 0,
      nomeUsuario: '',
      emailUsuario: '',
      saldoDisponivel: 0
    },
    logBusca: {
      tipoConsulta: 0,
      paginaAtual: 1,
      pesquisa: '',
      idPublicacao: 0,
      historico: '',
      abrindoPublicacao: false
    }

  },
  mutations: {
    atualizaDadosUsuario: function (state, { idUsuario, nomeUsuario, emailUsuario, saldoDisponivel }) {
      state.dadosUsuario.idUsuario = idUsuario;
      state.dadosUsuario.nomeUsuario = nomeUsuario;
      state.dadosUsuario.emailUsuario = emailUsuario;
      state.dadosUsuario.saldoDisponivel = saldoDisponivel;
    },
    selecionaPlano: function (state, { idPlano, valor, descricao, tipo, comprando }) {
      state.checkout.valorPlanoSelecionado = valor;
      state.checkout.idPlanoSelecionado = idPlano;
      state.checkout.descricaoPlano = descricao;
      state.checkout.tipoPlano = tipo;
      state.checkout.comprando = comprando;
    },
    comprando: function (state, { comprando }) {
      state.checkout.comprando = comprando;
    },
    logBusca: function (state, { tipoConsulta, paginaAtual, pesquisa, idPublicacao, historico }) {
      state.logBusca.tipoConsulta = tipoConsulta;
      state.logBusca.paginaAtual = paginaAtual;
      state.logBusca.pesquisa = pesquisa;
      state.logBusca.idPublicacao = idPublicacao;
      state.logBusca.historico = historico;
    },
    abrindoPublicacao: function (state, { abrindoPublicacao }) {
      state.logBusca.abrindoPublicacao = abrindoPublicacao;
    },
    atualizaSaldoDisponivel: function (state) {

      let idUsuario = JSON.parse(localStorage.getItem("usuario")).clienteId;

      if (idUsuario > 0) {
        api.get(`clientes/saldo?clienteId=${idUsuario}`).then((saldo) => {
          state.dadosUsuario.saldoDisponivel = saldo.data.saldo;
        });
      } else {
        state.dadosUsuario.saldoDisponivel = 0;
      }
    }
  },
  actions: {

  },
  getters: {

  },
  plugins: [createPersistedState()]
})
