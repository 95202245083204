<template>
  <div class="fluid bg-cinza-claro resultado-busca">
    <vue-topprogress ref="topProgress"></vue-topprogress>
    <div class="container" v-if="itensCarregados">
      <div class="info-busca my-3 row px-3">
        <div class="qtd-busca px-0 px-md-3 col-md-9 btn-sm">

          <div class="flex-md-row d-flex flex-column-reverse flex-column align-items-start">
            <div>
              <strong class="pr-1">{{ totalRegistros == 10000 ? 'Mais de 10.000' : totalRegistros }}</strong>resultados
              para
              <b><i>"{{ pesquisaExibicao }}"</i></b>
              <a v-if="usuarioLogado" href="#" v-b-modal.salvar-consulta class="btn btn-link p-0 ml-2 btn-sm">Salvar
                Pesquisa</a>
            </div>

            <div class="flex-md-row d-flex flex-column-reverse flex-column align-items-start ml-auto">
              <div class=" my-2 mr-2">Ordenar por: </div>

              <div class="small">
                <b-form-select v-model="maisRecentes" @change="AlteraRelevancia"
                  :options="[{ value: false, text: 'Relevância' }, { value: true, text: 'Data' }]"></b-form-select>


                <!-- <select id="inputState" class="form-control form-control-sm small" v-model="AlteraRelevancia">
                <option value=false class="small">Relevância</option>
                <option value=true class="small">Data</option>
              </select> -->
              </div>

              <!-- <input type="checkbox" class="custom-control-input" @change="AlteraRelevancia" v-model="maisRecentes" id="maisrecentes">
              <label class="custom-control-label" for="maisrecentes">Mais Recentes</label> -->
            </div>
          </div>




          <div>

            <b-modal id="salvar-consulta" header-class="flex-column">
              <template #modal-header>
                <h5 class="d-block">Salvar Resultado</h5>
                <small class="d-block">Salve as pesquisas com os filtros para acesso rápido</small>
              </template>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="email">Nome para a pesquisa</label>
                    <input type="text" class="form-control" id="nomeDaConsulta" v-model="nomeSalvarConsulta" :class="{
                      'is-invalid': $v.nomeSalvarConsulta.$error,
                    }" />
                    <b-form-invalid-feedback v-if="
                      !$v.nomeSalvarConsulta.required && formularioPostado
                    ">Campo obrigatório<br /></b-form-invalid-feedback>
                  </div>
                </div>
              </div>
              <template #modal-footer>
                <b-button variant="link" size="sm" class="float-right" @click="fecharModalSalvarConsulta()">
                  Cancelar
                </b-button>
                <!-- confirmação com redirect para lista de itens Salvos; Retorno da mensagem por Toaster;  -->
                <b-button @click="salvarConsulta()" variant="primary" size="sm" class="float-right">
                  Salvar
                </b-button>
              </template>
            </b-modal>
          </div>
        </div>

        <div class="col-md-3 p-0 align-items-center d-none d-sm-flex">
          <button v-if="usuarioLogado" type="button" class="btn btn-primary btn-azul btn-sm text-left btn-block"
            @click="enviarParaResultadosAbertos()">
            PUBLICAÇÕES ABERTAS<i class="fas fa-chevron-right float-right mt-1"></i>
          </button>
        </div>
      </div>
      <div class="
          flex-md-row
          d-flex
          flex-column-reverse flex-column
          align-items-start
        ">
        <div class="col-md-9 px-0 px-md-3" v-if="itensCarregados && totalRegistros > 0">
          <div class="items mb-5">
            <div class="item p-3 mb-2 d-md-flex align-items-center" v-for="(item, index) in publicacoes"
              :key="item.guid" :id="item.guid">
              <div>
                <div class="ttl">Diário: {{ item.diario }}</div>
                <div class="ttl-alt">
                  Publicado em
                  <strong>
                    {{ item.dataPublicacao }}
                  </strong>
                </div>
                <div class="ttl-alt">
                  Disponibilizado em
                  <strong>
                    {{ item.dataDisponibilizacao }}
                  </strong>
                </div>
                <div class="ttl-alt" v-html="item.cabecalhoSecundario"></div>
                <div class="ttl-alt" v-html="item.cabecalhoPrincipal"></div>
                <div class="precia-ctn" v-html="item.resumo"></div>
              </div>
              <div class="acao ml-md-3 mt-3">
                <button type="button" :class="item.aberto ? 'btn-outline-azul' : ''"
                  class="btn btn-sm btn-outline-primary btn-block"
                  @click="abrirDetalhes(item.id, index, item.publicacaoFAZId)">
                  ABRIR
                </button>
              </div>
            </div>
          </div>
          <div v-if="itensCarregados">
            <div class="overflow-auto">
              <div>

                <div style="width: 114px; display: inline;">Página: <input class="form-control" v-model="paginaSelecionada"
                    style="width: 51px;padding: 0px !important; text-align: center; display: inline !important;"
                    @change="selecionarPagina()" />
                </div>
                <div style="float: right;">
                  <b-pagination-nav v-model="paginaAtual" :number-of-pages="totalPaginas"
                    :link-gen="geradorLinksPaginacao" align="center" use-router @change="atualizarPaginacao">
                  </b-pagination-nav>
                </div>

              </div>
            </div>
          </div>
          <div>
            <AvisoDadosPublicos />
          </div>
        </div>
        <div class="col-md-9 px-0 px-md-3" v-if="itensCarregados && totalRegistros == 0">
          <div class="items mb-5">
            <ResultadoVazio titulo="Resultado Vazio" descricao="Nenhum resultado encontrado" icon="fa-exclamation" />
          </div>
        </div>
        <div class="col-md-3 px-0 mb-3 opcoes-busca">
          <h6 class="d-sm-block d-none">Filtrar resultados</h6>

          <div class="abrir-filtro d-block d-sm-none" @click="filtroBloqueado = !filtroBloqueado">
            <hr class="w-100" />
            <span> Filtrar resultados</span>
          </div>
          <div v-bind:class="{ filtros: filtroBloqueado }">
            <div class="filtro mb-4 filtro-data px-md-3">
              <div class="d-flex">
                <div class="ttl">Data da Publicação</div>
                <a href="javascript:void(0)" @click="limparDatas()" class="ml-auto">
                  <i class="fas fa-times-circle small"></i> Limpar</a>
              </div>
              <div class="d-flex my-1 mb-3">
                <span class="line-1"> </span>
                <span class="line-2"> </span>
              </div>

              <div class="ctn d-flex flex-column">
                <input class="form-control form-control-md" type="tel" placeholder="Data início" v-mask="'##/##/####'"
                  v-model="textDataInicial" />
                <input class="form-control form-control-md mt-3" type="tel" placeholder="Data fim" v-mask="'##/##/####'"
                  v-model="textDataFinal" />
              </div>
              <button type="button" @click="aplicarFiltros" class="btn btn-primary btn-sm mt-4 btn-block">
                APLICAR FILTROS
              </button>
            </div>
            <hr />
            <div class="filtro mb-4 filtro-regiao px-md-3">
              <div class="d-flex">
                <div class="ttl">Por Regiões</div>
                <a href="javascript:void(0)" @click="limparEstados()" class="ml-auto">
                  <i class="fas fa-times-circle small"></i> Limpar
                </a>
              </div>
              <div class="d-flex my-1 mb-3">
                <span class="line-1"> </span>
                <span class="line-2"> </span>
              </div>
              <div class="ctn d-flex flex-column">
                <!-- <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value="MT"
                    id="EstadoMT"
                    v-model="checkboxEstados"
                    @change="zeraIdPublicacao($event)"
                  />
                  <label class="form-check-label" for="EstadoMT">
                    Mato Grosso
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value="MS"
                    id="EstadoMS"
                    v-model="checkboxEstados"
                    @change="zeraIdPublicacao($event)"
                  />
                  <label class="form-check-label" for="EstadoMS>
                    Mato Grosso do Sul
                  </label>
                </div> -->

                <div class="form-check" v-for="regiao in regioes" :key="regiao.sigla">
                  <input class="form-check-input" type="checkbox" :value="regiao.sigla" :id="'Estado' + regiao.sigla"
                    v-model="checkboxEstados" @change="zeraIdPublicacao($event)" />
                  <label class="form-check-label" :for="'Estado' + regiao.sigla">
                    {{ regiao.nome }}
                  </label>
                </div>
              </div>
              <button type="button" @click="aplicarFiltros" class="btn btn-primary btn-sm mt-4 btn-block">
                APLICAR FILTROS
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalAbrirVisualizacao :idPublicacao="idPublicacao" :tipoConsulta="tipoConsulta" :paginaAtual="paginaAtual"
      :pesquisa="pesquisa" :idModal="this.idModalCompra" />

    <ModalDescontarCredito :valorConsulta="valorConsulta" :idPublicacao="idPublicacao" :tipoConsulta="tipoConsulta"
      :paginaAtual="paginaAtual" :pesquisa="pesquisa" :historico="historico" :publicacaoFAZId="publicacaoFAZId" />
  </div>
</template>

<script>
import ResultadoVazio from "@/components/ResultadoVazio";
import { mask } from "vue-the-mask"; // https://vuejs-tips.github.io/vue-the-mask/
import UsuarioLogado from "../config/usuario-logado";
import api from "../config/api";
import { required } from "vuelidate/lib/validators";
import ModalAbrirVisualizacao from "@/components/ModalAbrirVisualizacao";
import ModalDescontarCredito from "@/components/ModalDescontarCredito";
import _toString from "lodash/toString";
import AvisoDadosPublicos from "@/components/AvisoDadosPublicos";
import _omit from "lodash/omit";
import ExpressaoBusca from "../models/expressao-busca";

export default {
  components: {
    ResultadoVazio,
    ModalDescontarCredito,
    ModalAbrirVisualizacao,
    AvisoDadosPublicos,
  },
  directives: {
    mask,
  },
  mounted() {
    this.$refs.topProgress.start();
    this.init();
  },
  data() {
    return {
      /* tipoConsulta
       * 0 = Busca por frase exata (PADRÃO)
       * 1 = Busca semelhança nos termos
       * 2 = Busca por expressões
       **/
      tipoConsulta: 0, // Tipo de consulta
      paginaAtual: 1, // Página atual
      paginaSelecionada: 1, // Página atual
      pesquisa: "", // Termo pesquisado pelo usuário
      maisRecentes: false,
      pesquisaExibicao: null,
      itensCarregados: false,
      textDataInicial: "",
      textDataFinal: "",
      checkboxEstados: [],
      usuarioLogado: null,
      nomeSalvarConsulta: "",
      filtroBloqueado: true,
      formularioPostado: false,
      valorConsulta: 0,
      idPublicacao: "",
      historico: "",
      totalRegistros: 0,
      totalPaginas: 0,
      publicacoes: null,
      filtros: null,
      idModalCompra: "modalabrirvisualizacao2",
      publicacaoFAZId: null,
      regioes: [
        { nome: "Distrito Federal", sigla: "DF" },
        { nome: "Acre", sigla: "AC" },
        { nome: "Alagoas", sigla: "AL" },
        { nome: "Amapá", sigla: "AP" },
        { nome: "Amazonas", sigla: "AM" },
        { nome: "Bahia", sigla: "BA" },
        { nome: "Ceará", sigla: "CE" },
        { nome: "Espírito Santo", sigla: "ES" },
        { nome: "Goiás", sigla: "GO" },
        { nome: "Maranhão", sigla: "MA" },
        { nome: "Mato Grosso", sigla: "MT" },
        { nome: "Mato Grosso do Sul", sigla: "MS" },
        { nome: "Minas Gerais", sigla: "MG" },
        { nome: "Pará", sigla: "PA" },
        { nome: "Paraíba", sigla: "PB" },
        { nome: "Paraná", sigla: "PR" },
        { nome: "Pernambuco", sigla: "PE" },
        { nome: "Piauí", sigla: "PI" },
        { nome: "Rio de Janeiro", sigla: "RJ" },
        { nome: "Rio Grande do Norte", sigla: "RN" },
        { nome: "Rio Grande do Sul", sigla: "RS" },
        { nome: "Rondônia", sigla: "RO" },
        { nome: "Roraima", sigla: "RR" },
        { nome: "Santa Catarina", sigla: "SC" },
        { nome: "São Paulo", sigla: "SP" },
        { nome: "Sergipe", sigla: "SE" },
        { nome: "Tocantins", sigla: "TO" },
      ],
    };
  },
  validations: {
    nomeSalvarConsulta: { required },
  },
  methods: {
    async init() {
      this.usuarioLogado = UsuarioLogado.obterUsuarioLogado();
      this.tipoConsulta = parseInt(this.$route.query.q); // q = Tipo de consulta
      this.paginaAtual = parseInt(this.$route.query.w); // w = Página atual
      this.pesquisa = this.$route.query.pesquisa; // pesquisa = Termo pesquisado pelo usuário
      this.pesquisaExibicao = ExpressaoBusca.converterParaExibicao(
        this.pesquisa
      );
      this.checkboxEstados =
        this.$route.query.estados === undefined
          ? []
          : this.$route.query.estados.replace(/ /g, "").split(",");

      this.textDataInicial = this.$route.query.inicio ?? "";
      this.textDataFinal = this.$route.query.fim ?? "";
      this.maisRecentes = this.$route.query.maisrecentes === undefined ? false : this.$route.query.maisrecentes;

      await this.buscarPublicacoes();
    },
    AlteraRelevancia() {
      this.buscarPublicacoes()
    },
    limparDatas() {
      this.textDataInicial = "";
      this.textDataFinal = "";
      var novaRota = _omit(this.$route.query, ["inicio", "fim"]);
      this.$router.replace({ query: novaRota });
    },
    limparEstados() {
      this.checkboxEstados = [];
      var novaRota = _omit(this.$route.query, ["estados"]);
      this.$router.replace({ query: novaRota });
    },
    fecharModalSalvarConsulta() {
      this.formularioPostado = false;
      this.nomeSalvarConsulta = "";
      this.$bvModal.hide("salvar-consulta");
      this.$v.$reset();
    },
    enviarParaResultadosAbertos() {
      this.$router.replace("/usuario/resultados-abertos");
    },
    salvarConsulta() {
      this.$v.nomeSalvarConsulta.$touch();
      this.formularioPostado = true;
      if (!this.$v.nomeSalvarConsulta.$error) {
        let loader = this.$loading.show();
        var cliente = UsuarioLogado.obterUsuarioLogado();
        api
          .post(`consultasalva`, {
            titulo: this.nomeSalvarConsulta,
            clienteId: cliente.clienteId,
            tipoConsulta: this.tipoConsulta,
            termoPesquisa: this.pesquisa,
            dataPublicacaoInicial: this.textDataInicial,
            dataPublicacaoFinal: this.textDataFinal,
            estados: this.checkboxEstados.join(", "),
          })
          .then(() => {
            this.formularioPostado = false;
            this.nomeSalvarConsulta = "";
            this.$v.$reset();
            this.$bvToast.toast("Busca salva com sucesso", {
              title: "Sucesso",
              variant: "success",
              solid: true,
            });
            this.$bvModal.hide("salvar-consulta");
          })
          .finally(() => {
            loader.hide();
          });
      }
    },
    aplicarFiltros() {
      let listaErros = this.validarFormulario();
      if (listaErros.length > 0) {
        this.$bvToast.toast(listaErros[0], {
          title: "Atenção",
          variant: "danger",
          solid: true,
        });
        return;
      }

      let path = {
        path: "busca",
        query: {
          estados: _toString(this.checkboxEstados), // Filtro por estados
          fim: this.textDataFinal, // Filtro por data final
          inicio: this.textDataInicial, // Filtro por data inicial
          pesquisa: this.pesquisa, // pesquisa = Termo pesquisado pelo usuário,
          q: this.tipoConsulta.toString(), // q = Tipo de consulta
          w: 1, // w = Voltar a página 1
        },
      };

      // Para evitar consultas redundantes e desperdício de recursos computacionais
      if (
        this.$route.query.estados !== path.query.estados ||
        this.$route.query.inicio !== path.query.inicio ||
        this.$route.query.fim !== path.query.fim
      ) {
        this.paginaAtual = 1;
        this.$router.push(path);
        this.buscarPublicacoes();
      }
    },
    buscarPublicacoes() {
      this.buscaInteligente();

      //CASO SEJA A ULTIMA BUSCA DO USUÁRIO DO DIA ANTERIOR, POSICIONAMOS NA MESMA PÁGINA QUE ELE PAROU
      var dataAtual = new Date();
      var hoje = new Date(dataAtual.getFullYear() + '-' + (dataAtual.getMonth() + 1) + '-' + dataAtual.getDate());
      var dataUltimaBusca = new Date(localStorage.getItem("ultimaBuscaData"))
      if (dataUltimaBusca < hoje) {
        if (this.pesquisa == localStorage.getItem("ultimaBusca")) {
          this.paginaAtual = localStorage.getItem("ultimaBuscaPagina")
          this.$root.$bvToast.toast(
            "Notamos que você parou sua pesquisa na página " + this.paginaAtual,
            {
              title: "Busca Legallake",
              variant: "info",
              solid: true,
              autoHideDelay: 6000,
            }
          );
        }
      }


      let consulta =
        "Consulta/consultarPublicacoes" +
        `?tipoConsulta=${this.tipoConsulta}` +
        `&numeroPagina=${this.paginaAtual}` +
        `&termoPesquisa=${encodeURIComponent(this.pesquisa)}` +
        `&filtroEstados=${this.checkboxEstados}` +
        `&filtroDataInicial=${this.textDataInicial}` +
        `&filtroDataFinal=${this.textDataFinal}` +
        `&OrdenaPorData=${this.maisRecentes}`;

      var usuario = UsuarioLogado.obterUsuarioLogado();

      if (usuario) {
        consulta += `&clienteId=${usuario.clienteId}`;
      }

      localStorage.setItem("ultimaBuscaData", hoje);
      localStorage.setItem("ultimaBusca", this.pesquisa);
      localStorage.setItem("ultimaBuscaPagina", this.paginaAtual);

      let loader = this.$loading.show();
      api
        .get(consulta)
        .then((resposta) => {
          this.itensCarregados = true;
          this.totalPaginas = resposta.data.totalPaginas;
          this.totalRegistros = resposta.data.totalRegistros;
          this.publicacoes = resposta.data.publicacoes;
          this.$store.commit("logBusca", {
            tipoConsulta: this.tipoConsulta,
            paginaAtual: this.paginaAtual,
            pesquisa: this.pesquisa,
            idPublicacao: 0,
          });
        })
        .catch((erro) => {
          console.error(erro);
          this.itensCarregados = false;
        })
        .finally(() => {
          loader.hide();
          setTimeout(() => {
            this.$refs.topProgress.done();
          }, 150);
        });
    },
    geradorLinksPaginacao(pagina) {
      return {
        path: "busca",
        query: {
          q: this.tipoConsulta, // Tipo de consulta
          w: pagina, // Página atual
          pesquisa: this.pesquisa, // Termo pesquisado pelo usuário
          estados: _toString(this.$route.query.estados), // Filtro por estados
          fim: this.$route.query.fim, // Filtro por data final
          inicio: this.$route.query.inicio, // Filtro por data inicial
        },
      };
    },
    selecionarPagina() {
      window.scrollTo(0, 0);
      this.atualizarPaginacao(parseInt(this.paginaSelecionada));
    },  
    atualizarPaginacao(pagina) {
      this.$store.commit("logBusca", {
        tipoConsulta: this.tipoConsulta,
        paginaAtual: pagina,
        pesquisa: this.pesquisa,
        idPublicacao: 0,
      });

      this.paginaSelecionada = pagina;
      this.paginaAtual = pagina;
      this.buscarPublicacoes();
    },
    validarData(dataPublicacaoInicial, dataPublicacaoFinal) {
      if (dataPublicacaoInicial === "" && dataPublicacaoFinal === "") {
        return "";
      }

      if (dataPublicacaoInicial === "" || dataPublicacaoFinal === "") {
        return "A data início e a data fim devem ser preenchidas para filtragem";
      }

      let dataInicial = dataPublicacaoInicial.split("/"),
        dataFinal = dataPublicacaoFinal.split("/"),
        inicio = Date.parse(
          `${dataInicial[2]}-${dataInicial[1]}-${dataInicial[0]}`
        ),
        fim = Date.parse(`${dataFinal[2]}-${dataFinal[1]}-${dataFinal[0]}`);

      if (isNaN(inicio)) {
        return "A data início está em um formato inválido";
      }

      if (isNaN(fim)) {
        return "A data fim está em um formato inválido";
      }

      if (inicio > fim) {
        return "A data início não pode ser maior que a data fim";
      }

      return "";
    },
    validarFormulario() {
      let erros = [];
      let validacaoFiltragemDataPublicacao = this.validarData(
        this.textDataInicial,
        this.textDataFinal
      );

      if (validacaoFiltragemDataPublicacao !== "") {
        erros.push(validacaoFiltragemDataPublicacao);
      }

      return erros;
    },
    abrirDetalhes(id, index, publicacaoFAZId) {
      this.idPublicacao = id;
      this.publicacaoFAZId = publicacaoFAZId;

      let vrHistorico = this.publicacoes[index].resumo
        .replace(/<\/mark>/gi, "")
        .replace("... ", "")
        .replace(/<mark>/g)
        .replace("  ", " ")
        .split(" ")
        .slice(0, 20)
        .join(" ");

      this.historico = vrHistorico;

      this.$store.commit("logBusca", {
        tipoConsulta: this.tipoConsulta,
        paginaAtual: this.paginaAtual,
        pesquisa: this.pesquisa,
        idPublicacao: this.idPublicacao,
        historico: vrHistorico,
      });

      if (localStorage.getItem("access_token") !== null) {
        const usuarioLogado = JSON.parse(localStorage.getItem("usuario"));

        //verifica se ja pagou e está válida a publicação
        api
          .get(
            `resultados/VerificaValidade?ClienteId=${usuarioLogado.clienteId}&PublicacaoId=${this.idPublicacao}`
          )
          .then((resp) => {
            resp.data.valida;

            if (resp.data.valida == true) {
              this.$router.push({
                path: "resultado",
                query: {
                  q: this.tipoConsulta, // q = Tipo de consulta
                  w: this.paginaAtual, // w = Página atual
                  pesquisa: this.pesquisa, // pesquisa = Termo pesquisado pelo usuário
                  r: this.idPublicacao, // r = _id para apresentar detalhes do item selecionado
                },
              });
            }
          })
          .catch(() => { });

        api
          .get("clientes/saldo?clienteId=" + usuarioLogado.clienteId)
          .then((resp) => {
            this.valorConsulta = resp.data.valorConsulta;
            this.$store.commit("atualizaSaldoDisponivel");

            if (resp.data.saldo > 0) {
              this.$bvModal.show("modalDescontarCredito");
            } else {
              this.$root.$bvModal.show(this.idModalCompra);
            }
          })
          .catch(() => { });
      } else {
        this.$root.$bvModal.show(this.idModalCompra);
      }
    },
    async posicionaPublicacao() {
      if (
        !!this.publicacoes &&
        this.idPublicacaoSelecionada !== null &&
        this.idPublicacaoSelecionada > 0
      ) {
        let top = await document.getElementById(this.idPublicacaoSelecionada)
          .offsetTop;
        await window.scrollTo(0, top);
        //await this.zeraIdPublicacao();
      }
    },
    zeraIdPublicacao: function () {
      this.$store.commit("logBusca", {
        tipoConsulta: this.tipoConsulta,
        paginaAtual: this.paginaAtual,
        pesquisa: this.pesquisa,
        idPublicacao: 0,
      });
    },
    buscaInteligente() {
      /* tipoConsulta
       * 0 = Busca por frase exata (PADRÃO)
       * 1 = Busca semelhança nos termos (DESABILITADO)
       * 2 = Busca por expressões
       **/
      var re = /("| AND| OR| NOT| and| or| not)/g;
      var testeRe = re.test(this.pesquisa);

      if (this.tipoConsulta === 2 && !testeRe) {
        this.tipoConsulta = 0;
        this.$router.push({
          path: "busca",
          query: {
            q: this.tipoConsulta, // q = Tipo de consulta
            w: this.paginaAtual, // w = Página atual
            pesquisa: this.pesquisa, // pesquisa = Termo pesquisado pelo usuário
          },
        });
      }
    },
  },
  computed: {
    idPublicacaoSelecionada() {
      return this.$store.state.logBusca.idPublicacao;
    },
    logBusca() {
      return this.$store.state.logBusca;
    },
  },
  async updated() {
    await this.posicionaPublicacao();
  },
};
</script>
<style lang="scss">
.resultado-busca {
  .tipo-pesquisa {
    font-size: 9px;
    background: #b6bccc;
    color: #3b455d;
    border-radius: 4px;
    text-transform: uppercase;
  }

  .info-busca {
    font-family: $font-alt;
    font-size: 1rem;
  }

  .item {
    background: $branco;
    border-radius: $border-radius;
    border: 1px solid $cinza-medio;
    box-shadow: 1px 1px 10px $cinza-medio;

    &:hover {
      box-shadow: 1px 1px 18px $cinza-medio;
    }

    .ttl {
      font-family: $font-alt;
      font-weight: 700;
      font-size: 1rem;
      margin-bottom: 3px;
    }

    .ttl-alt {
      font-size: 0.8rem;
      color: $cinza-medio2;
      font-family: $font-alt;
    }

    span.marcacao {
      border-radius: 5px;
      padding-left: 3px;
      background: #fcde66;
      font-weight: 700;
    }
  }

  .opcoes-busca {
    background: $branco;
    border-radius: $border-radius;
    border: 1px solid $cinza-medio;
    box-shadow: 1px 1px 10px $cinza-medio;

    h6 {
      display: block;
      padding: 8px 0;
      background: linear-gradient(#8f97ab, #777f94);
      text-transform: uppercase;
      text-align: center;
      font-weight: 400;
      font-size: 0.83rem !important;
      margin-bottom: 20px;
      border-bottom: 1px solid $cinza-medio;
      color: #fff;
      border-radius: 3px 3px 0 0;
    }

    .ttl {
      font-family: $font-alt;
      font-size: 1rem;
      font-weight: 700;
    }

    .line-1 {
      width: 80px;
      height: 4px;
      border-radius: $border-radius;
      background: $azul;
      display: inline-block;
      margin-right: 10px;
    }

    .line-2 {
      width: 30px;
      height: 4px;
      border-radius: $border-radius;
      background: $azul;
      display: inline-block;
    }
  }
}

.mobile {
  .abrir-filtro {
    color: $vermelho;
    text-align: center;
    margin-bottom: 20px;

    span {
      background: $cinza-claro;
      padding: 0 20px;
      position: relative;
    }

    hr {
      border-top: 1px solid $vermelho;
      margin: 0;
      padding: 0;
      float: left;
      position: relative;
      top: 12px;
    }
  }

  .opcoes-busca {
    background: none;
    border-radius: none;
    border: none;
    box-shadow: none;
  }
}
</style>
