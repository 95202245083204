<template>
  <div class="pre-pago plano d-flex flex-column justify-content-center">
    <div class="ttl">Pré-pago</div>
    <div class="ctn">
      <div class="dest p-4">
        <div class="descricao text-center py-2">
          Ideal para quem precisa de pesquisas pontuais.
        </div>
        <div
          v-if="PlanosPre.length > 0"
          class="regras text-center small text-uppercase mt-3"
        >
          No plano Pré-pago os créditos tem validade de
          {{ PlanosPre[idPlano].validadeDias }} dias
        </div>
      </div>
      <div class="valor d-flex align-items-end px-3 py-2 text-uppercase">
        Custo por visualização:
        <span class="valor-desconto" v-if="false"><s>R$ 2,90</s></span>
        <div
          v-if="PlanosPre.length > 0"
          class="ml-auto d-flex align-items-center"
        >
          <div class="valor-final">
            {{ PlanosPre[idPlano].valorConsulta | currency }}
          </div>
          <div class="d-inline-block desconto small ml-2" v-if="false">
            {{
              Math.floor((PlanosPre[idPlano].valorConsulta / 2.9 - 1) * 100)
            }}%
          </div>
        </div>
      </div>
      <div class="selecao p-3 my-4">
        <div class="range">
          <b-form-input
            id="range-pre-pago"
            v-model="idPlano"
            type="range"
            min="0"
            :max="PlanosPre.length - 1"
          ></b-form-input>
          <div class="mt-2"></div>
        </div>
      </div>
      <div class="valores d-flex mt-2 px-3">
        <div>
          <div v-if="PlanosPre.length > 0" class="qtd-v">
            {{
              Math.floor(
                PlanosPre[idPlano].valorRecargaMensal /
                  PlanosPre[idPlano].valorConsulta
              )
            }}
          </div>
          <div class="small text-uppercase">Visualizações</div>
        </div>
        <div v-if="PlanosPre.length > 0" class="total mb-3 ml-auto">
          <div class="d-inline-block desconto small text-center w-100">
            TOTAL
            <span v-if="false"
              >{{
                Math.floor((PlanosPre[idPlano].valorConsulta / 2.9 - 1) * 100)
              }}%</span
            >
          </div>
          <div class="valor-total text-right">
            {{ PlanosPre[idPlano].valorRecargaMensal | currency }}
          </div>
        </div>
      </div>
      <a
        v-if="podeAdquirir"
        @click="checkout"
        class="btn btn-primary d-block m-3"
        >ADQUIRIR</a
      >
    </div>
  </div>
</template>
<script>
import api from "../../config/api";
import UsuarioLogado from "../../config/usuario-logado";

export default {
  props: {
    abrindoPublicacao: Boolean,
  },
  data: function () {
    return {
      idPlano: "0",
      PlanosPre: [],
      podeAdquirir: false,
    };
  },
  methods: {
    CarregaPlanos() {
      api
        .get("PlanosAssinatura?tipo=pre")
        .then((resp) => {
          this.PlanosPre = resp.data;
          this.SelecionaPlano();
        })
        .catch(() => {});
    },
    SelecionaPlano() {
      this.$store.commit("selecionaPlano", {
        idPlano: this.PlanosPre[this.idPlano].planoAssinaturaId,
        valor: this.PlanosPre[this.idPlano].valorRecargaMensal,
        descricao: this.PlanosPre[this.idPlano].descricao,
        tipo: "PRÉ-PAGO",
      });
    },
    verificarPlano() {
      var cliente = UsuarioLogado.obterUsuarioLogado();
      if (cliente) {
        api
          .get(`clientes/saldo?clienteId=${cliente.clienteId}`)
          .then((saldo) => {
            this.podeAdquirir = saldo.data.tipoPlano !== "Assinatura";
          });
      } else {
        this.podeAdquirir = true;
      }
    },
    checkout() {
      this.SelecionaPlano();

      this.$store.commit("comprando", { comprando: true });
      this.$store.commit("abrindoPublicacao", {
        abrindoPublicacao: this.abrindoPublicacao,
      });

      if (localStorage.getItem("access_token") !== null) {
        this.$router.push({ path: "checkout" });
      } else {
        this.$router.push({ path: "cadastro" });
      }
    },
  },
  mounted() {
    this.CarregaPlanos();
    this.verificarPlano();
  },
  watch: {
    idPlano: function () {
      this.SelecionaPlano();
    },
  },
};
</script>