<template>
  <div class="d-sm-flex align-items-md-center container-onda">
    <vue-topprogress ref="topProgress"></vue-topprogress>
    <div class="login m-auto">
      <div class="header-padrao p-3 text-center">
        <img class="logo" src="../assets/imgs/legallake-branco.png" />
      </div>
      <div class="px-5 py-4">
        <div v-if="removido" class="text-center">
          <div class="py-3">E-mail removido.</div>
        </div>
        <div class="py-3 text-center small">
          <a href="/">Voltar para Legal Lake</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../config/api";

export default {
  mounted: function () {
    this.$refs.topProgress.start();
    this.removerEmail();
  },
  data: function () {
    return {
      removido: false,
    };
  },
  methods: {
    removerEmail() {
      var email = this.$route.query.email;
      var codigo = this.$route.query.codigo;
      api
        .delete(`emailnovidades?email=${email}&codigo=${codigo}`)
        .then(() => {
          this.removido = true;
        })
        .finally(() => {
          setTimeout(() => {
            this.$refs.topProgress.done();
          }, 150);
        });
    },
  },
};
</script>