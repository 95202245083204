<template>
  <div>
    <router-view></router-view>
    <cookie-law 
    class="border-azul mx-auto my-5 shadow bg-white"
    theme="base"   
    buttonText="Prosseguir" 
    buttonClass="btn btn-primary btn-azul"
    >
    <div slot="message" class="container">
      A SEDEP utiliza cookies e outras tecnologias semelhantes para melhorar a sua experiência, de acordo com a nossa <a href="https://privacidade.sedep.com.br/politica-privacidade" target="_blank">Política de Privacidade</a>, ao continuar navegando, você concorda com estas condições
    </div>
    </cookie-law>
  </div>
          
</template>


<script>
//import Token from "./config/token";
//import UsuarioLogado from "./config/usuario-logado";
import Dispositivo from "./config/dispositivo";
import CookieLaw from 'vue-cookie-law';

export default {
  name: "App",
  components: { CookieLaw },
  data() {
    return {
      classIsMobile: "desktop",
    };
  },
  mounted() {
    /* eslint-disable */
    if (process.env.NODE_ENV === "production") {
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      fbq("init", "337038407976795");
      fbq("track", "PageView");
    }
  },
  metaInfo: {
    htmlAttrs: {
      lang: "pt_BR",
    },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "robots", content: "index, follow" },
      { name: "theme-color", content: "#09076e" },
      {
        name: "title",
        content: "Legal Lake - Mergulhe em processos de todo o Brasil",
      },
      {
        name: "description",
        content:
          "DESCUBRA INFORMAÇÕES JURÍDICASSOBRE QUALQUER PESSOA OU EMPRESA, buscando em processos de todo o Brasil",
      },
      {
        name: "keywords",
        content:
          "Legal Lake, Processo, Busca de Processo, Processos Jurídicos, Busca, Encontre, Pessoa Física, Pessoa Jurídica, Encontrar Pessoa",
      },
      { name: "author", content: "Sedep Brasil" },
    ],
    title: "Legal Lake",
    titleTemplate: "%s - Mergulhe",
    bodyAttrs: {
      class: [
        Dispositivo.mobile() ? "mobile" : "desktop",
        // /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? "mobile" : "desktop",
        //UsuarioLogado.obterUsuarioLogado() && !Token.expirado() ? "logado" : "publico"
      ],
    },
  },
};
</script>

<style lang="scss">
 @import "~bootstrap/scss/bootstrap.scss";
 @import "~bootstrap-vue/src/index.scss";
 @import "@/assets/styles/legallake.scss";
 @import "@/assets/styles/medias.scss";
</style>
