<template>
  <div class="master-vazio">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "MasterVazio",
  components: {},
  metaInfo: {
    bodyAttrs: {
      class: ["bg-cinza-claro"],
    },
  },
};
</script>