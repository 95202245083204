<template>
  <div class="fluid overflow-hidden bg-cinza-claro">
    <vue-topprogress ref="topProgress"></vue-topprogress>
    <div class="container">
      <div class="d-flex align-items-center">
        <div>
          <h3 class="ff-roboto-slab mt-4 pb-0 mb-0">Pesquisas Salvas</h3>
          <small class="text-uppercase">
            Acesso rápido a suas pesquisas salvas</small
          >
        </div>
        <div class="ml-auto"></div>
      </div>
      <div v-if="consultasSalvas && consultasSalvas.length > 0">
        <div class="bg-white p-4 area-usario mt-3 mb-5">
          <div
            v-for="buscaSalva in consultasSalvas"
            :key="buscaSalva"
            class="d-flex align-items-center buscas-salvas p-3"
          >
            <div>
              <div><b>Título: </b>{{ buscaSalva.titulo }}</div>
              <div v-if="buscaSalva.tipoConsulta === 0">
                <b>Termo: </b>{{ buscaSalva.termoPesquisa }}
              </div>
              <div v-if="buscaSalva.tipoConsulta !== 0">
                <b>Termo: </b
                >{{ montarTermoParaExibicao(buscaSalva.termoPesquisa) }}
              </div>
              <div><b>Data Salva: </b>{{ buscaSalva.dataSalva }}</div>
              <div v-if="buscaSalva.dataInicial">
                <b>Data Inicial: </b>{{ buscaSalva.dataInicial }}
              </div>
              <div v-if="buscaSalva.dataFinal">
                <b>Data Final: </b>{{ buscaSalva.dataFinal }}
              </div>

              <div v-if="buscaSalva.estados">
                <b>Estados: </b>{{ buscaSalva.estados }}
              </div>
              <div>
                <b>Tipo de Busca: </b>
                <span v-if="buscaSalva.tipoConsulta === 0"
                  >BUSCA POR FRASE COMPLETA</span
                >
                <span v-if="buscaSalva.tipoConsulta === 1"
                  >BUSCA POR SEMELHANÇA</span
                >
                <span v-if="buscaSalva.tipoConsulta === 2"
                  >BUSCA POR EXPRESSÃO</span
                >
              </div>
            </div>
            <div class="ml-auto acoes">
              <a
                href="javascript:void(0);"
                class="btn btn-sm btn-link mr-1"
                @click="excluirConsulta(buscaSalva)"
                ><i class="fas fa-trash-alt"></i> Excluir</a
              >
              <a
                href="javascript:void(0);"
                class="btn btn-sm btn-primary btn-outline-primary"
                @click="visualizarConsulta(buscaSalva)"
                >Ver Busca</a
              >
            </div>
          </div>
        </div>
        <div class="overflow-auto">
          <b-pagination
            v-model="pagina"
            :total-rows="quantidade"
            :per-page="registrosPorPagina"
            align="center"
            @input="obterConsultasSalvas(pagina)"
          ></b-pagination>
        </div>
      </div>
      <div v-if="consultasSalvas && consultasSalvas.length <= 0">
        <div class="bg-white p-4 area-usario mt-3 mb-5">
          <ResultadoVazio
            titulo="Nenhuma Busca"
            descricao="Salve suas buscas para monitorar resultados"
            icon="fa-exclamation"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ResultadoVazio from "@/components/ResultadoVazio";
import api from "../config/api";
import UsuarioLogado from "../config/usuario-logado";
import ExpressaoBusca from "../models/expressao-busca";

export default {
  components: { ResultadoVazio },
  data: function () {
    return {
      pagina: 1,
      registrosPorPagina: 10,
      quantidade: null,
      consultasSalvas: null,
    };
  },
  mounted() {
    this.$refs.topProgress.start();
    this.obterConsultasSalvas(this.pagina);
    setTimeout(() => {
      this.$refs.topProgress.done();
    }, 150);
  },
  methods: {
    montarTermoParaExibicao(termo) {
      return ExpressaoBusca.converterParaExibicao(termo);
    },
    visualizarConsulta(buscaSalva) {
      var url = `/busca?q=${buscaSalva.tipoConsulta}&w=1&pesquisa=${buscaSalva.termoPesquisa}`;
      if (buscaSalva.dataInicial) {
        url += `&inicio=${buscaSalva.dataInicial}`;
      }
      if (buscaSalva.dataFinal) {
        url += `&fim=${buscaSalva.dataFinal}`;
      }
      if (buscaSalva.estados) {
        url += `&estados=${buscaSalva.estados}`;
      }
      this.$router.push({ path: url });
    },
    excluirConsulta(buscaSalva) {
      if (confirm("Deseja realmente excluir essa busca?")) {
        let loader = this.$loading.show();
        var cliente = UsuarioLogado.obterUsuarioLogado();
        api
          .delete(
            `consultasalva?clienteId=${cliente.clienteId}&consultaSalvaId=${buscaSalva.consultaSalvaId}`
          )
          .then(() => {
            this.obterConsultasSalvas(this.pagina);
            this.$bvToast.toast("Busca excluída com sucesso", {
              title: "Sucesso",
              variant: "success",
              solid: true,
            });
          })
          .finally(() => {
            loader.hide();
          });
      }
    },
    obterConsultasSalvas(pagina) {
      let loader = this.$loading.show();
      var cliente = UsuarioLogado.obterUsuarioLogado();
      api
        .get(
          `consultasalva?clienteId=${cliente.clienteId}
          &pagina=${pagina}&registrosPorPagina=${this.registrosPorPagina}`
        )
        .then((resposta) => {
          this.quantidade = resposta.data.quantidade;
          this.consultasSalvas = resposta.data.consultasSalvas;
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>
<style lang="scss">
.buscas-salvas {
  border-bottom: 1px solid $cinza-medio;
  &:hover {
    background: $cinza-claro;
  }
}
.mobile {
  .buscas-salvas {
    display: block !important;
    .acoes {
      display: flex;
      margin-top: 6px;
      a {
        display: block;
        flex: 1 1 auto;
      }
    }
  }
}
</style>