import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import routes from "./config/routes";
import BootstrapVue from "bootstrap-vue";
import VueCurrencyFilter from "vue-currency-filter";
import Vuex from "vuex";
import store from "./stores/store";
import VueTheMask from "vue-the-mask";
import Vuelidate from "vuelidate";
import vueTopprogress from "vue-top-progress";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import VueGtag from "vue-gtag";
import Hotjar from "vue-hotjar";

Vue.config.productionTip = false;
Vue.config.devtools = process.env.VUE_APP_USE_DEV_TOOLS;

// Registro global de componentes
Vue.component("main-app", require("./App.vue").default);

Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(BootstrapVue);
Vue.use(VueTheMask);
Vue.use(Vuelidate);
Vue.use(vueTopprogress);
Vue.use(require("vue-moment"));
Vue.use(Loading, {
  color: "#fb4343",
  width: 64,
  height: 64,
});

Vue.use(VueCurrencyFilter, [
  {
    symbol: "R$",
    thousandsSeparator: ".",
    fractionCount: 2,
    fractionSeparator: ",",
    symbolPosition: "front",
    symbolSpacing: true,
  },
  {
    name: "currency2",
    symbol: "",
    thousandsSeparator: ".",
    fractionCount: 2,
    fractionSeparator: ",",
    symbolPosition: "front",
    symbolSpacing: true,
  },
]);

const MaskCpfCnpj = {
  install(Vue) {
    Vue.filter("MaskCpfCnpj", (documentId) => {
      documentId =
        typeof documentId != "string" ? documentId.toString() : documentId;
      if (documentId.length > 11) {
        documentId = documentId.padStart(14, "0");
        documentId = documentId.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          "$1.$2.$3/$4-$5"
        );
      } else {
        documentId = documentId.padStart(11, "0");
        documentId = documentId.replace(
          /^(\d{3})(\d{3})(\d{3})(\d{2})/,
          "$1.$2.$3-$4"
        );
      }
      return documentId;
    });
  },
};
Vue.use(MaskCpfCnpj);
const MaskTelefone = {
  install(Vue) {
    Vue.filter("MaskTelefone", (documentId) => {
      documentId =
        typeof documentId != "string" ? documentId.toString() : documentId;
      if (documentId.length == 11) {
        documentId =
          "(" +
          documentId.substr(0, 2) +
          ")" +
          " " +
          documentId.substr(2, 5) +
          "-" +
          documentId.substr(7, 4);
      }
      if (documentId.length == 10) {
        documentId =
          "(" +
          documentId.substr(0, 2) +
          ")" +
          " " +
          documentId.substr(2, 4) +
          "-" +
          documentId.substr(6, 4);
      }
      return documentId;
    });
  },
};
Vue.use(MaskTelefone);

const UnMask = {
  install(Vue) {
    Vue.filter("UnMask", (documentId) => {
      documentId =
        typeof documentId != "string" ? documentId.toString() : documentId;
      documentId = documentId.replace(/[.|/|-]/g, "");
      documentId = documentId.replace(/[)|(| ]/g, "");
      return documentId;
    });
  },
};
Vue.use(UnMask);

window.Vue = Vue;

const router = new VueRouter({
  //mode: 'history', // Com isso as páginas ficam síncronas
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

Vue.use(Hotjar, {
  id: "2214365",
});

Vue.use(VueGtag, {
  config: { id: "G-Q98MTYX4BV" },
});

export const app = new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
