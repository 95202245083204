<template>
  <div>
    <b-dropdown
      id="minha-carteira"
      right
      size="sm"
      v-if="!saldoDisponivel == 0"
      ref="dropdown"
    >
      <template #button-content class="btn-azul">
        <i
          class="fas fa-circle color-vermelho notifica"
          v-if="avisarVencimento"
        ></i>
        <i class="fas fa-wallet mr-1"></i>
        <span class="pr-2">R${{ saldoDisponivel | currency2 }}</span>
      </template>
      <b-dropdown-text slots text-class="p-1">
        <div class="d-flex mb-2" style="width: 250px">
          <b>Minha Carteira</b>
          <a
            href="#/usuario/minha-carteira"
            class="small ml-auto"
            @click="fecharDropDown()"
            >DETALHES</a
          >
        </div>
        <div
          class="pre-pago bg-cinza-claro my-1 p-2"
          v-if="planoCliente && planoCliente.tipoPlano && planoCliente.tipoPlano.toLowerCase() != 'assinatura'"
        >
          <div class="d-flex border-bottom pb-1 mb-1">
            <div class="color-vermelho"><b>Pré-pago</b></div>
            <div class="ml-auto">
              <b>R${{ saldoDisponivel | currency2 }}</b>
            </div>
          </div>

          <div>
            <small
              ><i
                class="fas fa-exclamation-triangle"
                v-if="avisarVencimento"
                :class="avisarVencimento ? 'color-vermelho' : ''"
              ></i>
              Vence em {{ planoValidadde | moment("DD/MM/YYYY") }}</small
            >
          </div>
        </div>
        <div class="assinatura bg-cinza-claro my-1 p-2" v-else>
          <div class="text-b d-flex border-bottom pb-1 mb-1">
            <div class="color-azul"><b>Assinatura</b></div>
            <div class="ml-auto">
              <b>R${{ saldoDisponivel | currency2 }}</b>
            </div>
          </div>

          <small
            >Próx. recorrência
            {{ planoValidadde | moment("DD/MM/YYYY") }}</small
          >
        </div>
        <a
          v-if="podeAdquirir"
          @click="comprarCredito()"
          class="btn btn-primary btn-azul btn-sm d-block"
        >
          Adicionar Créditos
        </a>
      </b-dropdown-text>
    </b-dropdown>
    <div class="credito d-flex" id="show-btn" v-if="saldoDisponivel == 0">
      <div class="valor">${{ saldoDisponivel | currency2 }}</div>

      <a @click="comprarCredito()">
        <i class="fas fa-plus"></i>
      </a>
    </div>
    <ModalAbrirVisualizacao :idModal="idModalCompra" />
  </div>
</template>
<script>
import api from "../config/api";
import UsuarioLogado from "../config/usuario-logado";
import ModalAbrirVisualizacao from "@/components/ModalAbrirVisualizacao";
import moment from "moment";

export default {
  data() {
    return {
      plano: null,
      cliente: null,
      dataPlanoVencimento: null,
      planoValidadde: null,
      diasDeAviso: 15,
      avisarVencimento: false,
      planoCliente: null,
      idModalCompra: "comprarCredito",
      podeAdquirir: false,
    };
  },
  components: {
    ModalAbrirVisualizacao,
  },
  methods: {
    comprarCredito() {
      this.$root.$bvModal.show(this.idModalCompra);
    },
    fecharDropDown() {
      this.$refs.dropdown.hide(true);
    },
    toastrVencimento(dataValidade) {
      var dataHoje = moment().startOf("day");
      var dataAviso = new moment(dataValidade).add(-7, "days").startOf("day");
      var limiteValidade = new moment(dataValidade)
        .add(1, "days")
        .startOf("day");
      if (limiteValidade.isAfter(dataHoje) && (dataAviso.isBefore(dataHoje) || dataAviso.isSame(dataHoje))) {
        var dataUltimoAviso = new moment(
          JSON.parse(localStorage.getItem("ultimo-aviso-vencimento"))
        );
        if (!(dataUltimoAviso.isBefore(dataHoje) || dataUltimoAviso.isSame(dataHoje))){
          var diasParaExpiracao = limiteValidade.diff(dataHoje, 'days')
          var mensagem = "Seus créditos serão expirados em " + diasParaExpiracao + " dia" + (diasParaExpiracao > 1 ? "s" : "");
          this.$root.$bvToast.toast(mensagem, {
            title: "Aviso",
            variant: "warning",
            solid: true,
          });
          localStorage.setItem(
            "ultimo-aviso-vencimento",
            JSON.stringify(dataHoje)
          );
        }
      }
    },
    calculaAviso(plano) {
      this.planoCliente = plano.data;
      this.planoValidadde = this.planoCliente.validade;
      this.podeAdquirir = this.planoCliente.tipoPlano !== "Assinatura";
      var dv = new Date(this.planoValidadde);
      dv.setDate(dv.getDate());
      var d = new Date();
      d.setDate(d.getDate());
      var converterDias = 1000 * 60 * 60 * 24 * this.diasDeAviso;
      this.avisarVencimento = dv - d <= converterDias;
      this.toastrVencimento(dv);
    },
  },
  mounted() {
    var cliente = UsuarioLogado.obterUsuarioLogado();
    api
      .get(`clientes/saldo?clienteId=${cliente.clienteId}`)
      .then((plano) => {
        this.calculaAviso(plano);
      })
      .catch((e) => {
        console.log(e);
      });
  },
  computed: {
    saldoDisponivel() {
      return this.$store.state.dadosUsuario.saldoDisponivel;
      //return 0;
    },
  },
};
</script>
<style lang="scss">
#minha-carteira {
  color: $cinza;
  .notifica {
    position: absolute;
    top: -5px;
    left: -5px;
  }
  .b-dropdown-text {
    font-size: 14px;
    font-weight: normal;
  }
}
</style>
