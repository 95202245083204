<template>
  <div :class="classContainer">
    <div
      class="input-group busca"
      v-click-outside="esconderTipoBusca"
      @click="MostraTipoBusca"
      v-on:keyup.enter="iniciarPesquisaTermo"
      :class="
        tipoConsulta && tipoConsulta !== '0'
          ? `${classContainer} disabled`
          : classContainer
      "
    >
      <input
        :disabled="tipoConsulta && tipoConsulta !== '0'"
        type="text"
        class="form-control"
        :class="classInput"
        :placeholder="
          tipoConsulta && tipoConsulta !== '0'
            ? ''
            : 'Digite nomes, número de processo ...'
        "
        aria-label="Busca Processos"
        aria-describedby="busca-processo"
        v-model="termoPesquisa"
        ref="campoPesquisa"
      />
      <div class="tipo-busca align-self-center"> {{ tipoConsulta == 0 ? '/TERMO' : '/EXPRE'}}</div>
      <div class="input-group-append">
        <button
          :disabled="tipoConsulta && tipoConsulta !== '0'"
          class="btn color-vermelho px-3"
          type="button"
          @click="iniciarPesquisaTermo()"
        >
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>
    <div class="opcao-busca" v-show="abrirOpcoesDeBusca">
      <div
        class="dropdown-menu d-block p-3 w-100"
        aria-labelledby="dropdownMenuButton"
      >
        <div class="ctn-tipo-busca mb-2 border-bottom pb-2">
          <div class="d-flex">
            <div class="input-group custom-control custom-radio w-auto">
              <input
                type="radio"
                :id="idBuscador + 'porTermo'"
                name="tipoDeBusca"
                class="custom-control-input"
                value="0"
                v-model="tipoConsulta"
                :group="idBuscador"
                @change="tipoDeBuscaSelecionado()"
                checked
              />
              <label class="custom-control-label" :for="idBuscador + 'porTermo'"
                >Busca por termo
              </label>
            </div>
            <div class="tipo-busca align-self-center pl-1">/TERMO</div>
          </div>
          <small class="ml-4 color-grafite">
            <i class="far fa-life-ring"></i>
            Busca completa, na sequência em que foram digitadas.
          </small>
        </div>
        <div class="ctn-tipo-busca mb-3">
          <div class="d-flex">
            <div class="input-group custom-control custom-radio w-auto">
              <input
                type="radio"
                :id="idBuscador + 'porExpressao'"
                name="tipoDeBusca"
                class="custom-control-input"
                value="2"
                v-model="tipoConsulta"
                :group="idBuscador"
                @change="tipoDeBuscaSelecionado()"
              />
              <label
                class="custom-control-label"
                :for="idBuscador + 'porExpressao'"
                >Busca por expressão</label
              >
            </div>
            <div class="tipo-busca align-self-center pl-1">/EXPRE</div>
          </div>

          <small class="ml-4 color-grafite">
            <i class="far fa-life-ring"></i>
            A busca pode ser realizada com condições que contenha: <b>E</b>,
            <b>OU</b> e <b>NÃO</b>.
          </small>

          <div
            class="busca-por-expressao ml-4 mt-2"
            v-if="this.tipoConsulta == 2"
          >
            <b-input-group size="sm" class="mb-2" prepend="Contêm">
              <b-form-input v-model="expressaoTermo"></b-form-input>
              <b-input-group-append></b-input-group-append>
            </b-input-group>

            <b-input-group
              size="sm"
              class="mb-1"
              v-for="numeroExpressao in limiteDeExpressoes"
              :key="numeroExpressao"
              v-show="expressoes[numeroExpressao - 1].exibirExpressao"
            >
              <b-form-input
                v-on:keyup.enter="iniciarPesquisaExpressao"
                v-model="expressoes[numeroExpressao - 1].expressao"
              ></b-form-input>
              <b-input-group-append>
                <b-button
                  v-if="numeroExpressao - 1 !== 0"
                  @click="removerExpressao(numeroExpressao - 1)"
                  variant="link"
                  class="btn-excluir"
                  ><i class="fas fa-times color-vermelho"></i
                ></b-button>
              </b-input-group-append>
              <template #prepend>
                <b-dropdown
                  size="sm"
                  class=""
                  :text="expressoes[numeroExpressao - 1].nomeExpressao"
                >
                  <b-dropdown-item
                    v-for="(termo, indice) in tipoExpressao"
                    :key="numeroExpressao - 1 + indice"
                    @click="
                      selecionarTipoExpressao(numeroExpressao - 1, indice)
                    "
                  >
                    {{ termo.nome }}
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-input-group>

            <div class="d-flex justify-content-between mt-2">
              <button
                :disabled="expressoesUtilizadas >= limiteDeExpressoes"
                class="btn btn-sm btn-link px-0"
                @click="adicionarExpressao()"
              >
                <i class="fas fa-plus-square pr-1"></i>adicionar condição
              </button>
              <button
                class="btn btn-sm btn-primary btn-azul"
                @click="iniciarPesquisaExpressao()"
              >
                BUSCAR
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClickOutside from "vue-click-outside";
import Dispositivo from "../config/dispositivo";
import _ from "lodash";
import ExpressaoBusca from "../models/expressao-busca";

export default {
  name: "buscador",
  mounted() {
    this.popupItem = this.$el;
    this.init();
  },
  props: {
    classContainer: String,
    classInput: String,
    idBuscador: String,
  },
  data: function () {
    return {
      /* tipoConsulta
       * 0 = Busca por frase exata (PADRÃO)
       * 1 = Busca semelhança nos termos
       * 2 = Busca por expressões
       */

      tipoConsulta: "0",
      paginaAtual: 1,
      termoPesquisa: "",
      abrirOpcoesDeBusca: false,
      expressaoTermo: null,
      expressoes: [
        {
          exibirExpressao: true,
          tipoExpressao: "AND",
          expressao: null,
          nomeExpressao: "E",
        },
        {
          exibirExpressao: false,
          tipoExpressao: "AND",
          expressao: null,
          nomeExpressao: "E",
        },
        {
          exibirExpressao: false,
          tipoExpressao: "AND",
          expressao: null,
          nomeExpressao: "E",
        },
      ],
      tipoExpressao: [
        {
          nome: "E",
          valor: "AND",
        },
        {
          nome: "OU",
          valor: "OR",
        },
        {
          nome: "NÃO",
          valor: "NOT",
        },
      ],
      it: null,
      limiteDeExpressoes: 3,
      expressoesUtilizadas: 1,
    };
  },
  methods: {
    init() {
      this.apresentarPesquisaRealizada();
    },
    apresentarPesquisaRealizada() {
      let termoPesquisa = this.$route.query.pesquisa;
      let tipoConsulta = this.$route.query.q;

      if (tipoConsulta) {
        this.tipoConsulta = tipoConsulta;

        if (termoPesquisa) {
          if (tipoConsulta === "2") {
            this.termoPesquisa =
              ExpressaoBusca.converterParaExibicao(termoPesquisa);

            var termos = this.termoPesquisa.split('" ');

            if (termos[0]) {
              this.expressaoTermo = termos[0].substring(1);
            }

            if (termos[1]) {
              let separacaoTermos = termos[1].split(' "');
              let tipo = this.tipoExpressao.filter(
                (te) => te.nome === separacaoTermos[0]
              );
              this.expressoes[0].expressao = separacaoTermos[1];
              if (this.expressoes[0].expressao.slice(-1) === '"') {
                this.expressoes[0].expressao =
                  this.expressoes[0].expressao.slice(0, -1);
              }
              this.expressoes[0].tipoExpressao = tipo[0].valor;
              this.expressoes[0].nomeExpressao = tipo[0].nome;
            }

            if (termos[2]) {
              let separacaoTermos = termos[2].split(' "');
              let tipo = this.tipoExpressao.filter(
                (te) => te.nome === separacaoTermos[0]
              );
              this.expressoes[1].expressao = separacaoTermos[1];
              if (this.expressoes[1].expressao.slice(-1) === '"') {
                this.expressoes[1].expressao =
                  this.expressoes[1].expressao.slice(0, -1);
              }
              this.expressoes[1].exibirExpressao = true;
              this.expressoes[1].tipoExpressao = tipo[0].valor;
              this.expressoes[1].nomeExpressao = tipo[0].nome;
            }

            if (termos[3]) {
              let separacaoTermos = termos[3].split(' "');
              let tipo = this.tipoExpressao.filter(
                (te) => te.nome === separacaoTermos[0]
              );
              this.expressoes[2].expressao = separacaoTermos[1];
              if (this.expressoes[2].expressao.slice(-1) === '"') {
                this.expressoes[2].expressao =
                  this.expressoes[2].expressao.slice(0, -1);
              }
              this.expressoes[2].exibirExpressao = true;
              this.expressoes[2].tipoExpressao = tipo[0].valor;
              this.expressoes[2].nomeExpressao = tipo[0].nome;
            }
          }
          if (tipoConsulta === "0") {
            this.termoPesquisa = termoPesquisa;
          }
        }
      }
    },
    tipoDeBuscaSelecionado() {
      this.termoPesquisa = null;
      _.forEach(this.expressoes, function (expressao) {
        expressao.exibirExpressao = false;
        expressao.tipoExpressao = "AND";
        expressao.expressao = null;
        expressao.nomeExpressao = "E";
      });
      this.expressoes[0].exibirExpressao = true;
      this.expressoesUtilizadas = 1;
    },
    iniciarPesquisaTermo() {
      if (this.termoPesquisa !== "") {
        if (this.verificarNumeroCNJ(this.termoPesquisa)) {
          this.termoPesquisa = this.preencherZerosNumeroCNJ(this.termoPesquisa);
        }
        this.executarPesquisa(this.termoPesquisa);
      }
    },
    iniciarPesquisaExpressao() {
      let quantidadeDeExpressoes = this.expressoes.filter(
        (e) => e.expressao && e.expressao !== ""
      ).length;
      if (
        this.expressaoTermo &&
        this.expressaoTermo !== "" &&
        quantidadeDeExpressoes >= 1
      ) {
        let expressoesUtilizadas = this.expressoes.filter(
          (e) => e.expressao && e.expressao !== ""
        );

        if (this.verificarNumeroCNJ(this.expressaoTermo)) {
          this.expressaoTermo = this.preencherZerosNumeroCNJ(
            this.expressaoTermo
          );
        }

        let self = this;
        _.forEach(expressoesUtilizadas, function (termo) {
          if (self.verificarNumeroCNJ(termo.expressao)) {
            termo.expressao = self.preencherZerosNumeroCNJ(termo.expressao);
          }
        });

        let expressao = this.montarTermoExpressao();
        this.executarPesquisa(expressao);
      }
    },
    preencherZerosNumeroCNJ(termo) {
      while (termo.length < 25) {
        termo = "0" + termo;
      }
      return termo;
    },
    verificarNumeroCNJ(termo) {
      //eslint-disable-next-line
      let regex =
        "^[\\d]+-[\\d][\\d].[\\d][\\d][\\d][\\d].[\\d].[\\d][\\d].[\\d][\\d][\\d][\\d]$";
      let matches = termo.match(regex);
      return matches;
    },
    montarTermoExpressao() {
      let expressaoCompilada = `"${this.expressaoTermo}"`;

      let expressoesUtilizadas = this.expressoes.filter(
        (e) => e.expressao && e.expressao !== ""
      );

      _.forEach(expressoesUtilizadas, function (expressao) {
        expressaoCompilada += ` ${expressao.tipoExpressao} "${expressao.expressao}"`;
      });

      return expressaoCompilada;
    },
    executarPesquisa(pesquisa) {
      var caminhoBusca = this.$route.path === "/busca";

      var query = {
        q: this.tipoConsulta, // q = Tipo de consulta
        w: this.paginaAtual, // w = Página atual
        pesquisa: pesquisa, // pesquisa = Termo pesquisado pelo usuário,
      };

      if (this.$route.query.inicio) {
        query.inicio = this.$route.query.inicio;
      }

      if (this.$route.query.fim) {
        query.fim = this.$route.query.fim;
      }

      if (this.$route.query.estados) {
        query.estados = this.$route.query.estados;
      }

      this.$router.push({
        path: "/busca",
        query: query,
      });

      if (caminhoBusca) {
        this.$router.go();
        if (Dispositivo.Safari()) {
          window.location.reload();
        }
      }
    },
    selecionarTipoExpressao(numero, indice) {
      this.expressoes[numero].tipoExpressao = this.tipoExpressao[indice].valor;
      this.expressoes[numero].nomeExpressao = this.tipoExpressao[indice].nome;
    },
    adicionarExpressao() {
      if (this.expressoesUtilizadas <= this.limiteDeExpressoes) {
        this.expressoesUtilizadas += 1;
        let expressao = this.expressoes.filter((e) => !e.exibirExpressao)[0];
        expressao.exibirExpressao = true;
      }
    },
    removerExpressao(numero) {
      this.expressoes[numero].exibirExpressao = false;
      this.expressoes[numero].tipoExpressao = "AND";
      this.expressoes[numero].expressao = null;
      this.expressoes[numero].nomeExpressao = "E";
      this.expressoesUtilizadas -= 1;
    },
    MostraTipoBusca() {
      this.abrirOpcoesDeBusca = true;
    },
    esconderTipoBusca() {
      this.abrirOpcoesDeBusca = false;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
<style lang="scss">
@import "~bootstrap/scss/bootstrap.scss";
.tipo-busca {
  text-transform: uppercase;
  border-radius: 3px;
  background: #f8f8f8;
  border: 1px solid #dedede;
  font-family: Consolas;
  font-weight: bold;
  font-size: 12px;
  color: #1e1e1e;
  padding: 1px 2px;
}

.opcao-busca {
  position: relative;
  .custom-radio {
    font-size: 1rem;
    font-weight: 300;

    &:last-child {
      border: 0;
    }
    label {
      padding-top: 4px;
    }
  }
}

.busca-por-expressao {
  .input-group-sm {
    input {
      height: inherit;
    }

    .input-group-prepend .dropdown {
      button {
        width: 70px;
      }
    }
    input {
      padding: 0 4px;
    }
  }
  .input-group-append .btn-excluir {
    border: 1px solid #ccc;
    background: #f1f1f1;
  }
}

#ajuda-busca .modal-content {
  background: transparent;
}
</style>
