<template>
  <div class="d-sm-flex align-items-md-center container-onda">
    <vue-topprogress ref="topProgress"></vue-topprogress>
    <div class="login m-auto">
      <div class="header-padrao p-3 text-center">
        <img class="logo" src="../assets/imgs/legallake-branco.png" />
      </div>
      <div class="px-3 px-md-5 py-4">
        <div class="text-center">
          <h3 class="p-0 m-0 font-weight-normal">Recuperar Senha</h3>
          <h6 class="p-2 small">Digite seu e-mail para recuperar a senha.</h6>
        </div>
        <div v-if="emailEnviado" class="alert alert-azul" role="alert">
          Foi enviado para o seu e-mail informações de recuperação de senha.
        </div>
        <div v-if="!emailEnviado">
          <div class="form-group">
            <label for="email">E-mail cadastrado</label>
            <input
              type="email"
              class="form-control"
              id="email"
              v-model="emailCadastrado"
              placeholder="nome@servidor.com.br"
              :class="{ 'is-invalid': $v.emailCadastrado.$error }"
            />
            <b-form-invalid-feedback
              v-if="!$v.emailCadastrado.required && formularioPostado"
              >Campo obrigatório<br
            /></b-form-invalid-feedback>
            <b-form-invalid-feedback
              v-if="!$v.emailCadastrado.email && formularioPostado"
              >E-mail inválido<br
            /></b-form-invalid-feedback>
          </div>
          <button
            @click="enviarEmailConfirmacao()"
            type="button"
            class="btn btn-primary btn-block"
          >
            Recuperar Senha
          </button>
        </div>
        <div class="py-3 text-center small">
          <a href="#/login">Voltar para o Login</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../config/api";
import { required, email } from "vuelidate/lib/validators";

export default {
  mounted() {
    this.$refs.topProgress.start();
    setTimeout(() => {
      this.$refs.topProgress.done();
    }, 150);
  },
  data: function () {
    return {
      formularioPostado: false,
      emailEnviado: false,
      emailCadastrado: "",
    };
  },
  validations: {
    emailCadastrado: { required, email },
  },
  methods: {
    enviarEmailConfirmacao: function () {
      this.$v.emailCadastrado.$touch();
      this.formularioPostado = true;
      if (!this.$v.emailCadastrado.$error) {
        let loader = this.$loading.show();
        api
          .post(`email/enviar-recuperacao-senha`, {
            emailCadastrado: this.emailCadastrado,
          })
          .then(() => {
            this.$root.$bvToast.toast(
              "Foi enviado para o seu e-mail informações de recuperação de senha.",
              {
                title: "Sucesso",
                variant: "success",
                solid: true,
              }
            );
            this.emailEnviado = true;
            this.$router.push({ path: "/" });
          })
          .finally(() => {
            loader.hide();
          });
      }
    },
  },
};
</script>