<template>
  <div>
    <div class="fluid destaque">
      <div class="container d-flex flex-column justify-content-center">
        <div class="align-items-center d-flex h-75 justify-content-center">
          <div class="d-flex flex-column align-items-center flex-wrap w-100">
            <div class="txt-destaque text-center">
              <h1>
                <span class="d-block text-weight-bold"
                  >Navegue em
                  <span class="color-vermelho"> 800+ milhões </span>
                  <div class="d-md-inline-block txt-00">
                    de publicações processuais
                  </div></span
                >
                <div class="txt-01">
                  <span>dos Tribunais de todo o Brasil</span>
                </div>
              </h1>
              <h5>
                Encontre Informações
                <span> Jurídicas de Pessoas e Empresas</span>
              </h5>
              <!--  <small
                class="text-white d-block mt-3 mb-5"
                v-if="configuracoesDoSistema"
                >{{ configuracoesDoSistema.textoAbragenciaProcessos }}</small
              > -->
            </div>
            <div class="item text-center mt-5 buscador">
              <Buscador classImput="form-control-lg" />
            </div>
            <div class="d-flex m-3 txt-02">
              <span>
                <i class="bi bi-clock-history pr-2 color-vermelho"></i>
                Processos a partir de 2015 atualizados semanalmente
              </span>
            </div>
          </div>
        </div>
        <div
          class="
            descubra
            h-md-25
            d-flex
            align-items-center
            justify-content-center
          "
        >
          <div class="d-flex flex-column align-items-center flex-wrap w-100">
            <div
              class="
                para-baixo
                d-flex
                align-items-center
                justify-content-center
              "
            >
              <i
                class="fas fa-arrow-down"
                :class="[
                  piscarSeta
                    ? 'animate__animated animate__fadeIn animate__infinite infinite'
                    : '',
                ]"
              ></i>
            </div>
            <div class="small">descubra</div>
          </div>
        </div>
      </div>
      <svg
        class="ondas"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
        shape-rendering="auto"
      >
        <defs>
          <path
            id="ondas-suave"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <g class="parallax">
          <use xlink:href="#ondas-suave" x="48" y="0" />
          <use xlink:href="#ondas-suave" x="48" y="4" />
          <use xlink:href="#ondas-suave" x="48" y="7" />
          <use xlink:href="#ondas-suave" x="48" y="5" />
          <use xlink:href="#ondas-suave" x="48" y="7" />
        </g>
      </svg>
    </div>
    <div class="fluid recursos">
      <div class="container py-5">
        <h2 class="ff-roboto-slab p-md-5 text-center">
          DESCUBRA INFORMAÇÕES JURÍDICAS<br />SOBRE QUALQUER PESSOA OU EMPRESA
        </h2>
        <div class="descricao mt-md-5 d-flex align-items-center">
          <ul class="list-unstyled col-md-6">
            <li>
              <i class="fas fa-search"></i>Nomes de pessoas, empresas, sócios
            </li>
            <li><i class="fas fa-search"></i>Números de OAB e processos</li>
            <li>
              <i class="fas fa-search"></i>Partes de um processo, como
              advogados, juízes...
            </li>
            <li>
              <i class="fas fa-search"></i>Assuntos, temas ou qualquer palavra
              que deseja encontrar
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="fluid passos">
      <div class="container py-md-5">
        <div class="d-flex flex-column flex-lg-row">
          <div class="p-5 passo passo-1">
            <div class="ordem">1º</div>
            <div class="txt-passos">
              <div class="ttl">DIGITE O TERMO NO CAMPO DE BUSCA</div>
              <div class="ctn">
                As pesquisas são ilimitadas, navegue à vontade
              </div>
            </div>
          </div>
          <div class="p-5 passo passo-2">
            <div class="ordem">2º</div>
            <div class="txt-passos">
              <div class="ttl">ENCONTRE INFORMAÇÕES OFICIAIS</div>
              <div class="ctn">
                O seu termo é pesquisado em milhões de páginas de Jornais
                Oficiais
              </div>
            </div>
          </div>
          <div class="p-5 passo passo-3">
            <div class="ordem">3º</div>
            <div class="txt-passos">
              <div class="ttl">SÓ PAGUE PELOS RESULTADOS ABERTOS</div>
              <div class="ctn">
                Você só será cobrado para ler resultados na íntegra
              </div>
            </div>
          </div>
        </div>
        <div
          class="
            d-flex
            flex-column flex-md-row
            justify-content-md-center
            pt-2 pt-md-5
            acoes
          "
        >
          <a
            href="javascript:void(0);"
            class="btn btn-primary btn-lg px-4"
            role="button"
            aria-pressed="true"
            @click="irParaPesquisa()"
            >Faça já uma pesquisa
            <i class="fas fa-angle-double-up pl-3"></i>
          </a>
          <a
            href="javascript:void(0);"
            class="btn btn-primary btn-azul btn-lg px-4"
            role="button"
            aria-pressed="true"
            @click="irParaPlanos()"
            >Conheça os nossos planos
            <i class="fas fa-angle-double-down pl-3"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="fluid planos" id="planos">
      <div class="container">
        <div class="head-onda">
          <div class="d-flex justify-content-center align-items-center pt-5">
            <img class="mr" src="../assets/imgs/onda-h.png" />
            <h2 class="ff-roboto-slab px-3 text-center">Planos</h2>
            <img class="ml" src="../assets/imgs/onda-h.png" />
          </div>
          <div class="text-center font-weight-bold mx-5 my-3 color-azul">
            Créditos e Assinaturas que se adequam a sua necessidade.
          </div>
          <div class="text-center font-weight mx-5 mt-4 mt-md-5">
            <i class="fas fa-angle-double-down pr-3"></i>Escolha o plano ideal
            para você<i class="fas fa-angle-double-down pl-3"></i>
          </div>
        </div>
        <div class="tipo-planos">
          <div class="p-md-4 mt-4">
            <div class="row px-md-4">
              <div class="col"><PlanoPrePago></PlanoPrePago></div>
              <div class="col"><PlanoAssinatura></PlanoAssinatura></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fluid newslatter" id="newslatter">
      <div class="container">
        <div class="ctn p-4 mt-5 m-md-5">
          <h2>Novidades</h2>
          <div class="pb-3">
            O nosso acervo está crescendo. Cadastre-se e receba as atualizações
            no seu e-mail.
          </div>

          <div class="input-group w-75 form-newslatter">
            <input
              type="text"
              class="form-control"
              placeholder="Digite seu e-mail"
              aria-label="e-mail"
              aria-describedby="e-mail-newslatter"
              v-model="emailNovidades"
            />
            <div class="input-group-append">
              <button
                @click="cadastrarEmailNovidades()"
                class="btn btn-primary px-4"
                type="button"
              >
                <i class="fas fa-paper-plane"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Buscador from "../components/Buscador";
import PlanoPrePago from "../components/checkout/PlanoPrePago";
import PlanoAssinatura from "../components/checkout/PlanoAssinatura";
import api from "../config/api";
import { required, email } from "vuelidate/lib/validators";

export default {
  components: {
    Buscador,
    PlanoPrePago,
    PlanoAssinatura,
  },
  data: function () {
    return {
      emailNovidades: "",
      piscarSeta: false,
      configuracoesDoSistema: null,
    };
  },
  mounted() {
    this.obterConfiguracoesDoSistema();
    this.verificarRota();
  },
  beforeMount: function () {
    // setInterval(
    //   function () {
    //     this.piscarSeta = !this.piscarSeta;
    //   }.bind(this),
    //   1500
    // );
  },
  validations: {
    emailNovidades: { required, email },
  },
  methods: {
    obterConfiguracoesDoSistema() {
      api.get("configuracoessistema").then((resp) => {
        this.configuracoesDoSistema = resp.data;
      });
    },
    cadastrarEmailNovidades() {
      this.$v.emailNovidades.$touch();
      if (!this.$v.emailNovidades.required) {
        this.$bvToast.toast("Digite seu e-mail", {
          title: "Erro",
          variant: "danger",
          solid: true,
        });
      }
      if (this.$v.emailNovidades.required && !this.$v.emailNovidades.email) {
        this.$bvToast.toast("E-mail inválido", {
          title: "Erro",
          variant: "danger",
          solid: true,
        });
      }

      if (this.$v.emailNovidades.required && this.$v.emailNovidades.email) {
        let loader = this.$loading.show();
        api
          .post("emailnovidades", { email: this.emailNovidades })
          .then(() => {
            this.emailNovidades = "";
            this.$bvToast.toast("E-mail cadastrado", {
              title: "Sucesso",
              variant: "success",
              solid: true,
            });
          })
          .finally(() => {
            loader.hide();
          });
      }
    },
    verificarRota() {
      if (this.$route.name.toLowerCase() === "planos") {
        setTimeout(() => {
          this.irParaPlanos();
        }, 10);
      }
    },
    irParaPesquisa() {
      document
        .getElementById("pesquisar")
        .scrollIntoView({ behavior: "smooth" });
    },
    irParaPlanos() {
      document.getElementById("planos").scrollIntoView({ behavior: "smooth" });
    },
  },
  computed: {
    comprando() {
      return this.$store.state.checkout.comprando;
    },
  },
  metaInfo: {
    bodyAttrs: {
      class: ["home"],
    },
  },
};
</script>

<style lang="scss">
@import "../assets/styles/pages/home.scss";
</style>
