import jwt_decode from "jwt-decode";

export default class Token {
    static expirado() {
        var token = localStorage.getItem('access_token');
        if (token) {
            var decoded = jwt_decode(token);
            var dataExpiracao = new Date(decoded.exp * 1000);
            return dataExpiracao < Date.now();
        } else {
            return true;
        }
    }
}