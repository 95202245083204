 <template>
  <b-modal
    :id="idModal"
    :modal-class="[this.status, 'modal-sem-credito']"
    body-class="p-0 visualizacao-credito shadow-none"
    centered
    hide-footer
    hide-header
    @show="carregarModal"
  >
    <div class="header">
      <div class="header-icon text-center">
        <img
          src="@/assets/imgs/icons/apple-icon-76x76.png"
          alt="Creditos Legal lake"
        />
      </div>
      <div class="header-info text-center text-white">
        <h2 class="color-vermelho font-weight-light">
          {{ txtLogado }}
        </h2>
        <p v-show="!esconder">
          Você não esta logado ou não possui créditos. <br />
        </p>
        <a
          @click="fazerLogin"
          v-show="!esconder"
          class="px-2 py-0 btn btn-primary btn-sm"
          >Fazer Login</a
        >
      </div>
    </div>
    <div class="creditos">
      <div style="background: #f1f1f1" class="font-weight-bold p-3">
        Para visualizar os resultados é necessário adquirir créditos
      </div>
      <div class="">
        <b-tabs class="selecionar-plano" fill>
          <b-tab title="Pré-Pago" active
            ><PlanoPrePago :abrindoPublicacao="true"
          /></b-tab>
          <b-tab title="Assinatura"><PlanoAssinatura /></b-tab>
        </b-tabs>
      </div>
      <a href="#" @click="$bvModal.hide(idModal)" class="p-2 btn btn-link d-block"
        >Voltar</a
      >
    </div>
  </b-modal>
</template>
<script>
import PlanoPrePago from '@/components/checkout/PlanoPrePago';
import PlanoAssinatura from '@/components/checkout/PlanoAssinatura';

export default {
  components: {
    PlanoPrePago,
    PlanoAssinatura
  },
  props: {
    status: String,
    idPublicacao: String,
    pesquisa: String,
    tipoConsulta: Number,
    paginaAtual: Number,
    idModal: String,
  },
  data () {
    return {
      txtLogado: "Quase lá!",
      esconder: false,
    }
  },
  mounted () {

  },

  methods: {
    carregarModal () {
      if (localStorage.getItem('access_token') !== null) {
        {
          this.esconder = true;
          if (this.pesquisa) {
            this.txtLogado = "Abrir Publicação"
          }
          else {
            this.txtLogado = "Comprar Crédito"
          }
        }
      }
    },
    gravaStadoPesquisa () {

      this.$store.commit('logBusca', {
        tipoConsulta: this.tipoConsulta,
        paginaAtual: this.paginaAtual,
        pesquisa: this.pesquisa,
        idPublicacao: this.idPublicacao
      });

      this.$store.commit('abrindoPublicacao', { abrindoPublicacao: true });

    },
    async fazerLogin () {

      await this.gravaStadoPesquisa()

      this.$router.push({ path: 'login' })

    }
  }
}
</script>
<style lang="scss">
.modal-sem-credito {
  .modal-content {
    background: transparent;
  }
  .visualizacao-credito {
    .header-icon {
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 50%,
        $azul_medio 50%
      );
    }
    .header-info {
      background: $azul_medio;
      padding: 20px;
    }
    .creditos {
      background: $branco;
    }
  }
  .selecionar-plano {
    //display: none;
    .nav-tabs {
      a {
        border-radius: 0;
        background-color: $cinza-medio;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
      }
      .nav-item:nth-child(1) {
        a {
          color: $vermelho;
          &.active {
            background-color: $vermelho;
            color: #fff;
          }
        }
      }
      .nav-item:nth-child(2) {
        a {
          color: $azul-escuro;
          &.active {
            background-color: $azul;
            color: #fff;
          }
        }
      }
    }
    .tab-content {
      .plano {
        .ttl {
          display: none;
        }
      }
      .ctn {
        border: none;
      }
    }
  }
  .actions {
    .btn-link {
      font-size: 0.9rem;
    }
  }
}
</style>