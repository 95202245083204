import UsuarioLogado from "./usuario-logado";
import api from "../config/api";
import { forEach } from 'lodash';
import { app } from "@/main";

export default class Pagamentos {

  static obterPagamentosPendentesDoUsuario() {

    var usuario = UsuarioLogado.obterUsuarioLogado();

    api
      .get(`pagamentos/pagamentos-pendentes?clienteId=${usuario.clienteId}`)
      .then((resposta) => {
        if (resposta.data.pagamentos.length > 0) {
          forEach(resposta.data.pagamentos, function (pagamento) {
            Pagamentos.verificarPagamentos(pagamento);
          });
        }
      });
  }

  static verificarPagamentos(pagamento) {
    var usuario = UsuarioLogado.obterUsuarioLogado();

    var intervalo = setInterval(function () {
      api
        .post(`pagamentos/verificar-pagamento`, {
          clienteId: usuario.clienteId,
          pagamentoId: pagamento.pagamentoId
        })
        .then((resposta) => {
          if (resposta.data) {
            app.$bvToast.toast('Pagamento confirmado', {
              title: `Sucesso`,
              variant: "success",
              solid: true
            });
            clearInterval(intervalo);
          }
        });
    }, 60000);
  }
}