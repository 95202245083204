<template>
  <div class="assinatura plano d-flex flex-column justify-content-center">
    <div class="ttl">Assinatura</div>
    <div class="ctn">
      <div class="dest p-4">
        <div class="descricao text-center py-2">
          Para quem realiza pesquisas frequentes
        </div>
        <div class="regras text-center small text-uppercase mt-3">
          Plano assinatura com recorrência mensal
        </div>
      </div>
      <div
        v-if="Planos.length > 0"
        class="valor d-flex align-items-end px-3 py-2 text-uppercase"
      >
        Custo por visualização:
        <span class="valor-desconto d-none" v-if="false"><s>R$ 2.90</s></span>
        <div class="ml-auto d-flex align-items-center">
          <div class="valor-final">
            {{ Planos[idPlano].valorConsulta | currency }}
          </div>
          <div class="d-inline-block desconto small ml-2" v-if="false">
            {{ Math.floor((Planos[idPlano].valorConsulta / 2.9 - 1) * 100) }}%
          </div>
        </div>
      </div>
      <div class="selecao p-3 my-4">
        <div class="range">
          <b-form-input
            id="range-pre-pago"
            v-model="idPlano"
            type="range"
            min="0"
            :max="Planos.length - 1"
          ></b-form-input>
          <div class="mt-2"></div>
        </div>
      </div>
      <div v-if="Planos.length > 0" class="valores d-flex mt-2 px-3">
        <div>
          <div class="qtd-v">
            {{
              Math.floor(
                Planos[idPlano].valorRecargaMensal /
                  Planos[idPlano].valorConsulta
              )
            }}
          </div>
          <div class="small text-uppercase">Visualizações</div>
        </div>
        <div class="total mb-3 ml-auto">
          <div class="d-inline-block desconto small text-center w-100 d-none">
            TOTAL
            <span v-if="false">
              {{
                Math.floor((Planos[idPlano].valorConsulta / 2.9 - 1) * 100)
              }}%</span
            >
          </div>
          <div class="valor-total text-right">
            {{ Planos[idPlano].valorRecargaMensal | currency }}
          </div>
        </div>
      </div>
      <a
        v-if="podeAdquirir"
        @click="checkout"
        class="btn btn-primary btn-azul d-block m-3"
        >ADQUIRIR</a
      >
    </div>
  </div>
</template>
<script>
import api from "../../config/api";
import UsuarioLogado from "../../config/usuario-logado";

export default {
  data: function () {
    return {
      idPlano: "0",
      Planos: [],
      podeAdquirir: false,
    };
  },
  methods: {
    CarregaPlanos() {
      api
        .get("PlanosAssinatura?tipo=assinatura")
        .then((resp) => {
          this.Planos = resp.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    SelecionaPlano() {
      this.$store.commit("selecionaPlano", {
        idPlano: this.Planos[this.idPlano].planoAssinaturaId,
        valor: this.Planos[this.idPlano].valorRecargaMensal,
        descricao: this.Planos[this.idPlano].descricao,
        tipo: "ASSINATURA",
      });
    },
    verificarPlano() {
      var cliente = UsuarioLogado.obterUsuarioLogado();
      if (cliente) {
        api
          .get(`clientes/saldo?clienteId=${cliente.clienteId}`)
          .then((saldo) => {
            this.podeAdquirir = saldo.data.tipoPlano !== "Assinatura";
          });
      } else {
        this.podeAdquirir = true;
      }
    },
    checkout() {
      this.SelecionaPlano();

      this.$store.commit("comprando", { comprando: true });

      if (localStorage.getItem("access_token") !== null) {
        this.$router.push({ path: "checkout" });
      } else {
        this.$router.push({ path: "cadastro" });
      }
    },
  },
  watch: {
    idPlano: function () {
      this.SelecionaPlano();
    },
  },
  mounted() {
    this.CarregaPlanos();
    this.verificarPlano();
  },
};
</script>