<template>
  <div class="container vh-90 p-5">
    <div class="d-flex msg bg-white p-3 mb-5 shadow-lg rounded">
      <div class="mr-2">
        <img class="img-404" src="../assets/imgs/404.png" alt="Erro 404" />
      </div>
      <div>
        <h4 class="font-weight-light">
          Nenhuma página foi encontrada em nosso Lago
        </h4>
        <small> Página não encontrada - erro 404</small>
      </div>
    </div>
    <div class="my-5 py-5 text-center">
      <h2>
        <span class="font-weight-light d-block"
          >Experimente mergulhar em mais de</span
        >
        <span class="color-vermelho text-weight-bold"> 800+ milhões </span>
        de processos
      </h2>
      <div class="item text-center">
        <Buscador
          classContainer="my-3 mx-auto w-75 shadow-md"
          classImput="form-control-lg"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Buscador from '../components/Buscador';

export default {
  metaInfo: {
    bodyAttrs: {
      class: ['bg-cinza-claro']
    },
  },
  components: {
    Buscador,
  },
  mouted () { },
  data: function () {
    return {
      show: true,
    };
  },
  methods: {},

};
</script>
<style lang="scss">
.busca {
  box-shadow: 1px 1px 4px #000;
}
.img-404 {
  width: 70px;
}
</style>