<template>
<b-modal
    id="ModalCompraCreditoAdicional"
    body-class="p-0 visualizacao-credito shadow-none"
    centered
    hide-footer
    hide-header
  >

      <div class="header-padrao p-3 text-center">
        <a href="#/"
          ><img class="logo" src="../assets/imgs/legallake-branco.png"
        /></a>
      </div>

      <div v-if="cartaoCadastrado" class="px-3 px-md-5 py-4"> 
        <h6>Valor do Crédito</h6>
        <div class="input-group">
            <input
            type="text"
            class="form-control"
            id="valorCompra"
            placeholder="Valor"
            autofocus
            />
        </div>
        
        <h6 class="mt-3">Forma de Pagamento</h6>
        <div class="assinatura bg-cinza-claro my-1 p-2">
          <i class="fas fa-credit-card pr-2"></i>{{ cartaoCadastrado.payment_company.name }} <div><b>Titular: </b>{{ cartaoCadastrado.holder_name }}</div>
          <div><b>Número:</b> {{ cartaoCadastrado.card_number_first_six }}****{{ cartaoCadastrado.card_number_last_four }}</div>          
        </div>
      </div>

    <div class="row px-3 px-md-5 py-4 ">       
    <div class="col-md-12"> 
        <button
            type="submit"
            @click="efetuaPagamento"
            :disabled="false"
            class="btn btn-info btn-block"
            >
            <span
                v-show="false"
                class="spinner-border spinner-border-sm mr-2"
                role="status"
                aria-hidden="true"
            ></span>

            <span v-show="false == false"
                ><i class="fas fa-shield-alt pr-2"></i>CONCLUIR COMPRA
                </span>
            <span v-show="false">Processando Pagamento...</span>
        </button>
    </div>
    
    <!-- <div class="col-md-5"> 
        <button
            type="submit"
            @click="this.$root.$bvModal.hidde('ModalCompraCreditoAdicional')"
            :disabled="false"
            class="btn btn-primary btn-block"
            >
            <span
                v-show="false"
                class="spinner-border spinner-border-sm mr-2"
                role="status"
                aria-hidden="true"
            ></span>

            <span v-show="false == false"
                ><i class="fas fa-ban pr-2"></i>CANCELAR
                </span>
        </button>
    </div> -->
    </div>

    </b-modal>
</template>
<script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
  
<script>
import api from '../config/api';
import UsuarioLogado from "../config/usuario-logado";

export default {
  data () {
    return {
      DadosCompra: {}
    }
  },
  props: {
    cartaoCadastrado: null,
  },
  
  methods: {
    async buscaDadosCartao() {
      await this.$emit("buscaDadosCartao");   
    },
    efetuaPagamento() {

    },
  },
  mounted() {
      this.buscaDadosCartao();
  },
}
</script>
<style lang="scss">
.login {
  max-width: 550px;
}

.pedidos {
  background: #fffccb;
  box-shadow: 0px 10px 20px #bbb;
  &:after {
    background: linear-gradient(-45deg, transparent 12px, #fffccb 0),
      linear-gradient(45deg, transparent 12px, #fffccb 0);
    background-repeat: repeat-x;
    background-position: left bottom;
    background-size: 12px 24px;
    content: "";
    display: block;
    width: 100%;
    height: 12px;
    position: relative;
    top: 12px;
    left: 0px;
    margin-bottom: 24px;
  }
  .pedido {
    border-bottom: 1px dashed $cinza;
  }
}
</style>