<template>
  <div class="d-sm-flex align-items-md-center container-onda">
    <vue-topprogress ref="topProgress"></vue-topprogress>
    <div class="login m-auto">
      <div class="header-padrao p-3 text-center">
        <img class="logo" src="../assets/imgs/legallake-branco.png" />
      </div>
      <div class="px-3 px-md-5 py-4">
        <div class="text-center">
          <h3 class="p-0 m-0 font-weight-normal">Recuperar Senha</h3>
        </div>
        <div v-if="!senhaAlterada">
          <div class="form-group">
            <label for="novaSenha">Nova Senha</label>
            <div class="input-group mb-3">
              <input
                id="nova-senha"
                type="password"
                class="form-control"
                placeholder="• • • • •"
                v-model="alteracaoSenha.novaSenha"
                @keyup.enter="alterarSenha()"
                :class="{ 'is-invalid': $v.alteracaoSenha.novaSenha.$error }"
              />
              <b-form-invalid-feedback
                v-if="
                  !$v.alteracaoSenha.novaSenha.required && formularioPostado
                "
                >Campo obrigatório<br
              /></b-form-invalid-feedback>
              <b-form-invalid-feedback
                v-if="
                  !$v.alteracaoSenha.novaSenha.minLength && formularioPostado
                "
                >Sua senha deve ter no mínimo 6 caracteres<br
              /></b-form-invalid-feedback>
            </div>
          </div>
          <div class="form-group">
            <label for="confirmacaoSenha">Repetir nova senha</label>
            <div class="input-group mb-3">
              <input
                type="password"
                class="form-control"
                placeholder="• • • • •"
                v-model="alteracaoSenha.confirmacaoSenha"
                @keyup.enter="alterarSenha()"
                :class="{
                  'is-invalid': $v.alteracaoSenha.confirmacaoSenha.$error,
                }"
              />
              <b-form-invalid-feedback
                v-if="
                  !$v.alteracaoSenha.confirmacaoSenha.required &&
                  formularioPostado
                "
                >Campo obrigatório</b-form-invalid-feedback
              >
              <b-form-invalid-feedback
                v-if="
                  !$v.alteracaoSenha.confirmacaoSenha.sameAs &&
                  $v.alteracaoSenha.confirmacaoSenha.required &&
                  formularioPostado
                "
                >Confirmação de senha está diferente</b-form-invalid-feedback
              >
            </div>
          </div>
          <button
            @click="alterarSenha()"
            type="button"
            class="btn mt-4 btn-primary btn-block"
          >
            <i class="fas fa-shield-alt pr-2"></i>Salvar nova senha
          </button>
        </div>
        <div v-if="senhaAlterada" class="text-center">
          <div class="py-3">Senha alterada.</div>
        </div>
        <div class="py-3 text-center small">
          <a href="/">Voltar para Legal Lake</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../config/api";
import { required, sameAs, minLength } from "vuelidate/lib/validators";

export default {
  mounted() {
    document.getElementById("nova-senha").focus();
    this.$refs.topProgress.start();
    setTimeout(() => {
      this.$refs.topProgress.done();
    }, 150);
  },
  data: function () {
    return {
      senhaAlterada: false,
      formularioPostado: false,
      alteracaoSenha: {
        novaSenha: "",
        confirmacaoSenha: "",
      },
    };
  },
  validations: {
    alteracaoSenha: {
      novaSenha: { required, minLength: minLength(6) },
      confirmacaoSenha: { required, sameAs: sameAs("novaSenha") },
    },
  },
  methods: {
    alterarSenha: function () {
      var clienteId = this.$route.query.clienteId;
      var codigo = this.$route.query.codigo;
      this.$v.alteracaoSenha.$touch();
      this.formularioPostado = true;
      if (!this.$v.alteracaoSenha.$error) {
        let loader = this.$loading.show();
        api
          .post(`senha/recuperar-senha`, {
            novaSenha: this.alteracaoSenha.novaSenha,
            clienteId: clienteId,
            codigo: codigo,
          })
          .then(() => {
            this.$root.$bvToast.toast("Senha alterada com sucesso", {
              title: "Sucesso",
              variant: "success",
              solid: true,
            });
            this.senhaAlterada = true;
            this.$router.push({ path: "/login" });
          })
          .finally(() => {
            loader.hide();
          });
      }
    },
  },
};
</script>