<template>
  <div class="resultado-vazio text-center overflow-hidden p-5 m-4">
    <div class="icon d-flex align-items-center justify-content-center">
      <i class="fas" :class="icon"></i>
    </div>
    <h5 class="ff-roboto-slab pt-4">{{titulo}}</h5>
    <div>{{descricao}}</div>
  </div>
</template>
<script>

export default {
  props: {
    titulo: String,
    descricao: String,
    icon: String //https://fontawesome.com/icons
  },
};
</script>
<style lang="scss" scoped>
.resultado-vazio {
  background: $cinza-claro;
  border: 1px solid $cinza-medio;
  border-radius: $border-radius;
  .icon {
    background: $cinza-medio;
    height: 100px;
    width: 100px;
    border-radius: 999px;
    margin: 4px auto;
    i {
      font-size: 2rem;
    }
  }
}
</style>