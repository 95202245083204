import MasterSite from "../layouts/MasterSite.vue";
import MasterVazio from "../layouts/MasterVazio.vue";

import Vue from "vue";
import Router from "vue-router";
import VueMeta from "vue-meta";
import GuardUsuarioLogado from "./guard-usuario-logado";

Vue.use(Router);
Vue.use(VueMeta);

const routes = [
  {
    path: "/",
    component: MasterSite,

    children: [
      {
        path: "/",
        name: "Home",
        component: require("../views/Home.vue").default,
        /*children: [
          {
            path: "ajuda",
            name: "Ajuda",
            meta: { abrirRotaAjuda: true }
          },
        ],*/
      },
      {
        path: "/planos",
        name: "Planos",
        component: require("../views/Home.vue").default,
      },
      {
        path: "/busca",
        name: "Busca",
        component: require("../views/Busca.vue").default,
      },
      {
        path: "/resultado",
        name: "Resultado",
        component: require("../views/Resultado.vue").default,
      },
      {
        path: "/politica-de-privacidade",
        name: "PoliticaDePrivacudade",
        component: require("../views/PoliticaPrivacidade.vue").default,
      },
      {
        path: "/termos-de-uso",
        name: "TermosDeUso",
        component: require("../views/TermosDeUso.vue").default,
      },
      {
        path: "/usuario",
        name: "Usuario",
        component: require("../views/Usuario.vue").default,
        beforeEnter: (to, from, next) => {
          GuardUsuarioLogado.verificarUsuarioLogado(to, from, next);
        },
      },
      {
        path: "/editar-usuario",
        name: "EditarUsuario",
        component: require("../views/EditarUsuario.vue").default,
      },
      {
        path: "/exportar-dados-pessoais",
        name: "ExportarDadosPessoais",
        component: require("../views/ExportarDadosPessoais.vue").default,
      },
      {
        path: "/excluir-conta-confirmacao",
        name: "ExcluirContaConfirmacao",
        component: require("../views/ExcluirContaConfirmacao.vue").default,
      },
      {
        path: "/excluir-conta",
        name: "ExcluirConta",
        component: require("../views/ExcluirConta.vue").default,
      },
      {
        path: "/usuario/minha-carteira",
        name: "MinhaCarteira",
        component: require("../views/MinhaCarteira.vue").default,
        beforeEnter: (to, from, next) => {
          GuardUsuarioLogado.verificarUsuarioLogado(to, from, next);
        },
      },
      {
        path: "/usuario/resultados-abertos",
        name: "ResultadosAbertos",
        component: require("../views/ListaResultadoAberto.vue").default,
        beforeEnter: (to, from, next) => {
          GuardUsuarioLogado.verificarUsuarioLogado(to, from, next);
        },
      },
      {
        path: "/usuario/buscas-salvas",
        name: "BuscasSalvas",
        component: require("../views/BuscasSalvas.vue").default,
        beforeEnter: (to, from, next) => {
          GuardUsuarioLogado.verificarUsuarioLogado(to, from, next);
        },
      },
      {
        path: "/404",
        name: "404",
        component: require("../views/404.vue").default,
      },
    ],
  },
  {
    path: "/",
    component: MasterVazio,

    children: [
      {
        path: "/login",
        name: "Login",
        component: require("../views/Login.vue").default,
      },
      {
        path: "/login-faz",
        name: "Login FAZ",
        component: require("../views/LoginFAZ.vue").default,
      },      
      {
        path: "/sms",
        name: "ConfirmacaoSMS",
        component: require("../views/ConfirmacaoSMS.vue").default,
        beforeEnter: (to, from, next) => {
          GuardUsuarioLogado.verificarUsuarioLogado(to, from, next);
        },
      },
      {
        path: "/envio-recuperar-senha",
        name: "EnvioRecuperarSenha",
        component: require("../views/EnvioEmailRecuperarSenha.vue").default,
      },
      {
        path: "/recuperar-senha",
        name: "RecuperarSenha",
        component: require("../views/RecuperarSenha.vue").default,
      },
      {
        path: "/redefinir-senha",
        name: "RedefinirSenha",
        component: require("../views/RedefinirSenha.vue").default,
        beforeEnter: (to, from, next) => {
          GuardUsuarioLogado.verificarUsuarioLogado(to, from, next);
        },
      },
      {
        path: "/checkout",
        name: "Checkout",
        component: require("../views/Checkout2.vue").default,
        beforeEnter: (to, from, next) => {
          GuardUsuarioLogado.verificarUsuarioLogado(to, from, next);
        },
      },
      {
        path: "/cadastro",
        name: "Cadastro",
        component: require("../views/Cadastro.vue").default,
      },
      {
        path: "/verificar-email",
        name: "VerificarEmail",
        component: require("../views/VerificarEmail.vue").default,
      },
      {
        path: "/remover-email-noticias",
        name: "RemoverEmailNoticias",
        component: require("../views/RemoverEmailNoticias.vue").default,
      },
    ],
  },

  { path: "*", redirect: "/404" },

  // [-----Modelo de links do vue-router-----]
  // {
  //   path: '/minha-pagina-personalizada',
  //   name: 'minha-pagina-personalizada',
  //   component: require('../views/alguma-view-bacana.vue').default,
  // },
];

export default routes;
