<template>
  <div>
    <div class="onda-footer d-none mt-5"></div>
    <div class="footer py-5 mt-5 text-white">
      <div class="container">
        <div class="d-flex flex-column flex-sm-row pt-5">
          <img src="../assets/imgs/legallake-branco.png" />
          <div class="mt-3 ml-auto">
            <span class="d-inline-block mr-4">{{ email }}</span>
            <span class="d-inline-block mr-4" v-if="false">{{ telefone }}</span>
          </div>
        </div>
        <div class="mt-3 text-center text-sm-left">
          <a href="#/termos-de-uso">Termos e Condições Gerais de Uso</a>
          <a class="d-block" href="https://privacidade.sedep.com.br/"
            >Política de Privacidade</a
          >
        </div>

        <hr />
        <div class="d-flex sobre-sedep flex-column flex-md-row">
          <div class="pr-md-5">
            <div class="mb-2 font-weight-bold">
              Um produto desenvolvido pela Sedep, excelência em Soluções
              Jurídicas
            </div>
            <p>
              A Sedep, há mais de 39 anos, possui tradição no acompanhamento das
              publicações jurídicas, em todo o Brasil. Seus investimentos
              contínuos na preparação e treinamento de funcionários e na
              aquisição de tecnologia de ponta resultaram na ampliação de seu
              portfólio de serviços voltados para a área jurídica, que
              contemplam informações seguras e excelentes soluções empresarias.
            </p>
          </div>
          <div class="ml-md-auto text-center order-1">
            <img src="../assets/imgs/sedep-branco.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      email: process.env.VUE_APP_EMAIL_LEGALLAKE,
      telefone: process.env.VUE_APP_TELEFONE_LEGALLAKE,
    };
  },
};
</script>
<style lang="scss" scoped>
.onda-footer {
  background-image: url(../assets/imgs/onda-azul.png);
  //background-color: $cinza-claro;
  height: 100px;
  background-position: bottom;
  background-repeat: repeat-x;
}
.footer {
  background-color: $azul_escuro;
  font-size: 13px;
  hr {
    margin: 50px 0;
    background-color: $vermelho;
  }
}
</style>
