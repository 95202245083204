<template>
  <div class="fluid overflow-hidden bg-cinza-claro">
    <vue-topprogress ref="topProgress"></vue-topprogress>
    <div class="container">
      <div class="d-flex align-items-center">
        <div>
          <h3 class="ff-roboto-slab mt-4 pb-0 mb-0">Dados do titular</h3>
          <small class="text-uppercase">
            Dados pessoais do usuário mantidos pelo LegalLake</small
          >
        </div>
      </div>

      <div class="bg-white p-4 area-usario mt-3 mb-5">
        <div class="p-3 d-flex flex-column flex-md-row align-items-stretch">
          <div>
            <div class="ttl mb-4">Conheça os seus direitos</div>
            <div class="ttl-alt mb-4">
              Nos preocupamos com os seus dados pessoais e trabalhamos com
              vários mecanismos para mitigação de riscos, proteção à privacidade
              e segurança da informação.
            </div>
            <div class="ttl-alt mb-4">
              Você como titular dos seus dados pessoais, possui direitos
              estabelecidos pela Lei Geral de Proteção de Dados (LGPD).
              Portanto, você poderá exportar seus dados pessoais que são
              mantidos pelo LegalLake, que são dados necessários para que você
              possa utilizar o nosso serviço.
            </div>
            <div class="ttl mb-4">
              Seus dados pessoais tratados pelo LegalLake
            </div>
          </div>
        </div>
        <div v-if="cliente">
          <div
            class="p-3 d-flex flex-column flex-md-row align-items-stretch"
            v-if="cliente.nome"
          >
            <div class="font-weight-bold pr-5">Nome:</div>
            {{ cliente.nome }}
          </div>
          <div
            class="p-3 d-flex flex-column flex-md-row align-items-stretch"
            v-if="cliente.cpf"
          >
            <div class="font-weight-bold pr-5">CPF:</div>
            {{ cliente.cpf }}
          </div>
          <div
            class="p-3 d-flex flex-column flex-md-row align-items-stretch"
            v-if="cliente.email"
          >
            <div class="font-weight-bold pr-5">E-mail:</div>
            {{ cliente.email }}
          </div>
          <div
            class="p-3 d-flex flex-column flex-md-row align-items-stretch"
            v-if="cliente.nascimento"
          >
            <div class="font-weight-bold pr-5">Data nascimento:</div>
            {{ cliente.nascimento }}
          </div>
          <div
            class="p-3 d-flex flex-column flex-md-row align-items-stretch"
            v-if="cliente.celular"
          >
            <div class="font-weight-bold pr-5">Celular:</div>
            {{ cliente.celular }}
          </div>
          <div
            class="p-3 d-flex flex-column flex-md-row align-items-stretch"
            v-if="cliente.telefone1"
          >
            <div class="font-weight-bold pr-5">Telefone 1:</div>
            {{ cliente.telefone1 }}
          </div>
          <div
            class="p-3 d-flex flex-column flex-md-row align-items-stretch"
            v-if="cliente.telefone2"
          >
            <div class="font-weight-bold pr-5">Telefone 2:</div>
            {{ cliente.telefone2 }}
          </div>
          <div
            class="p-3 d-flex flex-column flex-md-row align-items-stretch"
            v-if="cliente.enderecoEstado"
          >
            <div class="font-weight-bold pr-5">Estado:</div>
            {{ cliente.enderecoEstado }}
          </div>
          <div
            class="p-3 d-flex flex-column flex-md-row align-items-stretch"
            v-if="cliente.enderecoMunicipio"
          >
            <div class="font-weight-bold pr-5">Município:</div>
            {{ cliente.enderecoMunicipio }}
          </div>
          <div
            class="p-3 d-flex flex-column flex-md-row align-items-stretch"
            v-if="cliente.enderecoLogradouro"
          >
            <div class="font-weight-bold pr-5">Logradouro:</div>
            {{ cliente.enderecoLogradouro }}
          </div>
          <div
            class="p-3 d-flex flex-column flex-md-row align-items-stretch"
            v-if="cliente.enderecoBairro"
          >
            <div class="font-weight-bold pr-5">Bairro:</div>
            {{ cliente.enderecoBairro }}
          </div>
          <div
            class="p-3 d-flex flex-column flex-md-row align-items-stretch"
            v-if="cliente.enderecoNumero"
          >
            <div class="font-weight-bold pr-5">Número:</div>
            {{ cliente.enderecoNumero }}
          </div>
          <div
            class="p-3 d-flex flex-column flex-md-row align-items-stretch"
            v-if="cliente.enderecoComplemento"
          >
            <div class="font-weight-bold pr-5">Complemento:</div>
            {{ cliente.enderecoComplemento }}
          </div>
        </div>
        <div class="d-flex justify-content-betwee">
          <button type="button" class="btn btn-link" @click="voltar()">
            <i class="fas fa-chevron-left"></i> Voltar
          </button>
          <button
            type="button"
            class="btn btn-primary ml-auto"
            @click="exportarDados()"
          >
            Eu quero exportar meus dados
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../config/api";
import UsuarioLogado from "../config/usuario-logado";

export default {
  mounted() {
    this.$refs.topProgress.start();
    this.obterDadosCliente();
  },
  data: function() {
    return {
      cliente: {
        nome: "",
        cpf: "",
        email: "",
        nascimento: "",
        celular: "",
        telefone1: "",
        telefone2: "",
        enderecoEstado: "",
        enderecoMunicipio: "",
        enderecoLogradouro: "",
        enderecoBairro: "",
        enderecoNumero: "",
        enderecoComplemento: "",
      },
    };
  },
  methods: {
    voltar() {
      this.$router.push({ path: "/usuario" });
    },
    obterDadosCliente() {
      var cliente = UsuarioLogado.obterUsuarioLogado();
      api
        .get(`clientes?clienteId=${cliente.clienteId}`)
        .then((resp) => {
          let data = resp.data;
          this.cliente.nome = data.nome;
          this.cliente.cpf =
            data.cpfCnpj !== null
              ? data.cpfCnpj.replace(
                  /(\d{3})(\d{3})(\d{3})(\d{2})/,
                  "$1.$2.$3-$4"
                )
              : null;
          this.cliente.email = data.email;
          this.cliente.nascimento =
            data.dataNascimento !== null
              ? data.dataNascimento
                  .replace(/[^\d]/g, "")
                  .substring(0, 8)
                  .replace(/(\d{4})(\d{2})(\d{2})/, "$3/$2/$1")
              : null;
          this.cliente.celular =
            data.celular !== null
              ? data.celular.length == 11
                ? data.celular
                    .replace(/[^\d]/g, "")
                    .replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4")
                : data.celular
                    .replace(/[^\d]/g, "")
                    .replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3")
              : null;
          this.cliente.telefone1 =
            data.fone01 !== null
              ? data.fone01.length == 11
                ? data.fone01
                    .replace(/[^\d]/g, "")
                    .replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4")
                : data.fone01
                    .replace(/[^\d]/g, "")
                    .replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3")
              : null;
          this.cliente.telefone2 =
            data.fone02 !== null
              ? data.fone02.length == 11
                ? data.fone02
                    .replace(/[^\d]/g, "")
                    .replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4")
                : data.fone02
                    .replace(/[^\d]/g, "")
                    .replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3")
              : null;
          this.cliente.enderecoEstado = data.estado;
          this.cliente.enderecoMunicipio = data.municipio;
          this.cliente.enderecoLogradouro = data.enderecoLogradouro;
          this.cliente.enderecoBairro = data.enderecoBairro;
          this.cliente.enderecoNumero = data.enderecoNumero;
          this.cliente.enderecoComplemento = data.enderecoComplemento;
        })
        .finally(() => {
          setTimeout(() => {
            this.$refs.topProgress.done();
          }, 150);
        });
    },
    exportarDados() {
      var salvarArquivo = (function() {
        var a = document.createElement("a");
        return function(dados, nomeArquivo) {
          var json = JSON.stringify(dados),
            blob = new Blob([json], { type: "octet/stream" }),
            url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = nomeArquivo;
          a.click();
          window.URL.revokeObjectURL(url);
        };
      })();

      var dados = {
          nome: this.cliente.nome,
          cpf: this.cliente.cpf,
          email: this.cliente.email,
          dataNascimento: this.cliente.nascimento,
          celular: this.cliente.celular,
          telefone1: this.cliente.telefone1,
          telefone2: this.cliente.telefone2,
          estado: this.cliente.enderecoEstado,
          municipio: this.cliente.enderecoMunicipio,
          logradouro: this.cliente.enderecoLogradouro,
          bairro: this.cliente.enderecoBairro,
          numero: this.cliente.enderecoNumero,
          complemento: this.cliente.enderecoComplemento,
        },
        nomeArquivo = "meus-dados-legallake.txt";

      salvarArquivo(dados, nomeArquivo);
    },
  },
};
</script>
