<template>
  <div class="fluid overflow-hidden bg-cinza-claro">
    <vue-topprogress ref="topProgress"></vue-topprogress>
    <div class="container">
      <div class="d-flex align-items-center">
        <div>
          <h3 class="ff-roboto-slab mt-4 pb-0 mb-0">Editar dados do Usuário</h3>
          <small class="text-uppercase">
            Informações de cadastro do usuário</small
          >
        </div>
      </div>
      <div v-if="dadosCliente" class="bg-white p-4 area-usario mt-3 mb-5">
        <div class="form-group">
          <label for="nome">Nome</label>
          <input
            type="text"
            class="form-control"
            id="nome"
            :class="{ 'is-invalid': $v.cliente.nome.$error }"
            placeholder="Seu nome completo"
            v-model="cliente.nome"
            @keyup.enter="editar()"
          />
          <b-form-invalid-feedback
            v-if="!$v.cliente.nome.required && formularioPostado"
            >Campo obrigatório</b-form-invalid-feedback
          >
        </div>
        <div class="form-group">
          <label for="email">E-mail</label>
          <input
            type="email"
            class="form-control"
            id="email"
            placeholder="nome@servidor.com.br"
            v-model="cliente.email"
            :disabled="dadosCliente && dadosCliente.emailConfirmado"
            :class="{ 'is-invalid': $v.cliente.email.$error }"
            @keyup.enter="editar()"
          />
          <b-form-invalid-feedback
            v-if="!$v.cliente.email.required && formularioPostado"
            >Campo obrigatório<br
          /></b-form-invalid-feedback>
          <b-form-invalid-feedback
            v-if="!$v.cliente.email.email && formularioPostado"
            >E-mail inválido<br
          /></b-form-invalid-feedback>
        </div>
        <div class="form-group">
          <label for="celular">Celular</label>
          <input
            type="text"
            class="form-control"
            id="celular"
            placeholder="Seu número de celular com DDD"
            v-model="cliente.celular"
            v-mask="['(##) ####-####', '(##) # ####-####']"
            :class="{ 'is-invalid': $v.cliente.celular.$error }"
            @keyup.enter="editar()"
          />
          <b-form-invalid-feedback
            v-if="!$v.cliente.celular.required && formularioPostado"
            >Campo obrigatório<br
          /></b-form-invalid-feedback>
          <b-form-invalid-feedback
            v-if="!$v.cliente.celular.minLength && formularioPostado"
            >Número inválido<br
          /></b-form-invalid-feedback>
        </div>
        <div class="form-group">
          <label for="estado">Estado</label>
          <b-form-select
            :class="{ 'is-invalid': $v.cliente.estado.$error }"
            v-model="cliente.estado"
            @change="
              cidade = '';
              carregaComboMunicipios(cliente.estado);
            "
          >
            <b-form-select-option
              :value="null"
              selected="selected"
            ></b-form-select-option>
            <b-form-select-option value="AC">Acre</b-form-select-option>
            <b-form-select-option value="AL">Alagoas</b-form-select-option>
            <b-form-select-option value="AP">Amapá</b-form-select-option>
            <b-form-select-option value="AM">Amazonas</b-form-select-option>
            <b-form-select-option value="BA">Bahia</b-form-select-option>
            <b-form-select-option value="CE">Ceará</b-form-select-option>
            <b-form-select-option value="DF"
              >Distrito Federal</b-form-select-option
            >
            <b-form-select-option value="ES"
              >Espírito Santo</b-form-select-option
            >
            <b-form-select-option value="GO">Goiás</b-form-select-option>
            <b-form-select-option value="MA">Maranhão</b-form-select-option>
            <b-form-select-option value="MT">Mato Grosso</b-form-select-option>
            <b-form-select-option value="MS"
              >Mato Grosso do Sul</b-form-select-option
            >
            <b-form-select-option value="MG">Minas Gerais</b-form-select-option>
            <b-form-select-option value="PA">Pará</b-form-select-option>
            <b-form-select-option value="PB">Paraíba</b-form-select-option>
            <b-form-select-option value="PR">Paraná</b-form-select-option>
            <b-form-select-option value="PE">Pernambuco</b-form-select-option>
            <b-form-select-option value="PI">Piauí</b-form-select-option>
            <b-form-select-option value="RJ"
              >Rio de Janeiro</b-form-select-option
            >
            <b-form-select-option value="RN"
              >Rio Grande do Norte</b-form-select-option
            >
            <b-form-select-option value="RS"
              >Rio Grande do Sul</b-form-select-option
            >
            <b-form-select-option value="RO">Rondônia</b-form-select-option>
            <b-form-select-option value="RR">Roraima</b-form-select-option>
            <b-form-select-option value="SC"
              >Santa Catarina</b-form-select-option
            >
            <b-form-select-option value="SP">São Paulo</b-form-select-option>
            <b-form-select-option value="SE">Sergipe</b-form-select-option>
            <b-form-select-option value="TO">Tocantins</b-form-select-option>
          </b-form-select>
          <b-form-invalid-feedback
            v-if="!$v.cliente.estado.required && formularioPostado"
            >Campo obrigatório</b-form-invalid-feedback
          >
        </div>
        <div class="form-group">
          <label for="cidade">Cidade</label>
          <b-form-select
            v-model="cliente.municipio"
            value-field="municipioId"
            text-field="nomeMunicipio"
            :options="municipios"
            :class="{
              'is-invalid':
                $v.cliente.estado.required && $v.cliente.municipio.$error,
            }"
            :disabled="municipios.length <= 0"
          >
            <b-form-select-option
              :value="null"
              selected="selected"
            ></b-form-select-option>
          </b-form-select>
          <b-form-invalid-feedback
            v-if="
              $v.cliente.estado.required &&
              !$v.cliente.municipio.required &&
              formularioPostado
            "
            >Campo obrigatório</b-form-invalid-feedback
          >
        </div>
        <div class="d-flex justify-content-betwee">
          <button type="button" @click="voltar()" class="btn btn-link">
            <i class="fas fa-chevron-left"></i> Voltar
          </button>
          <button
            type="button"
            class="btn btn-primary ml-auto"
            @click="editar()"
          >
            Salvar Edição
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../config/api";
import UsuarioLogado from "../config/usuario-logado";
import { required, email, minLength } from "vuelidate/lib/validators";

export default {
  mounted() {
    this.$refs.topProgress.start();
    this.ObterDadosCliente();
  },
  data: function () {
    return {
      formularioPostado: false,
      dadosCliente: null,
      cliente: {
        nome: "",
        email: "",
        celular: "",
        estado: "",
        municipio: "",
      },
      municipios: [],
    };
  },
  validations: {
    cliente: {
      nome: { required },
      email: { required, email },
      celular: { required, minLength: minLength(10) },
      estado: { required },
      municipio: { required },
    },
  },
  methods: {
    carregaComboMunicipios(estado) {
      if (estado !== "") {
        let loader = this.$loading.show();
        api
          .get(`municipios/combo?estado=${estado}`)
          .then((resp) => {
            this.municipios = resp.data;
          })
          .finally(() => {
            loader.hide();
          });
      }
    },
    voltar() {
      this.$router.push({ path: "/usuario" });
    },
    editar: function () {
      this.$v.cliente.$touch();
      this.formularioPostado = true;
      if (!this.$v.cliente.$error) {
        let loader = this.$loading.show();
        api
          .put(`clientes`, {
            clienteId: this.dadosCliente.clienteId,
            nome: this.cliente.nome,
            email: this.cliente.email,
            celular: this.cliente.celular,
            enderecoUF: this.cliente.estado,
            municipioId: this.cliente.municipio,
          })
          .then(() => {
            this.$root.$bvToast.toast("Dados alterados com sucesso", {
              title: "Sucesso",
              variant: "success",
              solid: true,
            });
            this.$router.push({ path: "/usuario" });
          })
          .finally(() => {
            loader.hide();
          });
      }
    },
    ObterDadosCliente() {
      var cliente = UsuarioLogado.obterUsuarioLogado();
      api
        .get(`clientes?clienteId=${cliente.clienteId}`)
        .then((resp) => {
          this.dadosCliente = resp.data;
          this.cliente.nome = resp.data.nome;
          this.cliente.email = resp.data.email;
          this.cliente.celular = resp.data.celular;
          this.cliente.estado = resp.data.estado;
          this.cliente.municipio = this.dadosCliente.municipioId;
          this.carregaComboMunicipios(resp.data.estado);
        })
        .finally(() => {
          setTimeout(() => {
            this.$refs.topProgress.done();
          }, 150);
        });
    },
  },
};
</script>